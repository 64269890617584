import type * as Api from 'types/api'
import type * as Store from 'types/store'

export const mapLanguagePickList = ({
  id,
  iso,
  name,
}: Api.LanguagePickListItem): Store.LanguagePickListItem => ({ id, iso, name })

export default mapLanguagePickList
