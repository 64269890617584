import { UserRoles } from 'types/enums'

const homepage = process.env.REACT_APP_HOMEPAGE

const allUsers = [UserRoles.User, UserRoles.Pro, UserRoles.Admin, UserRoles.Tester, UserRoles.Sales]

const routes = {
  allMachines: {
    key: 'allMachines',
    url: `${homepage}/`,
    private: true,
    menu: 'allMachines',
    authorize: allUsers,
    headTitle: 'allMachines.title',
  },
  machineDetail: {
    key: 'machineDetail',
    url: `${homepage}/machine/:id`,
    private: true,
    menu: 'machineDetail',
    authorize: allUsers,
    headTitle: 'machineDetails.title',
  },
  machineMap: {
    key: 'machineMap',
    url: `${homepage}/map`,
    private: true,
    menu: 'machineMap',
    authorize: allUsers,
    headTitle: 'allMachines.title',
  },
  machineDetailType: {
    key: 'machineDetailType',
    url: `${homepage}/machine/:id/:type/`,
    private: true,
    menu: '',
    authorize: allUsers,
    headTitle: 'machineDetails.title',
  },
  machineDetailTab: {
    key: 'machineDetailTab',
    url: `${homepage}/machine/:id/:type/:tab`,
    private: true,
    menu: '',
    authorize: allUsers,
    headTitle: 'machineDetails.title',
  },

  machinePDFReport: {
    key: 'machinePDFReport',
    url: `${homepage}/machinepdf/:id`,
    private: false,
    menu: '',
    authorize: allUsers,
    headTitle: 'machineDetails.title',
  },
  machineEvalPDFReport: {
    key: 'machinePDFReport',
    url: `${homepage}/machinepdf/:id/:evalId`,
    private: false,
    menu: '',
    authorize: allUsers,
    headTitle: 'machineDetails.title',
  },

  translations: {
    key: 'translations',
    url: `${homepage}/admin/translations`,
    private: true,
    menu: 'translations',
    authorize: [UserRoles.Admin],
    headTitle: 'translations.title',
  },
  languages: {
    key: 'languages',
    url: `${homepage}/admin/languages`,
    private: true,
    menu: 'languages',
    authorize: [UserRoles.Admin],
    headTitle: 'languages.title',
  },
  userProfile: {
    key: 'profile',
    url: `${homepage}/user`,
    private: true,
    menu: '',
    authorize: allUsers,
    headTitle: 'form.profile',
  },
  adminMachines: {
    key: 'adminMachines',
    url: `${homepage}/admin/machines`,
    private: true,
    menu: 'machines',
    authorize: [UserRoles.Admin],
    headTitle: 'adminMachines.title',
  },
  adminUsers: {
    key: 'adminUsers',
    url: `${homepage}/admin/users`,
    private: true,
    menu: 'users',
    authorize: [UserRoles.Admin],
    headTitle: 'adminUsers.title',
  },
  adminCodes: {
    key: 'adminCodes',
    url: `${homepage}/admin/codes`,
    private: true,
    menu: 'codes',
    authorize: [UserRoles.Admin],
    headTitle: 'adminCodes.title',
  },

  evaluationDetail: {
    key: 'evaluationDetail',
    url: `${homepage}/machine/:id/:type/eval/:ideval`,
    private: true,
    menu: '',
    authorize: allUsers,
    headTitle: 'evaluationDetail.title',
  },

  register: {
    key: 'register',
    url: `${homepage}/register`,
    private: false,
    menu: '',
    headTitle: 'register.title',
  },
  emailConfirm: {
    key: 'emailConfirm',
    url: `${homepage}/register/confirm`,
    private: false,
    menu: '',
    headTitle: 'register.confirmTitle',
  },
  //Authentication Routes
  login: {
    key: 'login',
    url: `${homepage}/login`,
    private: false,
    menu: '',
    headTitle: 'login.title',
  },
  forgotPassword: {
    key: 'forgotPassword',
    url: `${homepage}/forgot-password`,
    private: false,
    menu: '',
    headTitle: '',
  },
  resetPassword: {
    key: 'resetPassword',
    url: '/reset-password',
    private: false,
    menu: '',
    headTitle: '',
  },
  // Playground (remove in a real project)
  // playgroundTheme: {
  //   key: 'playgroundTheme',
  //   url: `${homepage}/playground-theme`,
  //   private: false,
  //   menu: '',
  // },
  // Error Routes
  notFoundPage: {
    key: 'not-found',
    url: `${homepage}/page-not-found`,
    private: false,
    menu: '',
    headTitle: 'pageNotFound.title',
  },
  errorPage: {
    key: 'error',
    url: `${homepage}/error`,
    private: false,
    menu: '',
    headTitle: '',
  },
} satisfies Routes

export default routes
