import React from 'react'
import { Card, Col, Row, Space, Typography } from 'antd'

import classNames from 'classnames'
import type * as Store from 'types/store'

import { getBoxTitle } from 'core/labelHelper'

import { ReactComponent as ExportIcon } from 'assets/icon/export-icon.svg'
import { ReactComponent as ImportIcon } from 'assets/icon/import-icon.svg'

import styles from './styles.module.scss'
import { BoxType } from 'types/enums'
import { useLanguage } from 'providers'
import { useTranslation } from 'react-i18next'
import { isNumeric, toNumber } from 'core'

interface RealtimeOutBoxProps {
  box: Store.MachineBox
  value?: number[] | null | undefined
  title: string
  unit?: string
}

export const RealtimeOutBox: React.FC<RealtimeOutBoxProps> = ({ box, value, title, unit }) => {
  const { t } = useTranslation()
  const lang = useLanguage()

  return (
    <Card className={classNames(styles['out-box'], 'out-box')} bordered={false}>
      <Typography.Title level={5} className="card-subtitle card-subtitle--top">
        {getBoxTitle(title as BoxType, t)}
      </Typography.Title>

      <Row>
        <Col span={16}>
          <Space align="start">
            <div className="icon-wrapper">
              {box?.isInput ? (
                <ImportIcon className="box-import-icon" />
              ) : (
                <ExportIcon className="box-export-icon" />
              )}
            </div>
            <div>
              <Typography.Text className="box-value">
                <span className="numeric">
                  {isNumeric(value?.[0]) ? new Intl.NumberFormat(lang).format(toNumber(value[0])) : ''}
                </span>
                <span className="unit">{unit}</span>
              </Typography.Text>
            </div>
          </Space>
        </Col>

        <Col span={8}>
          {box?.valueDetails.map((vd, index) => (
            <div key={index} className="value-wrapper">
              <Typography.Text className="label">{vd.label}</Typography.Text>
              <Typography.Text className="value">
                <span>
                  {isNumeric(value?.[index + 1])
                    ? new Intl.NumberFormat(lang).format(toNumber(value[index + 1]))
                    : ''}
                </span>

                {unit && (
                  <span className="unit" style={{ marginLeft: '5px' }}>
                    {unit}
                  </span>
                )}
              </Typography.Text>
            </div>
          ))}
        </Col>
      </Row>
    </Card>
  )
}

export default RealtimeOutBox
