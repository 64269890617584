import React, { useMemo, useState } from 'react'
import { Radio, Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { useTranslation } from 'react-i18next'
import { CaretRightOutlined, CaretDownOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'

import { config, isNumeric, toNumber } from 'core'

import type * as Store from 'types/store'
import { useNumberFormat } from 'hooks'
import { ProductType, TimelineTab, TimelineTableRecordType } from 'types/enums'

interface Props {
  tab?: string
  data: Store.EnergyByPeriodDataPoint[]
  expandedRowRender: (record: Store.EnergyByPeriodDataPoint) => React.ReactNode
  machineType: string
}

interface RecordType {
  day: Date
  date: string
  dailyEnergyKwh: string
  cumulativeEnergyKwh: string
  dailySavingKwh: string
  dailySavingPerc: string
  cumulativeSavingKwh: string
  cumulativeSavingPerc: string
  ku: string
}

export const TimelineMobileTable: React.FC<Props> = ({ tab, data, expandedRowRender, machineType }) => {
  const { t } = useTranslation()
  const formatNumber = useNumberFormat()
  const [recordType, setRecordType] = useState(TimelineTableRecordType.Energy)
  const [value, setValue] = useState(t('metricUnit.energy'))

  const handleSavingsViewChange = (e) => {
    setValue(e.target.value)
  }

  const mappedData = useMemo<RecordType[]>(
    () =>
      data?.map(({ day, eIn, eInSum, eRis, eRisP, eRisSum, eRispSum, ku }) => ({
        day: day,
        date: dayjs(day).format(config.getDateFormat()),
        dailyEnergyKwh: isNumeric(eIn) ? formatNumber(eIn) : '',
        cumulativeEnergyKwh: isNumeric(eInSum) ? formatNumber(eInSum) : '',
        dailySavingKwh: isNumeric(eRis) ? formatNumber(eRis) : '',
        dailySavingPerc: isNumeric(eRisP) ? formatNumber(eRisP) : '',
        cumulativeSavingKwh: isNumeric(eRisSum) ? formatNumber(eRisSum) : '',
        cumulativeSavingPerc: isNumeric(eRispSum) ? formatNumber(eRispSum) : '',
        ku: isNumeric(ku) ? formatNumber(ku) : '',
      })),
    [data],
  )

  const columnsEnergy: ColumnsType<RecordType> = useMemo(
    () => [
      {
        key: 'date',
        title: <span className="responsive-title">{t('table.date')}</span>,
        dataIndex: 'date',
        align: 'center',
      },
      {
        key: 'dailyEnergyKwh',
        title: <span className="responsive-title">{t('table.daily')}</span>,
        dataIndex: 'dailyEnergyKwh',
        align: 'center',
      },
      {
        key: 'cumulativeEnergyKwh',
        title: <span className="responsive-title">{t('table.cumulative')}</span>,
        dataIndex: 'cumulativeEnergyKwh',
        align: 'center',
      },
    ],
    [t],
  )

  const columnsSavings: ColumnsType<RecordType> = useMemo(
    () => [
      {
        key: 'saving',
        dataIndex: 'saving',
        align: 'center',
        title: (
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'end' }}>
            <Radio.Group
              onChange={handleSavingsViewChange}
              value={value}
              style={{ display: 'flex', alignItems: 'end' }}
            >
              <Radio
                value={t('metricUnit.energy')}
                style={{ display: 'flex', alignItems: 'end', marginRight: '10px' }}
              >
                <span className="responsive-title" style={{ marginLeft: '8px' }}>
                  {t('metricUnit.energy')}
                </span>
              </Radio>
              <Radio value={t('table.perc')} style={{ display: 'flex', alignItems: 'end' }}>
                <span className="responsive-title" style={{ marginLeft: '8px' }}>
                  {t('table.perc')}
                </span>
              </Radio>
            </Radio.Group>
          </div>
        ),
        children: [
          {
            key: 'date',
            title: <span className="responsive-title">{t('table.date')}</span>,
            dataIndex: 'date',
            align: 'center',
          },
          {
            key: 'dailySaving',
            title: <span className="responsive-title">{t('table.daily')}</span>,
            dataIndex: value === t('metricUnit.energy') ? 'dailySavingKwh' : 'dailySavingPerc',
            align: 'center',
          },
          {
            key: 'cumulativeSaving',
            title: <span className="responsive-title">{t('table.cumulative')}</span>,
            dataIndex: value === t('metricUnit.energy') ? 'cumulativeSavingKwh' : 'cumulativeSavingPerc',
            align: 'center',
          },
        ],
      },
    ],
    [t, value],
  )
  return (
    <div className="timeline-mobile-table-wrapper">
      {machineType !== ProductType.Sterostab && (
        <Radio.Group
          className="data-type-switch"
          buttonStyle="solid"
          onChange={(e) => {
            setRecordType(e.target.value)
          }}
          value={recordType}
        >
          <Radio.Button className="radio-energy" value={TimelineTableRecordType.Energy}>
            {t('table.energy')}
          </Radio.Button>

          <Radio.Button value={TimelineTableRecordType.Saving}>{t('table.saving')}</Radio.Button>
        </Radio.Group>
      )}

      <Table
        dataSource={mappedData}
        columns={
          machineType === ProductType.Sterostab
            ? columnsEnergy
            : recordType == TimelineTableRecordType.Energy
            ? columnsEnergy
            : columnsSavings
        }
        rowKey="date"
        pagination={false}
        expandable={{
          defaultExpandAllRows: tab === TimelineTab.Report,
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <CaretDownOutlined onClick={(e) => onExpand(record, e)} />
            ) : (
              <CaretRightOutlined onClick={(e) => onExpand(record, e)} />
            ),
          expandedRowRender:
            typeof expandedRowRender === 'function'
              ? (record: RecordType) =>
                  expandedRowRender({
                    ...record,
                    eIn: isNumeric(record.dailyEnergyKwh) ? toNumber(record.dailyEnergyKwh) : null,
                    eInSum: isNumeric(record.cumulativeEnergyKwh)
                      ? toNumber(record.cumulativeEnergyKwh)
                      : null,
                    eRis: isNumeric(record.dailySavingKwh) ? toNumber(record.dailySavingKwh) : null,
                    eRisP: isNumeric(record.dailySavingPerc) ? toNumber(record.dailySavingPerc) : null,
                    eRisSum: isNumeric(record.cumulativeSavingKwh)
                      ? toNumber(record.cumulativeSavingKwh)
                      : null,
                    eRispSum: isNumeric(record.cumulativeSavingPerc)
                      ? toNumber(record.cumulativeSavingPerc)
                      : null,
                    ku: isNumeric(record.ku) ? toNumber(record.ku) : null,
                  })
              : void 0,
        }}
      />
    </div>
  )
}

export default TimelineMobileTable
