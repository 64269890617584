import { useFetchMachineLatestData } from 'hooks'
import React from 'react'

import RealtimeChartBox from 'components/RealtimeChartBox'
import { MachineMetricsDataType } from 'types/enums'

interface Props {
  machineId: number
}

export const VoltageChartBox: React.FC<Props> = ({ machineId }) => {
  const {
    data: latestData,
    isLoading,
    error,
  } = useFetchMachineLatestData(machineId, MachineMetricsDataType.Voltage)

  if (isLoading || error) {
    // TODO: better error handling and loading
    return null
  }

  return (
    <div className="chart-box">
      <RealtimeChartBox type={MachineMetricsDataType.Voltage} metrics={latestData} />
    </div>
  )
}

export default VoltageChartBox
