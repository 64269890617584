import React, { useEffect } from 'react'
import useSWR from 'swr'

import type * as Api from 'types/api'
import type * as Store from 'types/store'

import { NewsCard } from 'components/NewsCard'
import { config } from 'core'
import { getSwrFetcher } from 'api/swr-fetcher'
import { mapNewsArticle } from 'services'
import { useLanguage } from 'providers'
import { useSetNewsVisibility } from 'providers/NewsProvider'

const articleFetcher = getSwrFetcher(
  {
    method: 'GET',
  },
  (data: Api.NewsArticle): Store.NewsArticle => mapNewsArticle(data),
)

interface NewsBlockProps {
  hidden?: boolean
  cardClass?: string
}

export const NewsBlock = React.forwardRef<HTMLDivElement, NewsBlockProps>(({ hidden, cardClass }, ref) => {
  const setNewsVisibility = useSetNewsVisibility()

  useEffect(() => {
    if (hidden) {
      setNewsVisibility(hidden)
    }
  }, [hidden])

  const language = useLanguage()
  const {
    data: latestArticle,
    isLoading,
    error,
  } = useSWR(config.api.getLatestNewsArticle(language), articleFetcher)

  if (error) {
    // TODO: consider better error handling for article
    return null
  }

  return (
    <NewsCard ref={ref} hidden={hidden} article={latestArticle} isLoading={isLoading} className={cardClass} />
  )
})
NewsBlock.displayName = 'NewsBlock'

export default NewsBlock
