import { createAsyncThunk } from '@reduxjs/toolkit'

import { mapLanguage, mapTranslations } from 'services'
import * as lang from 'api/adminLanguage'
import * as labelApi from 'api/adminLabels'
import * as codeApi from 'api/adminCodes'
import * as userApi from 'api/adminUsers'
import * as machineApi from 'api/adminMachines'

import * as Api from 'types/api'

import * as K from './constants'
import mapCode from 'services/map-admin-code'
import mapUser from 'services/map-admin-user'
import { mapMachine } from 'services/map-admin-machine'

interface ApiErrorMessage {
  message?: string
  error?: string
}

/** LANGUAGES **/
export const fetchAdminLanguagesThunk = createAsyncThunk(K.FETCH_ADMIN_LANGUAGES, async () => {
  const data = await lang.getAdminLanguages()
  const mapped = Array.isArray(data) ? data.map(mapLanguage) : []
  return mapped
})

export const fetchAdminLanguageByIdThunk = createAsyncThunk(
  K.FETCH_ADMIN_LANGUAGEBY_ID,
  async (id: number) => {
    const data = (await lang.getAdminLanguageById(id)) as Api.AdminLanguage
    const mapped = mapLanguage(data)
    return mapped
  },
)

export const createAdminLanguageThunk = createAsyncThunk(
  K.CREATE_ADMIN_LANGUAGE,
  async (languagePayload: Api.AdminLanguagePayload) => {
    const data = (await lang.postAdminLanguage(languagePayload)) as Api.AdminLanguage
    const mapped = mapLanguage(data)
    return mapped
  },
)

export const updateAdminLanguageThunk = createAsyncThunk(
  K.UPDATE_ADMIN_LANGUAGE,
  async ({ id, language }: Api.AdminLanguageActionPayload) => {
    const data = (await lang.putAdminLanguageById(id, language)) as Api.AdminLanguage
    const mapped = mapLanguage(data)
    return mapped
  },
)

export const deleteAdminLanguageThunk = createAsyncThunk(K.DELETE_ADMIN_LANGUAGE, async (id: number) => {
  const data = (await lang.deleteAdminLanguageById(id)) as Api.AdminLanguage
  const mapped = mapLanguage(data)
  return mapped
})

/** LABELS **/
export const fetchAdminLabelsThunk = createAsyncThunk(
  K.FETCH_ADMIN_LABELS,
  async (queryParams?: Api.PaginationParams) => {
    const data = (await labelApi.getAdminLabels(queryParams)) as Api.PaginatedResult<Api.LabelTag>
    const mapped = Array.isArray(data.result) ? data.result.flatMap(mapTranslations) : []
    return { ...data, result: mapped }
  },
)

export const fetchAdminLabelByIdThunk = createAsyncThunk(K.FETCH_ADMIN_LABELBY_ID, async (id: number) => {
  const data = (await labelApi.getAdminLabelById(id)) as Api.LabelTag
  const mapped = mapTranslations(data)
  return mapped
})

export const createAdminLabelThunk = createAsyncThunk(
  K.CREATE_ADMIN_LABEL,
  async (labelPayload: Api.LabelPayload) => {
    const data = (await labelApi.postAdminLabel(labelPayload)) as Api.LabelTag
    const mapped = mapTranslations(data)
    return mapped
  },
)

export const updateAdminLabelThunk = createAsyncThunk(
  K.UPDATE_ADMIN_LABEL,
  async ({ id, label }: Api.LabelActionPayload) => {
    const data = (await labelApi.putAdminLabelById(id, label)) as Api.LabelTag
    const mapped = mapTranslations(data)
    return mapped
  },
)

export const deleteAdminLabelThunk = createAsyncThunk(K.DELETE_ADMIN_LABEL, async (id: number) => {
  const data = (await labelApi.deleteAdminLabelById(id)) as Api.LabelTag
  const mapped = mapTranslations(data)
  return mapped
})

/** CODES **/
export const fetchAdminCodesThunk = createAsyncThunk(
  K.FETCH_ADMIN_CODES,
  async (queryParams?: Api.PaginationParams) => {
    const data = (await codeApi.getAdminCodes(queryParams)) as Api.PaginatedResult<Api.AdminCode>
    const mapped = Array.isArray(data.result) ? data.result.flatMap(mapCode) : []
    return { ...data, result: mapped }
  },
)

export const createAdminCodeThunk = createAsyncThunk<
  Api.AdminCode,
  Api.AdminCodePayload,
  { rejectValue: ApiErrorMessage }
>(K.CREATE_ADMIN_CODE, async (codePayload: Api.AdminCodePayload, thunkApi) => {
  try {
    const data = (await codeApi.postAdminCode(codePayload)) as Api.AdminCode
    const mapped = mapCode(data)
    return mapped
  } catch (error) {
    console.error(error)
    return thunkApi.rejectWithValue(error.data as ApiErrorMessage)
  }
})

/** USERS **/
export const fetchAdminUsersThunk = createAsyncThunk(
  K.FETCH_ADMIN_USERS,
  async (queryParams?: Api.PaginationParams) => {
    const data = (await userApi.getAdminUsers(queryParams)) as Api.PaginatedResult<Api.AdminUser>
    const mapped = Array.isArray(data.result) ? data.result.flatMap(mapUser) : []
    return { ...data, result: mapped }
  },
)

export const fetchAdminUserByIdThunk = createAsyncThunk(K.FETCH_ADMIN_USERBY_ID, async (id: number) => {
  const data = (await userApi.getAdminUserById(id)) as Api.AdminUser
  const mapped = mapUser(data)
  return mapped
})

export const createAdminUserThunk = createAsyncThunk<
  Api.AdminUser,
  Api.AdminUserPayload,
  { rejectValue: ApiErrorMessage }
>(K.CREATE_ADMIN_USER, async (userPayload: Api.AdminUserPayload, thunkApi) => {
  try {
    const data = (await userApi.postAdminUser(userPayload)) as Api.AdminUser
    const mapped = mapUser(data)
    return mapped
  } catch (error) {
    console.error(error)
    return thunkApi.rejectWithValue(error.data as ApiErrorMessage)
  }
})

export const updateAdminUserThunk = createAsyncThunk<
  Api.AdminUser,
  Api.AdminUserActionPayload,
  { rejectValue: ApiErrorMessage }
>(K.UPDATE_ADMIN_USER, async ({ id, user }: Api.AdminUserActionPayload, thunkApi) => {
  try {
    const data = (await userApi.putAdminUserById(id, user)) as Api.AdminUser
    const mapped = mapUser(data)
    return mapped
  } catch (error) {
    console.error(error)
    return thunkApi.rejectWithValue(error.data as ApiErrorMessage)
  }
})

export const deleteAdminUserThunk = createAsyncThunk<Api.AdminUser, number, { rejectValue: ApiErrorMessage }>(
  K.DELETE_ADMIN_USER,
  async (id: number, thunkApi) => {
    try {
      const data = (await userApi.deleteAdminUserById(id)) as Api.AdminUser
      const mapped = mapUser(data)
      return mapped
    } catch (error) {
      console.error(error)
      return thunkApi.rejectWithValue(error.data as ApiErrorMessage)
    }
  },
)

/** MACHINES **/
export const fetchAdminMachinesThunk = createAsyncThunk(
  K.FETCH_ADMIN_MACHINES,
  async (queryparams?: Api.PaginationParams) => {
    const data = (await machineApi.getAdminMachines(queryparams)) as Api.PaginatedResult<Api.AdminMachine>
    const mapped = Array.isArray(data.result) ? data.result.map(mapMachine) : []
    return { ...data, result: mapped }
  },
)

export const createAdminMachineThunk = createAsyncThunk<
  Api.AdminMachine,
  Api.AdminMachinePayload,
  { rejectValue: ApiErrorMessage }
>(K.CREATE_ADMIN_MACHINE, async (machinePayload: Api.AdminMachinePayload, thunkApi) => {
  try {
    const data = (await machineApi.postAdminMachine(machinePayload)) as Api.AdminMachine
    const mapped = mapMachine(data)
    return mapped
  } catch (error) {
    console.error(error)
    return thunkApi.rejectWithValue(error.data as ApiErrorMessage)
  }
})

export const deleteAdminMachineThunk = createAsyncThunk<
  Api.AdminMachine,
  number,
  { rejectValue: ApiErrorMessage }
>(K.DELETE_ADMIN_MACHINE, async (machineId: number, thunkApi) => {
  try {
    const data = (await machineApi.deleteAdminMachineById(machineId)) as Api.AdminMachine
    const mapped = mapMachine(data)
    return mapped
  } catch (error) {
    console.error(error)
    return thunkApi.rejectWithValue(error.data as ApiErrorMessage)
  }
})

export const updateAdminMachineThunk = createAsyncThunk<
  Api.AdminMachine,
  Api.AdminMachineActionPayload,
  { rejectValue: ApiErrorMessage }
>(K.UPDATE_ADMIN_MACHINE, async ({ machineId, machine }: Api.AdminMachineActionPayload, thunkApi) => {
  try {
    const data = (await machineApi.updateAdminMachineById(machineId, machine)) as Api.AdminMachine
    const mapped = mapMachine(data)
    return mapped
  } catch (error) {
    console.error(error)
    return thunkApi.rejectWithValue(error.data as ApiErrorMessage)
  }
})
