import { configureStore } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'

import type { TypedUseSelectorHook } from 'react-redux'

import { STATE_KEY as USER_KEY, reducer as userReducer } from './user'
import { STATE_KEY as ADMIN_KEY, reducer as adminReducer } from './admin'
import { STATE_KEY as MACHINE_KEY, reducer as machinesReducer } from './machines'

export const store = configureStore({
  reducer: {
    [USER_KEY]: userReducer,
    [ADMIN_KEY]: adminReducer,
    [MACHINE_KEY]: machinesReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false, thunk: true }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store
