import { AxiosError } from 'axios'
import { config } from 'core'
import { getAxios } from './apiService'
import { getApiError, getAuthenticationHeader } from './common'

const axios = getAxios()

export async function getTimelineWordDocument(
  machineId: number,
  report: string,
  lang: string,
  start: string,
  end: string,
): Promise<string | ApiError> {
  try {
    const { headers } = await getAuthenticationHeader()
    const response = await axios.get<string>(
      config.api.getTimelineWordDocument(machineId, report, lang, start, end),
      {
        headers,
        responseType: 'blob',
      },
    )
    return response.data
  } catch (error) {
    return getApiError(error as AxiosError)
  }
}

export async function getEvalWordDocument(
  machineId: number,
  evalId: number,
  report: string,
  lang: string,
  start: string,
  end: string,
): Promise<string | ApiError> {
  try {
    const { headers } = await getAuthenticationHeader()
    const response = await axios.get<string>(
      config.api.getEvalWordDocument(machineId, evalId, report, lang, start, end),
      {
        headers,
        responseType: 'blob',
      },
    )
    return response.data
  } catch (error) {
    return getApiError(error as AxiosError)
  }
}
