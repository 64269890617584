import React from 'react'
import RealtimeInOutBox from 'components/RealtimeInOutBox'
import { BoxType, ProductType } from 'types/enums'

import type * as Store from 'types/store'

import { groupBoxesByLabel } from '../VoltageBox'
import { getDataDashboard } from './getDataDashboard'
import { RealtimeOutBox } from 'components/RealtimeOutBox'
import { useTranslation } from 'react-i18next'

interface DashboardProps {
  machine: Store.MachineDetail
  realtimeData: Store.MachineRealTimeAllResponse
  realtimeIsLoading: boolean
  realtimeError: unknown
}

export const DashboardBox: React.FC<DashboardProps> = ({
  machine,
  realtimeData,
  realtimeError,
  realtimeIsLoading,
}) => {
  const { t } = useTranslation()

  const currentBox = groupBoxesByLabel(machine?.currentBoxes)
  const voltageBox = groupBoxesByLabel(machine?.voltageBoxes)
  const ithdBox = groupBoxesByLabel(machine?.ithdBoxes)
  const vthdBox = groupBoxesByLabel(machine?.vthdBoxes)
  const powerFactorBox = groupBoxesByLabel(machine?.powerFactorBoxes)
  const powerBox = groupBoxesByLabel(machine?.powerBoxes)

  const AllDashboardData = getDataDashboard(realtimeData)
  if (realtimeError || realtimeIsLoading || !realtimeData) {
    if (machine.product === ProductType.Ecometer) {
      return (
        <div className="realtime-boxes realtime-boxes--dashboard">
          <RealtimeOutBox
            box={voltageBox[BoxType.VConcatenata][0]}
            value={null}
            title={BoxType.VConcatenata}
            unit={t('metricUnit.voltage')}
          />
          <RealtimeOutBox
            box={voltageBox[BoxType.VFase][0]}
            value={null}
            title={BoxType.VFase}
            unit={t('metricUnit.voltage')}
          />
          <RealtimeOutBox
            box={currentBox[BoxType.Current][0]}
            value={null}
            title={BoxType.Current}
            unit={t('metricUnit.current')}
          />
          <RealtimeOutBox
            box={powerFactorBox[BoxType.PowerFactor][0]}
            value={null}
            title={BoxType.PowerFactor}
          />
          <RealtimeOutBox
            box={ithdBox[BoxType.ITHD][0]}
            value={null}
            title={BoxType.ITHD}
            unit={t('metricUnit.percentage')}
          />
          <RealtimeOutBox
            box={vthdBox[BoxType.VTHD][0]}
            value={null}
            title={BoxType.VTHD}
            unit={t('metricUnit.percentage')}
          />
        </div>
      )
    } else {
      return (
        <>
          <div className="realtime-boxes realtime-boxes--dashboard">
            <RealtimeInOutBox
              box={voltageBox[BoxType.VConcatenata]}
              inValue={null}
              outValue={null}
              title={BoxType.VConcatenata}
              unit={t('metricUnit.voltage')}
            />
            <RealtimeInOutBox
              box={voltageBox[BoxType.VFase]}
              inValue={null}
              outValue={null}
              title={BoxType.VFase}
              unit={t('metricUnit.voltage')}
            />
            <RealtimeInOutBox
              box={currentBox[BoxType.Current]}
              inValue={null}
              outValue={null}
              title={BoxType.Current}
              unit={t('metricUnit.current')}
            />

            <RealtimeInOutBox
              box={powerFactorBox[BoxType.PowerFactor]}
              inValue={null}
              outValue={null}
              title={BoxType.PowerFactor}
            />

            <RealtimeOutBox
              box={powerBox?.potenzaAttiva[0]}
              value={null}
              title={BoxType.PotenzaAttiva}
              unit={t('metricUnit.voltage')}
            />

            <RealtimeOutBox
              box={powerBox?.potenzaReattiva[0]}
              value={null}
              title={BoxType.PotenzaReattiva}
              unit={t('metricUnit.reactivePower')}
            />

            <RealtimeOutBox
              box={powerBox?.potenzaApparente[0]}
              value={null}
              title={BoxType.PotenzaApparente}
              unit={t('metricUnit.apparentPower')}
            />
            <RealtimeInOutBox
              box={ithdBox[BoxType.ITHD]}
              inValue={null}
              outValue={null}
              title={BoxType.ITHD}
              unit={t('metricUnit.percentage')}
            />
            <RealtimeInOutBox
              box={vthdBox[BoxType.VTHD]}
              inValue={null}
              outValue={null}
              title={BoxType.VTHD}
              unit={t('metricUnit.percentage')}
            />
          </div>
        </>
      )
    }
  }

  if (machine.product === ProductType.Ecometer) {
    return (
      <div className="realtime-boxes realtime-boxes--dashboard">
        <RealtimeOutBox
          box={voltageBox[BoxType.VConcatenata][0]}
          value={AllDashboardData.voltage.inVConcatenata}
          title={BoxType.VConcatenata}
          unit={t('metricUnit.voltage')}
        />
        <RealtimeOutBox
          box={voltageBox[BoxType.VFase][0]}
          value={AllDashboardData.voltage.inVFase}
          title={BoxType.VFase}
          unit={t('metricUnit.voltage')}
        />
        <RealtimeOutBox
          box={currentBox[BoxType.Current][0]}
          value={AllDashboardData.current.in}
          title={BoxType.Current}
          unit={t('metricUnit.current')}
        />
        <RealtimeOutBox
          box={powerFactorBox[BoxType.PowerFactor][0]}
          value={AllDashboardData.powerFactor.in}
          title={BoxType.PowerFactor}
        />
        <RealtimeOutBox
          box={ithdBox[BoxType.ITHD][0]}
          value={AllDashboardData.ithd.in}
          title={BoxType.ITHD}
          unit={t('metricUnit.percentage')}
        />
        <RealtimeOutBox
          box={vthdBox[BoxType.VTHD][0]}
          value={AllDashboardData.vthd.in}
          title={BoxType.VTHD}
          unit={t('metricUnit.percentage')}
        />
      </div>
    )
  } else {
    return (
      <>
        <div className="realtime-boxes realtime-boxes--dashboard">
          <RealtimeInOutBox
            box={voltageBox[BoxType.VConcatenata]}
            inValue={AllDashboardData.voltage.inVConcatenata}
            outValue={AllDashboardData.voltage.outVConcatenata}
            title={BoxType.VConcatenata}
            unit={t('metricUnit.voltage')}
          />
          <RealtimeInOutBox
            box={voltageBox[BoxType.VFase]}
            inValue={AllDashboardData.voltage.inVFase}
            outValue={AllDashboardData.voltage.outVFase}
            title={BoxType.VFase}
            unit={t('metricUnit.voltage')}
          />
          <RealtimeInOutBox
            box={currentBox[BoxType.Current]}
            inValue={AllDashboardData.current.in}
            outValue={AllDashboardData.current.in}
            title={BoxType.Current}
            unit={t('metricUnit.current')}
          />

          <RealtimeInOutBox
            box={powerFactorBox[BoxType.PowerFactor]}
            inValue={AllDashboardData.powerFactor.in}
            outValue={AllDashboardData.powerFactor.out}
            title={BoxType.PowerFactor}
          />

          <RealtimeOutBox
            box={powerBox?.potenzaAttiva[0]}
            value={AllDashboardData.power?.potenzaAttiva}
            title={BoxType.PotenzaAttiva}
            unit={t('metricUnit.power')}
          />

          <RealtimeOutBox
            box={powerBox?.potenzaReattiva[0]}
            value={AllDashboardData.power?.potenzaReattiva}
            title={BoxType.PotenzaReattiva}
            unit={t('metricUnit.reactivePower')}
          />

          <RealtimeOutBox
            box={powerBox?.potenzaApparente[0]}
            value={AllDashboardData.power?.potenzaApparente}
            title={BoxType.PotenzaApparente}
            unit={t('metricUnit.apparentPower')}
          />
          <RealtimeInOutBox
            box={ithdBox[BoxType.ITHD]}
            inValue={AllDashboardData.ithd.in}
            outValue={AllDashboardData.ithd.out}
            title={BoxType.ITHD}
            unit={t('metricUnit.percentage')}
          />
          <RealtimeInOutBox
            box={vthdBox[BoxType.VTHD]}
            inValue={AllDashboardData.vthd.in}
            outValue={AllDashboardData.vthd.out}
            title={BoxType.VTHD}
            unit={t('metricUnit.percentage')}
          />
        </div>
      </>
    )
  }
}

export default DashboardBox
