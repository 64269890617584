import type * as Api from 'types/api'
import type * as Store from 'types/store'
import { mapDate } from './map-date'

export const mapMachineEvalution = ({
  machineId,
  name,
  serial,
  product,
  displayId,
  evalId,
  begin,
  end,
  duration,
  cumulativeSavingKwh,
  cumulativeSavingPerc,
  note,
}: Api.MachineEvalutionItem): Store.MachineEvalutionItem => {
  return {
    machineId,
    name,
    serial,
    product,
    displayId,
    evalId,
    begin: mapDate(begin),
    end: mapDate(end),
    duration,
    cumulativeSavingKwh,
    cumulativeSavingPerc,
    note,
  }
}

export default mapMachineEvalution
