import RealtimeInOutBox from 'components/RealtimeInOutBox'
import React from 'react'
import { BoxType } from 'types/enums'
import type * as Store from 'types/store'
import { groupBoxesByLabel } from '../VoltageBox'
import { useTranslation } from 'react-i18next'

interface VthdBoxProps {
  boxes: Store.MachineBox[]
  realtimeData: Store.MachineRealTimeResponse<Store.RealtimeVthdData>
  realtimeIsLoading: boolean
  realtimeError: unknown
}

export const VthdBox: React.FC<VthdBoxProps> = ({ boxes, realtimeData, realtimeError }) => {
  const { t } = useTranslation()
  if (realtimeError) {
    // TODO: better error handling
  }

  const VthdBoxByLabel = groupBoxesByLabel(boxes)

  const value = realtimeData?.metrics
    ? {
        inVTHD: [
          realtimeData.metrics.thdTensionInAvg,
          realtimeData.metrics.thdTensionInPhaseR,
          realtimeData.metrics.thdTensionInPhaseS,
          realtimeData.metrics.thdTensionInPhaseT,
        ],
        outVTHD: [
          realtimeData.metrics.thdTensionOutAvg,
          realtimeData.metrics.thdTensionOutPhaseU,
          realtimeData.metrics.thdTensionOutPhaseV,
          realtimeData.metrics.thdTensionOutPhaseW,
        ],
      }
    : null

  return (
    <>
      <div className="realtime-boxes">
        <RealtimeInOutBox
          box={VthdBoxByLabel && VthdBoxByLabel[BoxType.VTHD]}
          inValue={value?.inVTHD}
          outValue={value?.outVTHD}
          unit={t('metricUnit.percentage')}
          title={BoxType.VTHD}
        />
      </div>
    </>
  )
}

export default VthdBox
