import type * as Api from 'types/api'
import type * as Store from 'types/store'

export const mapNewsArticle = ({
  id,
  publishedAt,
  guid,
  modifiedAt,
  slug,
  link,
  title,
  imageUrl,
}: Api.NewsArticle): Store.NewsArticle => ({
  id,
  publishedAt,
  guid,
  modifiedAt,
  slug,
  link,
  title,
  imageUrl,
})

export default mapNewsArticle
