import { AxiosError, AxiosResponse } from 'axios'

import { getAxios } from './apiService'
import { getApiError, getAuthenticationHeader } from './common'

const axios = getAxios()

interface Options<D = any> {
  method?: string
  data?: D
  params?: { [key: string]: string }
  headers?: { [key: string]: string }
}

export const getSwrFetcher =
  <T = any, D = any>({ method, params, headers }: Options<D> = {}, mapper?: (data: any) => T) =>
  async (url: string, { arg }: { arg?: D } = {}): Promise<T> => {
    try {
      const { headers: authHeader } = await getAuthenticationHeader()
      const response = await axios<T, AxiosResponse<T, D>, D>({
        url,
        method,
        data: arg,
        params,
        headers: { ...authHeader, ...headers },
      })
      return typeof mapper === 'function' ? mapper(response.data) : response.data
    } catch (error) {
      throw getApiError(error as AxiosError)
    }
  }

export default getSwrFetcher
