import React from 'react'

import RealtimeInOutBox from 'components/RealtimeInOutBox'
import { BoxType } from 'types/enums'

import type * as Store from 'types/store'

import { groupBoxesByLabel } from '../VoltageBox'
import { useTranslation } from 'react-i18next'

interface CurrentBoxProps {
  boxes: Store.MachineBox[]
  realtimeData: Store.MachineRealTimeResponse<Store.RealtimeCurrentData>
  realtimeIsLoading: boolean
  realtimeError: unknown
}

export const CurrentBox: React.FC<CurrentBoxProps> = ({ boxes, realtimeData, realtimeError }) => {
  const { t } = useTranslation()

  if (realtimeError) {
    // TODO: better error handling
  }

  const currentBoxesByLabel = groupBoxesByLabel(boxes)

  const value = realtimeData?.metrics
    ? {
        inCurrent: [
          realtimeData.metrics.currentInAvg,
          realtimeData.metrics.currentInPhaseR,
          realtimeData.metrics.currentInPhaseS,
          realtimeData.metrics.currentInPhaseT,
        ],
        outCurrent: [
          realtimeData.metrics.currentOutAvg,
          realtimeData.metrics.currentOutPhaseU,
          realtimeData.metrics.currentOutPhaseV,
          realtimeData.metrics.currentOutPhaseW,
        ],
        unit: t('metricUnit.current'),
      }
    : null

  return (
    <div className="realtime-boxes">
      <RealtimeInOutBox
        box={currentBoxesByLabel && currentBoxesByLabel[BoxType.Current]}
        inValue={value?.inCurrent}
        outValue={value?.outCurrent}
        unit={value?.unit}
        title="current"
      />
    </div>
  )
}

export default CurrentBox
