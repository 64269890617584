import dayjs from 'dayjs'
import type * as Api from 'types/api'
import type * as Store from 'types/store'

export const mapMachineLatestThdData = ({
  bounds: { begin, end },
  ithd,
  vthd,
}: Api.MachineLatestThdData): Store.MachineLatestThdData => ({
  bounds: { begin: dayjs(begin).toDate(), end: dayjs(end).toDate() },
  ithd: ithd.map(({ t, ithdIn, ithdOut }) => ({ t: dayjs(t).toDate(), ithdIn, ithdOut })),
  vthd: vthd.map(({ t, vthdIn, vthdOut }) => ({ t: dayjs(t).toDate(), vthdIn, vthdOut })),
})

export default mapMachineLatestThdData
