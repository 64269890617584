import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { getCurrentRoute } from 'core/helpers'

const Seo: React.FC = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const currentRoute = getCurrentRoute(location.pathname)

  const baseTitle = t('general.headTitle')
  const title = currentRoute?.headTitle ? `${baseTitle} - ${t(currentRoute.headTitle)}` : baseTitle

  const baseSeoDescription = t('seo.description')
  const seoDescription = currentRoute?.seoDescription
    ? `${baseSeoDescription} - ${t(currentRoute.seoDescription)}`
    : baseSeoDescription

  const baseSeoKeywords = t('seo.keywords')
  const seoKeywords = currentRoute?.seoKeywords
    ? `${baseSeoKeywords} - ${t(currentRoute.seoKeywords)}`
    : baseSeoKeywords

  return (
    <Helmet key={location.pathname}>
      <title>{title}</title>
      <meta name="description" content={seoDescription} />
      <meta name="keywords" content={seoKeywords} />
    </Helmet>
  )
}

export default Seo
