import { isNumeric, toNumber } from 'core'

import * as Api from 'types/api'
import * as Store from 'types/store'

import { mapDate } from './map-date'

export const mapMachineMetricsByPeriod = ({
  begin,
  end,
  data: { min, max, avg, out },
}: Api.MachineMetricsByPeriod): Store.MachineMetricsByPeriod => ({
  begin: mapDate(begin),
  end: mapDate(end),
  data: {
    min: isNumeric(min) ? toNumber(min) : null,
    max: isNumeric(max) ? toNumber(max) : null,
    avg: isNumeric(avg) ? toNumber(avg) : null,
    out: isNumeric(out) ? toNumber(out) : null,
  },
})

export const mapMachineEnergyMetricsByPeriod = ({
  begin,
  end,
  data: { max_att, min_att, avg_att, max_reac, min_reac, avg_reac, max_reai, min_reai, avg_reai },
}: Api.MachineEnergyMetricsByPeriod): Store.MachineEnergyMetricsByPeriod => ({
  begin: mapDate(begin),
  end: mapDate(end),
  data: {
    att: {
      max: isNumeric(max_att) ? toNumber(max_att) : null,
      min: isNumeric(min_att) ? toNumber(min_att) : null,
      avg: isNumeric(avg_att) ? toNumber(avg_att) : null,
    },
    reac: {
      max: isNumeric(max_reac) ? toNumber(max_reac) : null,
      min: isNumeric(min_reac) ? toNumber(min_reac) : null,
      avg: isNumeric(avg_reac) ? toNumber(avg_reac) : null,
    },
    reai: {
      max: isNumeric(max_reai) ? toNumber(max_reai) : null,
      min: isNumeric(min_reai) ? toNumber(min_reai) : null,
      avg: isNumeric(avg_reai) ? toNumber(avg_reai) : null,
    },
  },
})

export default mapMachineMetricsByPeriod
