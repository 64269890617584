import type * as Api from 'types/api'
import type * as Store from 'types/store'

export const mapRegistryPicklist = ({
  sectors,
  countries,
  provinces,
}: Api.RegistryPicklist): Store.RegistryPicklist => ({
  sectors,
  countries,
  provinces,
})

export default mapRegistryPicklist
