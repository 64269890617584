import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Tabs, TabsProps, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import { ListMapView, ProductType } from 'types/enums'
import { useMachinesListWithRealtime } from 'hooks'
import MachineList from 'components/MachineList'
import * as Store from 'types/store'
export interface AllMachinesTabsProps {
  isMapPage?: boolean
  setDataType?: (type: ListMapView) => void
  machineGeocode?: Store.MachineGeocode[]
  setActiveTab?: (activeTab: ProductType) => void
  activeTab?: ProductType
  renderTabBar?: TabsProps['renderTabBar']
}

export const AllMachinesTabs: React.FC<AllMachinesTabsProps> = ({
  isMapPage = false,
  setDataType,
  setActiveTab,
  renderTabBar,
}: AllMachinesTabsProps) => {
  const isMap = window.location.href.includes('map')

  const { t } = useTranslation()
  const {
    data: machinesList,
    machinesListLoading,
    realtimeLoading,
    machinesError,
    realtimeError,
  } = useMachinesListWithRealtime()

  const allCount = (machinesList ?? []).length
  const ecostabCount = (machinesList ?? []).filter((m) => m.product === ProductType.Ecostab).length
  const sterostabCount = (machinesList ?? []).filter((m) => m.product === ProductType.Sterostab).length
  const ecometerCount = (machinesList ?? []).filter((m) => m.product === ProductType.Ecometer).length

  const getOnlineDevices = useCallback(
    (type?: ProductType) => {
      if (!type) {
        const allOnline = machinesList?.filter((m) => m.realtimeData?.online)?.length
        return allOnline ?? 0
      }
      const devices = machinesList?.filter((m) => m.product === type)
      const online = devices?.filter((m) => m.realtimeData?.online)?.length
      return online ?? 0
    },
    [machinesList, machinesListLoading],
  )

  const renderLabel = (count: number, translation: string, productType?: ProductType): JSX.Element => {
    const isAll = typeof productType === 'undefined'
    const onlineDevices = isAll ? getOnlineDevices() : getOnlineDevices(productType)
    const translatedLabel = t(`general.${translation}`)

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>{`${translatedLabel} (${count}`}</span>
        {/* 14/11/2024 Fabrizio - hiding online device counter for the "all" label after a client request */}
        {!isAll && (
          <>
            <span>{'\u00A0/\u00A0'}</span>
            <span className="counter-device">{onlineDevices}</span>
          </>
        )}
        <span>{')'}</span>
      </div>
    )
  }

  const items = useMemo<TabsProps['items']>(
    () =>
      !isMap
        ? [
            {
              key: 'all',
              label: renderLabel(allCount, 'all'),
              children: isMapPage ? (
                <></>
              ) : (
                <MachineList
                  machines={machinesList}
                  machineLoading={machinesListLoading}
                  realtimeLoading={realtimeLoading}
                  machineError={machinesError}
                  realtimeError={realtimeError}
                  setDataType={setDataType}
                />
              ),
            },
            {
              key: ProductType.Ecostab,
              label: renderLabel(ecostabCount, 'ecostab', ProductType.Ecostab),
              children: isMapPage ? (
                <></>
              ) : (
                <MachineList
                  machines={machinesList}
                  machineLoading={machinesListLoading}
                  realtimeLoading={realtimeLoading}
                  machineError={machinesError}
                  realtimeError={realtimeError}
                  filterProduct={ProductType.Ecostab}
                  setDataType={setDataType}
                />
              ),
            },
            {
              key: ProductType.Sterostab,
              label: renderLabel(sterostabCount, 'sterostab', ProductType.Sterostab),
              children: isMapPage ? (
                <></>
              ) : (
                <MachineList
                  machines={machinesList}
                  machineLoading={machinesListLoading}
                  realtimeLoading={realtimeLoading}
                  machineError={machinesError}
                  realtimeError={realtimeError}
                  filterProduct={ProductType.Sterostab}
                  setDataType={setDataType}
                />
              ),
            },
            {
              key: ProductType.Ecometer,
              label: renderLabel(ecometerCount, 'ecometer', ProductType.Ecometer),
              children: isMapPage ? (
                <></>
              ) : (
                <MachineList
                  machines={machinesList}
                  machineLoading={machinesListLoading}
                  realtimeLoading={realtimeLoading}
                  machineError={machinesError}
                  realtimeError={realtimeError}
                  filterProduct={ProductType.Ecometer}
                  setDataType={setDataType}
                />
              ),
            },
          ]
        : [
            {
              key: 'all',
              label: renderLabel(allCount, 'all'),
              children: isMapPage ? (
                <></>
              ) : (
                <MachineList
                  machines={machinesList}
                  machineLoading={machinesListLoading}
                  realtimeLoading={realtimeLoading}
                  machineError={machinesError}
                  realtimeError={realtimeError}
                  setDataType={setDataType}
                />
              ),
            },
            {
              key: ProductType.Ecostab,
              label: renderLabel(ecostabCount, 'ecostab', ProductType.Ecostab),
              children: isMapPage ? (
                <></>
              ) : (
                <MachineList
                  machines={machinesList}
                  machineLoading={machinesListLoading}
                  realtimeLoading={realtimeLoading}
                  machineError={machinesError}
                  realtimeError={realtimeError}
                  filterProduct={ProductType.Ecostab}
                  setDataType={setDataType}
                />
              ),
            },
            {
              key: ProductType.Sterostab,
              label: renderLabel(sterostabCount, 'sterostab', ProductType.Sterostab),
              children: isMapPage ? (
                <></>
              ) : (
                <MachineList
                  machines={machinesList}
                  machineLoading={machinesListLoading}
                  realtimeLoading={realtimeLoading}
                  machineError={machinesError}
                  realtimeError={realtimeError}
                  filterProduct={ProductType.Sterostab}
                  setDataType={setDataType}
                />
              ),
            },
          ],
    [machinesList, machinesListLoading, machinesError],
  )

  return (
    <div className={renderTabBar ? 'custom-tabs-wrapper' : 'all-machines-tabs-wrapper'}>
      <Tabs
        className="all-machines-tabs"
        size="large"
        defaultActiveKey="all"
        items={items}
        renderTabBar={renderTabBar}
        onChange={setActiveTab}
      />
    </div>
  )
}

export default AllMachinesTabs
