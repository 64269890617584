import { getAxios } from './apiService'
import type * as Api from 'types/api'
import { config } from 'core'
import { getApiError, getAuthenticationHeader } from './common'
import { AxiosError, AxiosResponse } from 'axios'

const axios = getAxios()

export async function getAdminCodes({ q, o, sz, n }: Api.PaginationParams = {}): Promise<
  Api.PaginatedResult<Api.AdminCode> | ApiError
> {
  try {
    const { headers } = await getAuthenticationHeader()
    const response = await axios.get<Api.PaginatedResult<Api.AdminCode>>(config.api.getAdminCodes(), {
      params: { q, o, sz: sz?.toString(), n: n?.toString() },
      headers,
    })
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function postAdminCode(newcodePayload: Api.AdminCodePayload): Promise<Api.AdminCode | ApiError> {
  try {
    const response = await axios.post<Api.AdminCode, AxiosResponse<Api.AdminCode>, Api.AdminCodePayload>(
      config.api.postAdminCode(),
      newcodePayload,
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}
