import React from 'react'

import RealtimeInOutBox from 'components/RealtimeInOutBox'
import { BoxType } from 'types/enums'

import type * as Store from 'types/store'
import { Button, Carousel, CarouselProps } from 'antd'
import { ReactComponent as ArrowIcon } from 'assets/icon/arrow-icon.svg'
import { useTranslation } from 'react-i18next'

interface VoltageBoxProps {
  boxes: Store.MachineBox[]
  realtimeData: Store.MachineRealTimeResponse<Store.RealtimeVoltageData>
  realtimeIsLoading: boolean
  realtimeError: unknown
}

export function groupBoxesByLabel(voltageBoxes: Store.MachineBox[]) {
  return voltageBoxes?.reduce((acc, vb) => {
    const { mainValueLabel } = vb
    if (!acc[mainValueLabel]) {
      acc[mainValueLabel] = []
    }
    acc[mainValueLabel].push(vb)
    return acc
  }, {} as { [t in BoxType]: Store.MachineBox[] })
}

export const VoltageBox: React.FC<VoltageBoxProps> = ({ boxes, realtimeData, realtimeError }) => {
  const { t } = useTranslation()
  if (realtimeError) {
    // TODO: better error handling
  }

  const carouselConfig: CarouselProps = {
    infinite: false,
    variableWidth: true,
    dots: false,
    draggable: true,
    arrows: true,
    nextArrow: <Button type="text" icon={<ArrowIcon />} />,
    prevArrow: <Button type="text" icon={<ArrowIcon />} />,
    responsive: [
      {
        breakpoint: 1232,
        settings: {
          variableWidth: false,
        },
      },
      {
        breakpoint: 1198,
        settings: {
          variableWidth: true,
        },
      },
      {
        breakpoint: 418,
        settings: {
          variableWidth: false,
        },
      },
    ],
  }

  const value = realtimeData?.metrics
    ? {
        inVConcatenata: [
          realtimeData.metrics.tensionInConcatenatedAvg,
          realtimeData.metrics.tensionInConcatenatedPhaseRs,
          realtimeData.metrics.tensionInConcatenatedFaseSt,
          realtimeData.metrics.tensionInConcatenatedFaseTr,
        ],
        outVConcatenata: [
          realtimeData.metrics.tensionOutConcatenatedAvg,
          realtimeData.metrics.tensionOutConcatenatedPhaseUv,
          realtimeData.metrics.tensionOutConcatenatedPhaseVw,
          realtimeData.metrics.tensionOutConcatenatedPhaseWu,
        ],
        inVFase: [
          realtimeData.metrics.tensionInAvg,
          realtimeData.metrics.tensionInPhaseR,
          realtimeData.metrics.tensionInPhaseS,
          realtimeData.metrics.tensionInPhaseT,
        ],
        outVFase: [
          realtimeData.metrics.tensionOutAvg,
          realtimeData.metrics.tensionOutPhaseU,
          realtimeData.metrics.tensionOutPhaseV,
          realtimeData.metrics.tensionOutPhaseW,
        ],
      }
    : null

  const voltageBoxesByLabel = groupBoxesByLabel(boxes)

  return (
    <div className="realtime-boxes">
      <Carousel {...carouselConfig}>
        <RealtimeInOutBox
          box={voltageBoxesByLabel && voltageBoxesByLabel[BoxType.VConcatenata]}
          inValue={value?.inVConcatenata}
          outValue={value?.outVConcatenata}
          title="VConcatenata"
          unit={t('metricUnit.voltage')}
        />
        <RealtimeInOutBox
          box={voltageBoxesByLabel && voltageBoxesByLabel[BoxType.VFase]}
          inValue={value?.inVFase}
          outValue={value?.outVFase}
          title="VFase"
          unit={t('metricUnit.voltage')}
        />
      </Carousel>
    </div>
  )
}

export default VoltageBox
