import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react'
import * as Store from 'types/store'

import Map, {
  FullscreenControl,
  GeolocateControl,
  NavigationControl,
  ScaleControl,
  MapRef,
  Source,
  Layer,
} from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import PopupMap from './PopupMap'
import { config } from 'core'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import mapboxgl from '!mapbox-gl'

import { clusterLayer, clusterCountLayer, unclusteredPointLayer } from './layers'
import { GeoJSONSourceOptions } from 'mapbox-gl'

interface Props {
  machineGeocode: Store.MachineGeocode[]
  selectedMachines: Store.MachineWithRealtime[]
  setSelectedMachinesId: (selectedMachineId: number[]) => void
  lang?: string
}

interface LatLonViewPort {
  latitude: number
  longitude: number
  zoom: number
}

export const MachineMap: React.FC<Props> = ({
  machineGeocode,
  selectedMachines,
  setSelectedMachinesId,

  lang,
}) => {
  const mapRef = useRef<MapRef>()
  const [hasMapLoaded, setHasMapLoaded] = useState(false)
  const [viewport, setViewport] = useState<LatLonViewPort>({
    latitude: config.defaultLatMapView,
    longitude: config.defaultLonMapView,
    zoom: config.defaultZoomMapView,
  })
  const [popupInfo, setPopupInfo] = useState<Store.MachineGeocode & { mode?: 'select' }>()

  const onPopupClose = () => {
    setSelectedMachinesId([])
    setPopupInfo(null)
  }

  const shape: GeoJSONSourceOptions['data'] = useMemo(
    () => ({
      type: 'FeatureCollection',
      features: machineGeocode?.map((mg) => ({
        type: 'Feature',
        properties: mg,
        geometry: {
          type: 'Point',
          coordinates: [mg?.coordinates?.longitude, mg?.coordinates?.latitude],
        },
      })),
    }),
    [machineGeocode],
  )

  useEffect(() => {
    if (selectedMachines.length > 0) {
      const geocode = machineGeocode?.find((mg) => selectedMachines[0].id === mg.id)

      if (selectedMachines.length === 1) {
        mapRef.current?.flyTo({
          center: [geocode?.coordinates.longitude, geocode?.coordinates.latitude],
          duration: config.defaultZoomMapViewDuration,
          zoom: config.defaultZoomMapViewSelectedMachine,
        })

        setViewport({
          ...viewport,
          latitude: geocode?.coordinates.latitude,
          longitude: geocode?.coordinates.longitude,
          zoom: config.defaultZoomMapViewSelectedMachine,
        })
      }

      setPopupInfo({
        ...geocode,
        mode: 'select',
      })
    } else {
      setPopupInfo({
        id: null,
        address: '',
        coordinates: {
          longitude: null,
          latitude: null,
        },
        error: '',
        mode: 'select',
      })
    }
  }, [selectedMachines, machineGeocode, setViewport, setPopupInfo])

  const clusterHandler = useCallback(
    async (firstFeature: any) => {
      const source = mapRef.current.getSource('machines') as any

      const clusterId = firstFeature.properties?.cluster_id

      source.getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) {
          return
        }

        mapRef.current.easeTo({
          center: firstFeature.geometry.coordinates,
          zoom,
          duration: config.defaultZoomMapViewDuration,
        })

        // two machines in the same spot, share machines ids
        if (zoom >= config.defaultMaxZoomMapView) {
          source.getClusterLeaves(clusterId, 9999, 0, (err, features) => {
            const clusteredMachineIds: number[] = features?.map((feature) => feature?.properties?.id) ?? []
            setSelectedMachinesId([...clusteredMachineIds])
          })
        }
      })
    },
    [setSelectedMachinesId],
  )

  const onMapClick = useCallback(
    async (e) => {
      setSelectedMachinesId([])

      const firstFeature = e?.features[0]

      if (!firstFeature) return

      const isCluster = firstFeature.properties?.cluster

      if (isCluster) {
        clusterHandler(firstFeature)
        return
      }

      const singleMachineId: number = firstFeature.properties?.id

      setSelectedMachinesId([singleMachineId])
    },
    [setSelectedMachinesId],
  )

  const labelLayers = useMemo(() => {
    if (!mapRef.current || !hasMapLoaded) return []

    const map = mapRef.current.getMap()
    return map.getStyle().layers.filter((layer) => /-label/.test(layer.id))
  }, [hasMapLoaded, mapRef])

  useEffect(() => {
    if (hasMapLoaded && mapRef.current) {
      const map = mapRef.current.getMap()
      labelLayers.forEach((labelLayer) => {
        map.setLayoutProperty(labelLayer.id, 'text-field', [
          'coalesce',
          ['get', 'name_' + lang],
          ['get', 'name'],
        ])
      })
    }
  }, [lang, hasMapLoaded, labelLayers, mapRef])

  return (
    <>
      <Map
        ref={mapRef}
        {...viewport}
        maxZoom={config.defaultMaxZoomMapView}
        mapLib={mapboxgl}
        mapStyle="mapbox://styles/mapbox/streets-v12"
        mapboxAccessToken={config.mapboxToken}
        onMove={(evt) => setViewport(evt.viewState)}
        interactiveLayerIds={[clusterLayer.id, unclusteredPointLayer.id]}
        onLoad={() => setHasMapLoaded(true)}
        onClick={onMapClick}
      >
        <Source
          id="machines"
          type="geojson"
          data={shape}
          cluster={true}
          clusterMaxZoom={18}
          clusterRadius={50}
        >
          <Layer {...clusterLayer} />
          <Layer {...clusterCountLayer} />
          <Layer {...unclusteredPointLayer} />
        </Source>
        <GeolocateControl position="top-left" />
        <FullscreenControl position="top-left" />
        <NavigationControl position="top-left" />
        <ScaleControl />
        {popupInfo?.id && (
          <PopupMap machines={selectedMachines} popupInfo={popupInfo} onClose={onPopupClose} />
        )}
      </Map>
    </>
  )
}

export default MachineMap
