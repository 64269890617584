import RealtimeOutBox from 'components/RealtimeOutBox/RealtimeOutBox'
import React from 'react'
import type * as Store from 'types/store'
import { groupBoxesByLabel } from '../VoltageBox'
import { BoxType } from 'types/enums'
import { Button, Carousel, CarouselProps } from 'antd'
import { ReactComponent as ArrowIcon } from 'assets/icon/arrow-icon.svg'
import { useTranslation } from 'react-i18next'

interface PowerBoxProps {
  boxes: Store.MachineBox[]
  realtimeData: Store.MachineRealTimeResponse<Store.RealtimePowerData>
  realtimeIsLoading: boolean
  realtimeError: unknown
}

const PowerBox: React.FC<PowerBoxProps> = ({ boxes, realtimeData, realtimeError }) => {
  const { t } = useTranslation()

  if (realtimeError) {
    // TODO: better error handling
  }

  const carouselConfig: CarouselProps = {
    infinite: false,
    variableWidth: true,
    dots: false,
    draggable: true,
    arrows: true,
    nextArrow: <Button type="text" icon={<ArrowIcon />} />,
    prevArrow: <Button type="text" icon={<ArrowIcon />} />,
    responsive: [
      {
        breakpoint: 418,
        settings: {
          variableWidth: false,
        },
      },
    ],
  }

  const value = realtimeData?.metrics
    ? {
        potenzaAttiva: [
          realtimeData.metrics.powerActiveOutAvg,
          realtimeData.metrics.powerActiveOutPhaseU,
          realtimeData.metrics.powerActiveOutPhaseV,
          realtimeData.metrics.powerActiveOutPhaseW,
        ],

        potenzaReattiva: [
          realtimeData.metrics.powerReactiveOutAvg,
          realtimeData.metrics.powerReactiveOutPhaseU,
          realtimeData.metrics.powerReactiveOutPhaseV,
          realtimeData.metrics.powerReactiveOutPhaseW,
        ],

        potenzaApparente: [
          realtimeData.metrics.powerApparentOutAvg,
          realtimeData.metrics.powerApparentOutPhaseU,
          realtimeData.metrics.powerApparentOutPhaseV,
          realtimeData.metrics.powerApparentOutPhaseW,
        ],
      }
    : null

  const boxType = groupBoxesByLabel(boxes)

  return (
    <div className="realtime-boxes">
      <Carousel {...carouselConfig}>
        <RealtimeOutBox
          box={boxType?.potenzaAttiva[0]}
          value={value?.potenzaAttiva}
          title={BoxType.PotenzaAttiva}
          unit={t('metricUnit.activePower')}
        />

        <RealtimeOutBox
          box={boxType?.potenzaReattiva[0]}
          value={value?.potenzaReattiva}
          title={BoxType.PotenzaReattiva}
          unit={t('metricUnit.reactivePower')}
        />

        <RealtimeOutBox
          box={boxType?.potenzaApparente[0]}
          value={value?.potenzaApparente}
          title={BoxType.PotenzaApparente}
          unit={t('metricUnit.apparentPower')}
        />
      </Carousel>
    </div>
  )
}

export default PowerBox
