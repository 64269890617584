import { Button, Drawer, Form, FormInstance, Popconfirm, Space, Typography } from 'antd'
import React, { useEffect, useRef, useState } from 'react'

import { ReactComponent as DeleteIcon } from 'assets/icon/delete-icon.svg'
import { ReactComponent as EditIcon } from 'assets/icon/edit-icon.svg'
import { ReactComponent as CloseIcon } from 'assets/icon/close-icon.svg'
import { ReactComponent as MapViewIcon } from 'assets/icon/map-icon.svg'
import { ReactComponent as WarningIcon } from 'assets/icon/warning-icon.svg'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import type * as Store from 'types/store'
import styles from './styles.module.scss'

import routes from 'config/routes'
import { SuggestedAddress } from 'containers/MachineDetail'
import { config } from 'core'
import { getUserClaims } from 'core/localStore'
import dayjs from 'dayjs'
import { useTheme } from 'providers'
import { Link } from 'react-router-dom'
import { MachineDetailsBoxMode, ProductType, UserRoles } from 'types/enums'
import TitleValue from './TitleValue'
import { themes } from './themes'

interface UpdateMachineDetailsProps {
  address: string
  sector: string
}

export interface DetailCardProps {
  machine: Store.MachineDetail
  registryPicklist?: Store.RegistryPicklist
  doUpdateMachineDetails?: (machineDetails: UpdateMachineDetailsProps) => void
  doUpdateNickname?: (nickname: string, evalID?: string) => void
  doDeleteMachine?: () => void
  detailsMode?: MachineDetailsBoxMode
  setDetailsMode?: (
    detailsMode: MachineDetailsBoxMode | ((detailsMode: MachineDetailsBoxMode) => MachineDetailsBoxMode),
  ) => void
  isEval?: boolean
  evaluationByIdEval?: Store.MachineEvalutionItem
  setAddress?: (address: string) => void
  suggestedAddress?: SuggestedAddress[]
  setSuggestedAddress?: (SuggestedAddress: SuggestedAddress[]) => void
}

export const MachineDetailsBox: React.FC<DetailCardProps> = ({
  machine,
  registryPicklist,
  doUpdateMachineDetails,
  doUpdateNickname,
  doDeleteMachine,
  setDetailsMode,
  detailsMode,
  isEval,
  evaluationByIdEval,
  setAddress,
  suggestedAddress,
  setSuggestedAddress,
}) => {
  const { t } = useTranslation()

  const { getStyles } = useTheme()
  const themeStyles = getStyles(themes)

  const { userRole } = getUserClaims()

  const [visible, setVisible] = useState(false)
  const [closable, setClosable] = useState(false)

  const [nickname, setNickname] = useState(machine?.note)
  const [evalNickname, setEvalNickname] = useState(evaluationByIdEval?.note)

  const [form] = Form.useForm()

  const resetAddressValidationErr = () => {
    form.setFields([
      {
        name: 'address',
        errors: [],
      },
    ])
  }

  const [machineDetails, setMachineDetails] = useState({
    address: machine?.address || '',
    sector: machine?.sector || '',
  })

  const { address, sector, product } = machine
  const incomplete = product !== ProductType.Ecometer && (address === '' || sector === '')

  useEffect(() => {
    if (incomplete && (userRole === UserRoles.Pro || userRole === UserRoles.User)) {
      setVisible(true)
    }
    setClosable(true)
  }, [machine, userRole])

  const formRef = useRef<FormInstance>()

  const showDrawer = () => {
    setVisible(true)
  }

  const submitHandler = async () => {
    setDetailsMode(MachineDetailsBoxMode.View)
    if (nickname !== machine?.note) {
      doUpdateNickname(nickname)
    }

    if (machineDetails.sector !== machine?.sector || machineDetails.address !== machine?.address) {
      doUpdateMachineDetails(machineDetails)
      setVisible(false)
    }
  }

  const submitEvalFormHandler = async () => {
    setDetailsMode(MachineDetailsBoxMode.View)

    if (evalNickname !== evaluationByIdEval?.note) {
      doUpdateNickname(evalNickname, evaluationByIdEval?.displayId?.toString())
    }
  }

  const isMapVisible =
    (machine?.product === ProductType.Ecostab || machine?.product === ProductType.Sterostab) &&
    machine?.address

  const detailsContent = evaluationByIdEval ? (
    <div className="details-wrapper">
      <div className="title-wrapper">
        <Typography.Title level={4}>{t('general.evaluationDetails')}</Typography.Title>

        <div className="buttons-wrapper">
          <Button
            type="dashed"
            icon={<EditIcon />}
            onClick={() => {
              setEvalNickname(evaluationByIdEval?.note)
              if (formRef.current) {
                formRef.current.resetFields()
              }
              setDetailsMode((boxMode) =>
                boxMode === MachineDetailsBoxMode.Edit
                  ? MachineDetailsBoxMode.View
                  : MachineDetailsBoxMode.Edit,
              )
            }}
          ></Button>
        </div>
      </div>

      <div className="content-wrapper">
        <Form
          ref={formRef}
          name="edit_machine_detail"
          initialValues={{
            evalNickname: evaluationByIdEval?.note,
          }}
          onFinish={submitEvalFormHandler}
        >
          <TitleValue
            name="evalNickname"
            title={t('machineDetail.nickname')}
            value={evaluationByIdEval?.note}
            editable={detailsMode === MachineDetailsBoxMode.Edit}
            onEditChange={(newNickname) => {
              setEvalNickname(newNickname)
            }}
          />
          <TitleValue
            name="evalutionStart"
            title={t('machineDetail.evaluationStart')}
            value={dayjs(evaluationByIdEval?.begin).format('DD/MM/YYYY')}
          />
          <TitleValue
            name="evalutionEnd"
            title={t('machineDetail.evaluationEnd')}
            value={dayjs(evaluationByIdEval?.end).format('DD/MM/YYYY')}
          />
          <TitleValue
            name="duration"
            title={t('machineDetail.duration')}
            value={evaluationByIdEval.duration}
          />

          {detailsMode === MachineDetailsBoxMode.Edit && (
            <div className="buttons-wrapper buttons-wrapper--bottom">
              <Button type="primary" htmlType="submit" className="submit">
                {t('button.submit')}
              </Button>

              <Button
                onClick={() => {
                  setEvalNickname(evaluationByIdEval.note)
                  setDetailsMode(MachineDetailsBoxMode.View)
                  if (formRef.current) {
                    formRef.current.resetFields()
                  }
                }}
                className="cancel"
                type="default"
              >
                {t('button.cancel')}
              </Button>
            </div>
          )}
        </Form>
      </div>
    </div>
  ) : (
    <div className="details-wrapper">
      {incomplete && !visible && (userRole === UserRoles.Admin || userRole === UserRoles.Tester) && (
        <Space>
          <WarningIcon />
          <Typography.Title level={5}>{t('general.incompleteRegistry')}</Typography.Title>
        </Space>
      )}

      <div className="title-wrapper">
        <Typography.Title level={4}>{t('general.details')}</Typography.Title>

        <div className="buttons-wrapper">
          {!visible || (visible && !incomplete) ? (
            <Button
              type="dashed"
              icon={<EditIcon />}
              onClick={() => {
                setMachineDetails({
                  address: machine?.address,
                  sector: machine?.sector,
                })
                setNickname(machine?.note)
                if (formRef.current) {
                  formRef.current.resetFields()
                }
                setDetailsMode((boxMode) =>
                  boxMode === MachineDetailsBoxMode.Edit
                    ? MachineDetailsBoxMode.View
                    : MachineDetailsBoxMode.Edit,
                )
              }}
            ></Button>
          ) : null}
          {detailsMode !== MachineDetailsBoxMode.Edit && (
            <Popconfirm
              placement="bottomRight"
              title={t('messages.deleteMachine.title')}
              description={t('messages.deleteMachine.description')}
              onConfirm={doDeleteMachine}
              okText={t('general.yes')}
              cancelText={t('general.no')}
              getPopupContainer={(trigger) => trigger.parentElement}
            >
              <Button type="dashed" icon={<DeleteIcon />}></Button>
            </Popconfirm>
          )}
          {isMapVisible && (
            <Link to={`${routes.machineMap.url}?id=${machine.id}`}>
              <Button type="dashed" icon={<MapViewIcon />} />
            </Link>
          )}
        </div>
      </div>

      <div className="content-wrapper">
        <Form
          form={form}
          ref={formRef}
          name="edit_machine_detail"
          initialValues={{
            nickname: machine?.note,
            sector: machine?.sector,
            address: machine?.address,
          }}
          onFinish={submitHandler}
        >
          <TitleValue
            name="nickname"
            type="nickname"
            title={t('machineDetail.nickname')}
            value={machine?.note}
            editable={detailsMode === MachineDetailsBoxMode.Edit}
            onEditChange={(newNickname) => {
              setNickname(newNickname)
            }}
          />
          {machine?.currOrder?.length > 0 && (
            <TitleValue
              name="machineName"
              title={t('machineDetail.currentOrder')}
              value={machine?.currOrder}
            />
          )}

          <TitleValue name="machineName" title={t('machineDetail.machineName')} value={machine?.name} />
          <TitleValue name="productType" title={t('machineDetail.productType')} value={machine?.product} />
          <TitleValue name="serialNumber" title={t('machineDetail.serialNumber')} value={machine?.sn} />

          {!isEval && (
            <>
              <TitleValue
                name="sector"
                title={t('machineDetail.sector')}
                errorMessage={t('messages.requiredField')}
                required={true}
                value={machine?.sector}
                editable={userRole !== UserRoles.Sales && detailsMode === MachineDetailsBoxMode.Edit}
                type="select"
                registryPicklist={registryPicklist?.sectors}
                onEditChange={(sector) => {
                  setMachineDetails((machineDetails) => ({ ...machineDetails, sector }))
                }}
              />
              <TitleValue
                name="address"
                type="address"
                title={t('machineDetail.address')}
                errorMessage={t('messages.requiredField')}
                required={true}
                value={machine?.address}
                editable={userRole !== UserRoles.Sales && detailsMode === MachineDetailsBoxMode.Edit}
                suggestedAddress={suggestedAddress}
                setAddress={setAddress}
                resetAddressValidationErr={resetAddressValidationErr}
                onEditChange={(address) => {
                  setMachineDetails((machineDetails) => ({ ...machineDetails, address }))
                }}
                setSuggestedAddress={setSuggestedAddress}
              />
            </>
          )}

          {detailsMode === MachineDetailsBoxMode.Edit && (
            <div className="buttons-wrapper buttons-wrapper--bottom">
              <Button type="primary" htmlType="submit" className="submit">
                {t('button.submit')}
              </Button>
              {incomplete && userRole === UserRoles.User ? (
                <></>
              ) : (
                <Button
                  onClick={() => {
                    setMachineDetails({
                      address: machine?.address,
                      sector: machine?.sector,
                    })
                    setNickname(machine.note)
                    setDetailsMode(MachineDetailsBoxMode.View)
                    if (formRef.current) {
                      formRef.current.resetFields()
                    }
                    setSuggestedAddress([])
                  }}
                  className="cancel"
                  type="default"
                >
                  {t('button.cancel')}
                </Button>
              )}
            </div>
          )}
        </Form>
      </div>

      {!isEval && (
        <div className="bottom-bar-wrapper">
          <Typography.Paragraph className="reset-date">
            <span>{t('general.since')} </span>
            {machine?.lastReset && dayjs(machine?.lastReset).format(config.getDateTimeFormat())}
          </Typography.Paragraph>
        </div>
      )}
    </div>
  )

  const onClose = () => {
    setVisible(false)
  }

  return (
    <section className={classNames(styles['machine-details-box'], 'machine-details-box')}>
      {detailsContent}
      <div className="machine-details-btn-wrapper">
        <Button
          className="p-0"
          type="text"
          icon={<EditIcon className="hamburger-icon" />}
          onClick={showDrawer}
        />

        <Drawer
          className={classNames(
            styles['machine-details-drawer'],
            themeStyles.theme,
            'machine-details-drawer',
          )}
          title={
            incomplete ? (
              <>
                <WarningIcon />
                <Typography.Title level={5}>{t('general.fillRegistry')}</Typography.Title>
              </>
            ) : null
          }
          placement="right"
          closable={!incomplete ? true : false}
          maskClosable={closable}
          onClose={onClose}
          closeIcon={<CloseIcon />}
          open={visible}
        >
          <div className={classNames(styles['ant-drawer-body'])}>{detailsContent}</div>
        </Drawer>
      </div>
    </section>
  )
}

export default MachineDetailsBox
