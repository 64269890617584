import React from 'react'
import { Card, Typography } from 'antd'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import { useTheme } from 'providers/ThemeProvider'
import { config } from 'core'

import type * as Store from 'types/store'

import { themes } from './themes'
import { ReactComponent as ArrowIcon } from 'assets/icon/arrow-icon.svg'
import { ReactComponent as FavouriteIcon } from 'assets/icon/favourite-icon.svg'
import { ReactComponent as MapViewIcon } from 'assets/icon/map-icon.svg'

import styles from './styles.module.scss'
import { Link } from 'react-router-dom'
import routes from 'config/routes'
import { ProductType } from 'types/enums'
import { fetchUserMachineMenu, setMachineFavorite } from 'store/machines'
import { useAppDispatch } from 'store'
import useSWRMutation from 'swr/mutation'
import { updateFavoriteById } from 'containers/MachineDetail'

export interface MachineCardProps {
  machine: Store.MachineWithRealtime
}

const isValidNumber = (input: unknown): input is number => {
  return typeof input === 'number' && !Number.isNaN(input) && Number.isFinite(input)
}

export const MachineCard: React.FC<MachineCardProps> = ({ machine }) => {
  const { t } = useTranslation()
  const { getStyles } = useTheme()
  const themeStyles = getStyles(themes)
  const machineCardClass = classNames(styles['machine-card'], themeStyles.theme, 'machine-card')

  const { name, note, serial } = machine ?? {}

  const dispatch = useAppDispatch()
  const { trigger: doUpdateFavoriteById } = useSWRMutation(
    config.api.putFavoriteById(machine?.id),
    updateFavoriteById,
  )

  const setFavourite = async (isFavorite: boolean) => {
    try {
      await doUpdateFavoriteById({ isFavorite: !isFavorite })
      dispatch(fetchUserMachineMenu())
      dispatch(setMachineFavorite({ id: machine.id, isFavorite: !isFavorite }))
    } catch (error) {
      console.error(error)
    }
  }

  const getMachineTitle = () => {
    return (
      <Typography.Title
        level={5}
        ellipsis={{
          rows: 1,
          tooltip: <Typography.Text>{note ? note : name}</Typography.Text>,
        }}
      >
        {note ? note : name}
      </Typography.Title>
    )
  }

  const savingsPerc = isValidNumber(machine?.realtimeData?.savingLifetimePerc)
    ? machine.product === ProductType.Sterostab
      ? t('general.online')
      : machine.realtimeData.savingLifetimePerc.toFixed(2) + ' %'
    : ''

  const lastReset = machine?.realtimeData?.lastReset && dayjs(machine.realtimeData.lastReset)

  const isMapVisible =
    (machine?.product === ProductType.Ecostab || machine?.product === ProductType.Sterostab) &&
    machine?.address

  const showSavingLabel =
    machine?.product === ProductType.Ecostab || machine?.product === ProductType.Ecometer

  return (
    <Link to={routes.machineDetail.url.replace(':id', machine?.id.toString())}>
      <Card className={machineCardClass} bordered={false}>
        <div className="top-wrapper">
          <div className="title-wrapper">
            <div className="title-wrapper__left">
              <FavouriteIcon
                className="favourite-icon"
                style={{ color: machine?.isFavourite ? '#FDCA40' : 'transparent', cursor: 'pointer' }}
                onClick={(event) => {
                  setFavourite(machine?.isFavourite)
                  event.stopPropagation()
                  event.preventDefault()
                }}
              />
              {getMachineTitle()}
            </div>

            {isMapVisible && (
              <Link to={`${routes.machineMap.url}?id=${machine.id}`}>
                <MapViewIcon
                  style={{
                    color: '#FDCA40',
                    marginLeft: 'auto',
                  }}
                />
              </Link>
            )}
          </div>
          <div className="name-wrapper">
            <Typography.Text className="name">{name}</Typography.Text>
            {'-'}
            <Typography.Text className="serial">{serial}</Typography.Text>
          </div>
          <Typography.Text className="card-subtitle">{machine?.sector}</Typography.Text>
        </div>

        <div className="content-wrapper">
          {savingsPerc && (
            <Typography.Text className="card-value">
              {`${showSavingLabel ? t('general.savingLifetime') + ': ' : ''}${savingsPerc}`}
            </Typography.Text>
          )}

          <div className="bottom-bar-wrapper">
            <Typography.Paragraph className="card-date">
              {t('general.since')} {lastReset?.isValid() ? lastReset.format(config.getDateTimeFormat()) : '-'}
            </Typography.Paragraph>
            <ArrowIcon />
          </div>
        </div>
      </Card>
    </Link>
  )
}

export default MachineCard
