import React from 'react'
import { Card, Col, Row, Space, Typography } from 'antd'
import classNames from 'classnames'
import type * as Store from 'types/store'
import { getBoxTitle } from 'core/labelHelper'
import { ReactComponent as ExportIcon } from 'assets/icon/export-icon.svg'
import { ReactComponent as ImportIcon } from 'assets/icon/import-icon.svg'

import styles from './styles.module.scss'
import { BoxType } from 'types/enums'
import { useLanguage } from 'providers'
import { useTranslation } from 'react-i18next'
import { isNumeric, toNumber } from 'core'

export interface RealtimeInOutBoxProps {
  box: Store.MachineBox[]
  inValue?: number[] | null | undefined
  outValue?: number[] | null | undefined
  title: string
  unit?: string
}

export const RealtimeInOutBox: React.FC<RealtimeInOutBoxProps> = ({
  box,
  inValue,
  outValue,
  title,
  unit,
}) => {
  const { t } = useTranslation()
  const lang = useLanguage()

  return (
    <Card className={classNames(styles['in-out-box'], 'in-out-box')} bordered={false}>
      <Typography.Title level={5} className="card-subtitle card-subtitle--top">
        {getBoxTitle(title as BoxType, t)}
      </Typography.Title>

      <Row className="main-row">
        {box?.map(
          (m, index) =>
            m?.isInput && (
              <Col key={index} className="left-col">
                <Row>
                  <Col span={16}>
                    <Space align="start">
                      <div className="icon-wrapper">
                        <ImportIcon className="box-import-icon" />
                      </div>
                      <div>
                        <Typography.Text className="box-value">
                          <span className="numeric">
                            {isNumeric(inValue?.[0])
                              ? new Intl.NumberFormat(lang).format(toNumber(inValue[0]))
                              : ''}
                          </span>
                          {unit && <span className="unit">{unit}</span>}
                        </Typography.Text>
                        <Typography.Title level={5} className="card-subtitle">
                          {t('avg.averageAbbr')}
                        </Typography.Title>
                      </div>
                    </Space>
                  </Col>

                  <Col span={8}>
                    {m.valueDetails?.map((vd, index) => (
                      <div key={index} className="value-wrapper">
                        <Typography.Text className="label">{vd.label}</Typography.Text>
                        <Typography.Text className="value">
                          <span>
                            {isNumeric(inValue?.[index + 1])
                              ? new Intl.NumberFormat(lang).format(toNumber(inValue[index + 1]))
                              : ''}
                          </span>

                          {unit && (
                            <span className="unit" style={{ marginLeft: '5px' }}>
                              {unit}
                            </span>
                          )}
                        </Typography.Text>
                      </div>
                    ))}
                  </Col>
                </Row>
              </Col>
            ),
        )}
        {box?.map(
          (m, index) =>
            !m?.isInput && (
              <Col key={index} className="right-col">
                <Row>
                  <Col span={16}>
                    <Space align="start">
                      <div className="icon-wrapper">
                        <ExportIcon className="box-export-icon" />
                      </div>
                      <div>
                        <Typography.Text className="box-value">
                          <span className="numeric">
                            {isNumeric(outValue?.[0])
                              ? new Intl.NumberFormat(lang).format(toNumber(outValue[0]))
                              : ''}
                          </span>
                          {unit && <span className="unit">{unit}</span>}
                        </Typography.Text>
                        <Typography.Title level={5} className="card-subtitle">
                          {t('avg.averageAbbr')}
                        </Typography.Title>
                      </div>
                    </Space>
                  </Col>

                  <Col span={8}>
                    {m.valueDetails.map((vd, index) => (
                      <div key={index} className="value-wrapper">
                        <Typography.Text className="label">{vd.label}</Typography.Text>
                        <Typography.Text className="value">
                          <span>
                            {isNumeric(outValue?.[index + 1])
                              ? new Intl.NumberFormat(lang).format(toNumber(outValue[index + 1]))
                              : ''}
                          </span>

                          {unit && (
                            <span className="unit" style={{ marginLeft: '5px' }}>
                              {unit}
                            </span>
                          )}
                        </Typography.Text>
                      </div>
                    ))}
                  </Col>
                </Row>
              </Col>
            ),
        )}
      </Row>
    </Card>
  )
}

export default RealtimeInOutBox
