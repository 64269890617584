export const STATE_KEY = 'admin'

/* LANGUAGES */
export const FETCH_ADMIN_LANGUAGES = `${STATE_KEY}/FETCH_LANGUAGES`
export const FETCH_ADMIN_LANGUAGEBY_ID = `${STATE_KEY}/FETCH_LANGUAGE_BY_ID`
export const CREATE_ADMIN_LANGUAGE = `${STATE_KEY}/CREATE_LANGUAGE`
export const UPDATE_ADMIN_LANGUAGE = `${STATE_KEY}/UPDATE_LANGUAGE`
export const DELETE_ADMIN_LANGUAGE = `${STATE_KEY}/DELETE_LANGUAGE`

/* LABELS */
export const FETCH_ADMIN_LABELS = `${STATE_KEY}/FETCH_LABELS`
export const FETCH_ADMIN_LABELBY_ID = `${STATE_KEY}/FETCH_LABEL_BY_ID`
export const CREATE_ADMIN_LABEL = `${STATE_KEY}/CREATE_LABEL`
export const UPDATE_ADMIN_LABEL = `${STATE_KEY}/UPDATE_LABEL`
export const DELETE_ADMIN_LABEL = `${STATE_KEY}/DELETE_LABEL`

/* CODES */
export const FETCH_ADMIN_CODES = `${STATE_KEY}/FETCH_CODES`
export const CREATE_ADMIN_CODE = `${STATE_KEY}/CREATE_CODE`

/* USERS */
export const FETCH_ADMIN_USERS = `${STATE_KEY}/FETCH_USERS`
export const FETCH_ADMIN_USERBY_ID = `${STATE_KEY}/FETCH_USER_BY_ID`
export const CREATE_ADMIN_USER = `${STATE_KEY}/CREATE_USER`
export const UPDATE_ADMIN_USER = `${STATE_KEY}/UPDATE_USER`
export const DELETE_ADMIN_USER = `${STATE_KEY}/DELETE_USER`

/* MACHINES */
export const FETCH_ADMIN_MACHINES = `${STATE_KEY}/FETCH_MACHINES`
export const CREATE_ADMIN_MACHINE = `${STATE_KEY}/CREATE_MACHINE`
export const DELETE_ADMIN_MACHINE = `${STATE_KEY}/DELETE_MACHINE`
export const UPDATE_ADMIN_MACHINE = `${STATE_KEY}/UPDATE_MACHINE`
