import { createContext } from 'react'

export interface NewsState {
  newsVisible: boolean
  setNewsVisibility: (hidden: boolean) => void
}

export const NewsContext = createContext<NewsState>({
  newsVisible: false,
  setNewsVisibility: () => null,
})
