import React, { useEffect } from 'react'
import { Col, Row, Spin } from 'antd'
import classNames from 'classnames'

import MachineDetailsBox from 'components/MachineDetailsBox/MachineDetailsBox'
import { TimelineBarChart } from 'components/TimelineBarChart'

import type * as Store from 'types/store'

import styles from './styles.module.scss'
import TimelineTable from 'containers/TimelineTable'
import dayjs from 'dayjs'
import { MachineDetailsBoxMode, TimelineTab } from 'types/enums'
import { SuggestedAddress } from 'containers/MachineDetail'
import { config } from 'core'

interface UpdateMachineDetailsProps {
  address: string
  sector: string
}

export interface MachineDetailProps {
  tab?: string
  machine: Store.MachineDetail
  registryPicklist?: Store.RegistryPicklist
  timelineData: Store.MachineEnergyByPeriod
  timelineLoading?: boolean
  timelineError?: unknown
  doUpdateMachineDetails?: (machineDetails: UpdateMachineDetailsProps) => void
  doUpdateNickname?: (nickname: string, evalID?: string) => void
  doDeleteMachine?: () => void
  detailsMode?: MachineDetailsBoxMode
  setDetailsMode?: (
    detailsMode: MachineDetailsBoxMode | ((detailsMode: MachineDetailsBoxMode) => MachineDetailsBoxMode),
  ) => void
  tableExpandedRowRenderer: (record: Store.EnergyByPeriodDataPoint) => React.ReactNode
  doUpdateMachineDateRange: (start: dayjs.Dayjs, end: dayjs.Dayjs) => void

  setAddress: (value: string) => void
  suggestedAddress: SuggestedAddress[]
  setSuggestedAddress: (SuggestedAddress: SuggestedAddress[]) => void
}

export const TimelineData: React.FC<MachineDetailProps> = ({
  tab,
  machine,
  registryPicklist,
  timelineData,
  timelineLoading,
  timelineError,
  doDeleteMachine,
  doUpdateMachineDetails,
  doUpdateNickname,
  detailsMode,
  setDetailsMode,
  tableExpandedRowRenderer,
  doUpdateMachineDateRange,
  setAddress,
  suggestedAddress,
  setSuggestedAddress,
}) => {
  const timelineClass = classNames(styles['timeline-data'], 'timeline-data')
  const lang = config.getLanguage()

  useEffect(() => {
    // TODO: better error handling
    if (timelineError) {
      console.error(timelineError)
    }
  }, [timelineError])

  return (
    <section className={timelineClass}>
      <Row className="body-wrapper">
        <Col
          className={`timeline-boxes-col`}
          style={{ maxWidth: tab === TimelineTab.Report ? '100%' : 'auto' }}
        >
          {timelineLoading && !timelineData ? (
            <Spin size="large" />
          ) : (
            <>
              <TimelineBarChart
                tab={tab}
                begin={timelineData?.begin}
                end={timelineData?.end}
                data={timelineData?.data}
                machineType={machine?.product}
                doUpdateMachineDateRange={doUpdateMachineDateRange}
                machineId={machine.id}
                lang={lang}
              />
              <TimelineTable
                tab={tab}
                data={timelineData?.data}
                expandedRowRender={tableExpandedRowRenderer}
                machineType={machine.product}
              />
            </>
          )}
        </Col>
        {tab !== TimelineTab.Report && (
          <Col className="details-box-col">
            <MachineDetailsBox
              machine={machine}
              registryPicklist={registryPicklist}
              doUpdateMachineDetails={doUpdateMachineDetails}
              doUpdateNickname={doUpdateNickname}
              doDeleteMachine={doDeleteMachine}
              detailsMode={detailsMode}
              setDetailsMode={setDetailsMode}
              setAddress={setAddress}
              suggestedAddress={suggestedAddress}
              setSuggestedAddress={setSuggestedAddress}
            />
          </Col>
        )}
      </Row>
    </section>
  )
}

export default TimelineData
