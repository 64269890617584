import React, { lazy } from 'react'
import Debug from 'debug'
import { Route, Routes, useLocation } from 'react-router-dom'
import { Layout } from 'antd'
import { useMount } from 'react-use'

import 'core/i18n'

import Seo from 'components/Seo'
import ErrorBoundary from 'components/errors/ErrorBoundary'
import { getCurrentRoute } from 'core/helpers'
import routes from 'config/routes'
import Header from 'containers/Header'
import AuthProvider from 'containers/AuthProvider'
import Sider from 'containers/Sider'

/*** Most used pages; those are not lazy loaded ***/
import AllMachinesPage from 'pages/AllMachinesPage'
import MachineDetailPage from 'pages/MachineDetailPage'
import { RegistrationStep } from 'types/enums'
import MachineMapPage from 'pages/MachineMapPage/MachineMapPage'

/*** Lazy Pages ***/
const Translations = lazy(() => import('pages/Translations'))
const Languages = lazy(() => import('pages/Languages'))
const AdminMachines = lazy(() => import('pages/AdminMachinesPage'))
const AdminUsers = lazy(() => import('pages/AdminUsersPage'))
const AdminCodes = lazy(() => import('pages/AdminCodesPage'))
const UserProfile = lazy(() => import('pages/UserProfilePage'))

const MachinePdf = lazy(() => import('pages/PDFReportMachinePage'))

// Error pages
const NotFoundPage = lazy(() => import('pages/NotFoundPage'))
const ErrorPage = lazy(() => import('pages/ErrorPage'))

// Login pages
const Login = lazy(() => import('pages/LoginPage'))
const Register = lazy(() => import('pages/RegisterPage'))
const EmailConfirm = lazy(() => import('pages/EmailConfirmPage'))
const ForgotPassword = lazy(() => import('pages/ForgotPasswordPage'))
const ResetPassword = lazy(() => import('pages/ResetPasswordPage'))

const debug = Debug('Frontend')

const App: React.FC = () => {
  const location = useLocation()

  const currentRoute = getCurrentRoute(location.pathname)
  const isPrivatePage = currentRoute?.private || false
  const isPublicPage = !isPrivatePage

  useMount(() => {
    debug('App started')
  })

  // All public pages, not logged users
  if (isPublicPage) {
    return (
      <ErrorBoundary>
        <Seo />
        <Layout className="app-layout">
          <Layout.Content className="app-content">
            <Routes>
              {/* Login Pages */}
              <Route path={routes.login.url} element={<Login />} />
              <Route path={routes.register.url} element={<Register step={RegistrationStep.FillForm} />} />
              <Route path={routes.emailConfirm.url} element={<EmailConfirm />} />
              <Route path={routes.forgotPassword.url} element={<ForgotPassword />} />
              <Route path={routes.resetPassword.url} element={<ResetPassword />} />

              <Route path={routes.machineEvalPDFReport.url} element={<MachinePdf />} />
              <Route path={routes.machinePDFReport.url} element={<MachinePdf />} />

              {/* Playgrounds Pages */}
              {/* <Route path={routes.playgroundTheme.url} element={<PlaygroundThemePage />} /> */}

              {/* Errors and Test Pages */}
              <Route path={routes.errorPage.url} element={<ErrorPage />} />
              <Route path={routes.notFoundPage.url} element={<NotFoundPage />} />
              {/* Fallback is error page */}
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </Layout.Content>
        </Layout>
      </ErrorBoundary>
    )
  }

  // All private pages
  return (
    <ErrorBoundary>
      <Seo />
      <AuthProvider>
        <Layout className="app-layout">
          <Sider />
          <Layout>
            <Header />
            <Layout.Content className="app-content">
              <Routes>
                <Route path={routes.allMachines.url} element={<AllMachinesPage />} />
                <Route path={routes.machineDetail.url} element={<MachineDetailPage />} />
                <Route path={routes.machineDetailType.url} element={<MachineDetailPage />} />
                <Route path={routes.machineDetailTab.url} element={<MachineDetailPage />} />
                <Route path={routes.evaluationDetail.url} element={<MachineDetailPage />} />
                <Route path={routes.machineMap.url} element={<MachineMapPage />} />

                {/* Admin and settings page */}
                <Route path={routes.languages.url} element={<Languages />} />
                <Route path={routes.translations.url} element={<Translations />} />

                <Route path={routes.adminMachines.url} element={<AdminMachines />} />
                <Route path={routes.adminUsers.url} element={<AdminUsers />} />
                <Route path={routes.adminCodes.url} element={<AdminCodes />} />
                <Route path={routes.userProfile.url} element={<UserProfile />} />

                {/* Errors and Test Pages */}
                <Route path={routes.errorPage.url} element={<ErrorPage />} />
                <Route path={routes.notFoundPage.url} element={<NotFoundPage />} />
                {/* Fallback is error page */}
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </Layout.Content>
          </Layout>
        </Layout>
      </AuthProvider>
    </ErrorBoundary>
  )
}

export default App
