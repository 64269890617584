import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Line } from 'react-chartjs-2'

import { useTheme } from 'providers'
import { EnergyTimelineTabIndex, MachineMetricsDataType, TimelineTab } from 'types/enums'

import type * as Store from 'types/store'

import { getChartData, getChartOptions } from './chartHelper'
import { ReactComponent as DownloadIcon } from 'assets/icon/download-icon.svg'

import LegendComponent from './Legend'
import Summary, { SummaryProps } from './Summary'

import styles from './styles.module.scss'
import { Button, Tooltip } from 'antd'
import { getAxios } from 'api/apiService'
import { config } from 'core'
import { getApiError, getAuthenticationHeader } from 'api/common'
import { AxiosError } from 'axios'
import { generateDownloadLink } from 'core/helpers'
import classNames from 'classnames'
import { ChartDataset } from 'chart.js'

type Props = {
  summary?: SummaryProps['data']
  machineId: number
  tab?: string
  summaryType?: any
} & (
  | {
      type: MachineMetricsDataType.Voltage
      data: Store.MachineDataByDayResponse<Store.MachineVoltageByDay>
    }
  | {
      type: MachineMetricsDataType.Current
      data: Store.MachineDataByDayResponse<Store.MachineCurrentByDay>
    }
  | {
      type: MachineMetricsDataType.Power
      data: Store.MachineDataByDayResponse<Store.MachinePowerByDay>
    }
  | {
      type: MachineMetricsDataType.PowerFactor
      data: Store.MachineDataByDayResponse<Store.MachinePowerFactorByDay>
    }
  | {
      type: MachineMetricsDataType.Vthd
      data: Store.MachineDataByDayResponse<Store.MachineVthdByDay>
    }
  | {
      type: MachineMetricsDataType.Ithd
      data: Store.MachineDataByDayResponse<Store.MachineIthdByDay>
    }
  | {
      type: MachineMetricsDataType.Energy
      data: Store.MachineDataByDayResponse<Store.MachineEnergyByDay>
    }
)

export const TimelineChartBox: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const { theme } = useTheme()
  const axios = getAxios()
  const containerStyle: React.CSSProperties = { width: '100%', height: '350px' }

  const MVh = t('metricUnit.energymWh')
  const MVAhr = t('metricUnit.energymVAhr')

  const [units, setUnits] = useState<string>(MVh)
  const [summaryData, setSummaryData] = useState({
    initialValue: props.summaryType?.att.min,
    finalValue: props.summaryType?.att.max,
  })
  const [chartData, setChartData] = useState(() => getChartData(theme, props, t))
  const options = useMemo(() => ({ ...getChartOptions(theme) }), [theme])

  const typeText = props.type === 'powerfactor' ? 'powerFactor' : props.type

  const handleEnergyClick = (data: any) => {
    return data.datasets.map((dataset, index) => ({
      ...dataset,
      hidden: index !== 0,
    }))
  }

  useEffect(() => {
    const updatedChartData = getChartData(theme, props, t)
    if (props.type === 'energy') {
      const initialValue = handleEnergyClick(updatedChartData)
      setChartData({
        ...updatedChartData,
        datasets: initialValue,
      })
    } else {
      setChartData(updatedChartData)
    }
  }, [theme, props, t, setChartData])

  const handleLegendClick = useCallback(
    (index: number) =>
      setChartData((chartData) => {
        let newDatasets: ChartDataset<'line', number[]>[]
        if (props.type === MachineMetricsDataType.Energy) {
          newDatasets = chartData.datasets.map((dataset, i) => ({
            ...dataset,
            hidden: i === index ? false : true,
          }))
          setUnits(index === 0 ? MVh : MVAhr)
        } else {
          newDatasets = [...chartData.datasets]
          newDatasets[index] = { ...newDatasets[index], hidden: !newDatasets[index].hidden }
        }

        switch (index) {
          case EnergyTimelineTabIndex.Att_Out:
            setSummaryData({
              initialValue: props.summaryType?.att.min,
              finalValue: props.summaryType?.att.max,
            })
            break
          case EnergyTimelineTabIndex.Reac_Out:
            setSummaryData({
              initialValue: props.summaryType?.reac.min,
              finalValue: props.summaryType?.reac.max,
            })
            break
          case EnergyTimelineTabIndex.Reai_Out:
            setSummaryData({
              initialValue: props.summaryType?.reai.min,
              finalValue: props.summaryType?.reai.max,
            })
            break
          default:
            break
        }

        return { ...chartData, datasets: newDatasets }
      }),
    [setChartData, setUnits],
  )

  const getCSVHandler = async (): Promise<void | ApiError> => {
    try {
      const response = await axios.get<string>(
        config.api.getCSVMachineDetails(
          props.machineId,
          props.type === MachineMetricsDataType.Energy ? `${props.type}-tab` : props.type,
          props.data.day,
        ),
        { ...(await getAuthenticationHeader()), responseType: 'blob' },
      )
      generateDownloadLink(response.data, response.headers['content-disposition'].split('=')[1].split('"')[1])
    } catch (error) {
      throw getApiError(error as AxiosError)
    }
  }

  const energySummary = [
    {
      description: t('timelineCharts.initialValue'),
      value: summaryData?.initialValue,
      unit: units,
    },
    {
      description: t('timelineCharts.finalValue'),
      value: summaryData?.finalValue,
      unit: units,
    },
  ]

  const summaryDataType = props.type !== MachineMetricsDataType.Energy ? props.summary : energySummary

  return (
    <div className={classNames(`chart-${props.type}`, styles['chart-box'])}>
      <div className="summary-wrapper">
        {props.tab === TimelineTab.Report && (
          <div style={{ fontWeight: 'bolder' }}>{t(`machineDetail.${typeText}`)}</div>
        )}
        <Summary
          data={summaryDataType.map(({ description, unit, value }) => ({
            description,
            unit,
            value,
          }))}
        />
        <Tooltip title={t('form.downloadData')}>
          <Button type="dashed" icon={<DownloadIcon />} onClick={getCSVHandler} />
        </Tooltip>
      </div>

      <LegendComponent datasets={chartData.datasets} onLegendClick={handleLegendClick} />
      <div style={containerStyle}>
        <Line data={chartData} options={options} />
      </div>
    </div>
  )
}

export default TimelineChartBox
