import dayjs from 'dayjs'
import type * as Api from 'types/api'
import type * as Store from 'types/store'

export const mapMachineLatestPowerData = ({
  bounds: { begin, end },
  pout,
  poutu,
  poutv,
  poutw,
}: Api.MachineLatestPowerData): Store.MachineLatestPowerData => ({
  bounds: { begin: dayjs(begin).toDate(), end: dayjs(end).toDate() },
  pOutTotal: pout.map(({ t, pOutTotal }) => ({ t: dayjs(t).toDate(), pOutTotal })),
  pOutU: poutu.map(({ t, pOutU }) => ({ t: dayjs(t).toDate(), pOutU })),
  pOutV: poutv.map(({ t, pOutV }) => ({ t: dayjs(t).toDate(), pOutV })),
  pOutW: poutw.map(({ t, pOutW }) => ({ t: dayjs(t).toDate(), pOutW })),
})

export default mapMachineLatestPowerData
