import React from 'react'
import { Row, Col, Typography, Button, Avatar } from 'antd'
import MainLogoDark from 'assets/logo-dark-theme.png'
import MainLogoLight from 'assets/logo-light-theme.png'
import ErrorPict from 'assets/500-error.png'
import classNames from 'classnames'

import styles from './styles.module.scss'
import { themes } from './themes'
import { useTheme } from 'providers'
import { ThemeType } from 'types/enums'
import { useTranslation } from 'react-i18next'

const Error500: React.FC = () => {
  const { t } = useTranslation()
  const { getStyles, theme } = useTheme()
  const themeStyles = getStyles(themes)
  const errorClass = classNames(styles['error-page'], themeStyles.theme)

  const isDark = theme === ThemeType.Dark

  return (
    <Row className={errorClass}>
      <Col className="content-col">
        <div className="top-bar-wrapper">
          <div className="logo-wrapper">
            <a href="https:\\www.irem.it" target="_blank" rel="noreferrer">
              <Avatar
                src={isDark ? MainLogoDark : MainLogoLight}
                className="app-logo full-logo"
                shape="square"
              />
            </a>
          </div>
        </div>

        <div className="content">
          <div className="title-wrapper">
            <Typography.Title level={1}>Si è verificato un problema</Typography.Title>
            <Typography.Paragraph className="subtitle">
              <Typography.Text>Ci scusiamo per l’incoveniente, si prega di provare a </Typography.Text>
              <Typography.Text strong>tornare in homepage</Typography.Text>.
            </Typography.Paragraph>
          </div>

          <Button href="/" size="large" type="primary" className="form">
            {t('user.backToLogin')}
          </Button>
        </div>
        <div></div>
      </Col>

      <Col className="media-col">
        <div className="image-wrapper">
          <img src={ErrorPict} alt="Somethign went wrong" />
        </div>
      </Col>
    </Row>
  )
}

Error500.displayName = 'Error500'

export default Error500
