import type * as Api from 'types/api'
import type * as Store from 'types/store'

export const mapMachineCurrentByDay = ({
  ir,
  ir_min,
  ir_max,
  is,
  is_min,
  is_max,
  it,
  it_min,
  it_max,
}: Api.MachineCurrentByDay): Store.MachineCurrentByDay => ({
  ir: ir,
  irMin: ir_min,
  irMax: ir_max,
  is: is,
  isMin: is_min,
  isMax: is_max,
  it: it,
  itMin: it_min,
  itMax: it_max,
})

export default mapMachineCurrentByDay
