import type * as Api from 'types/api'
import type * as Store from 'types/store'

export const mapMachineEnergyByDay = ({
  En_ATT_TOT_OUT,
  En_REAC_TOT_OUT,
  En_REAI_TOT_OUT,
}: Api.MachineEnergyByDay): Store.MachineEnergyByDay => ({
  enAttTotOut: En_ATT_TOT_OUT,
  enReacTotOut: En_REAC_TOT_OUT,
  enReaiTotOut: En_REAI_TOT_OUT,
})

export default mapMachineEnergyByDay
