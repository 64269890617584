import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import type * as Store from 'types/store'
import { STATE_KEY } from './constants'
import { fetchUserMachineListThunk, fetchUserMachineMenuThunk } from './thunks'

export const initialState: Store.MachinesState = {
  machinesList: [],
  loadingMachineList: false,
  machinesMenu: [],
  loadingMachineMenu: false,
  loadingError: null,
}

export const machinesSlice = createSlice({
  name: STATE_KEY,
  initialState,
  reducers: {
    // NOTE: creates both action and reducer in one go
    setMachineFavorite: (state, action: PayloadAction<{ id: number; isFavorite: boolean }>) => {
      const machine = state.machinesList.find((m) => m.id === action.payload.id)
      if (machine) {
        machine.isFavourite = action.payload.isFavorite
      }
    },
  },
  // Handle async thunk states in the extraReducers
  extraReducers: (builder) => {
    // FETCH USER MACHINES LIST
    builder.addCase(fetchUserMachineListThunk.pending, (state) => {
      state.loadingMachineList = true
    })
    builder.addCase(fetchUserMachineListThunk.rejected, (state, action) => {
      state.loadingMachineList = false
      state.loadingError = action.error
    })
    builder.addCase(fetchUserMachineListThunk.fulfilled, (state, action) => {
      state.loadingMachineList = false
      state.machinesList = action.payload
    })

    // FETCH USER MACHINES MENU
    builder.addCase(fetchUserMachineMenuThunk.pending, (state) => {
      state.loadingMachineMenu = true
    })
    builder.addCase(fetchUserMachineMenuThunk.rejected, (state, action) => {
      state.loadingMachineMenu = false
      state.loadingError = action.error
    })
    builder.addCase(fetchUserMachineMenuThunk.fulfilled, (state, action) => {
      state.loadingMachineMenu = false
      state.machinesMenu = action.payload
    })
  },
})
