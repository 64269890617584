import React, { useState } from 'react'

import { Avatar, Button, Layout, Space } from 'antd'

import MachineMenu from 'containers/MachineMenu'
import { useTheme } from 'providers/ThemeProvider'
import { ThemeType } from 'themes/types'

import MainLogoDark from 'assets/logo-dark-theme.png'
import MainLogoLight from 'assets/logo-light-theme.png'
import MiniLogo from 'assets/logo-mini.png'
import NewsBlock from 'containers/NewsBlock'
import { useInView } from 'react-intersection-observer'

import { ReactComponent as CollapseIcon } from 'assets/icon/collapse-icon.svg'

import styles from './styles.module.scss'

export const Sider: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false)
  const { theme } = useTheme()

  const isDark = theme === ThemeType.Dark

  const { ref, inView } = useInView({
    root: null,
    threshold: 1,
  })

  const closeBtn = (
    <Button
      type="text"
      className="sider-trigger p-0"
      icon={
        <CollapseIcon
          style={{ fill: isDark ? 'white' : 'black', transform: collapsed ? 'scaleX(-1)' : null }}
        />
      }
      onClick={() => {
        setCollapsed((collapsed) => !collapsed)
      }}
    />
  )

  return (
    <Layout.Sider
      className={styles.sider}
      width={276}
      collapsible
      collapsed={collapsed}
      collapsedWidth={98}
      trigger={null}
    >
      <Space direction="vertical">
        <div className="logo-wrapper">
          <Avatar src={MiniLogo} className="app-logo mini-logo" shape="square" />

          <a href="https:\\www.irem.it" target="_blank" rel="noreferrer">
            <img src={isDark ? MainLogoDark : MainLogoLight} className="app-logo full-logo" />
          </a>
          {closeBtn}
        </div>

        <MachineMenu mobile={false} />
      </Space>
      <Space className="news-wrapper" style={{ overflow: 'hidden', gap: 0 }}>
        <NewsBlock ref={ref} hidden={!inView} />
      </Space>
    </Layout.Sider>
  )
}

export default Sider
