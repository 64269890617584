// NOTE: uses the "reselect" library, internally
import { createSelector } from '@reduxjs/toolkit'

import { STATE_KEY } from './constants'

import type { RootState } from '../'

const selectSelf = (state: RootState) => state[STATE_KEY]

// Memoized selectors
export const selectMachinesList = createSelector(selectSelf, (machinesState) => machinesState.machinesList)
export const selectMachinesMenu = createSelector(selectSelf, (machinesState) => machinesState.machinesMenu)
export const selectLoadingMachinesList = createSelector(
  selectSelf,
  (machinesState) => machinesState.loadingMachineList,
)
export const selectLoadingMachinesMenu = createSelector(
  selectSelf,
  (machinesState) => machinesState.loadingMachineMenu,
)

export const selectMachinesLoadingError = createSelector(
  selectSelf,
  (machinesState) => machinesState.loadingError,
)
