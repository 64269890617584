import React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, Link } from 'react-router-dom'
import { ReactComponent as ArrowIcon } from 'assets/icon/arrow-icon.svg'
import { Typography, Button } from 'antd'
import classNames from 'classnames'

import styles from './styles.module.scss'
import { MachineDataType } from 'types/enums'

interface Props {
  title?: any
  subtitle?: any
  backRoute?: Route
  classname?: string
  extra?: any
  machineId?: number
}
const PageHeader: React.FC<Props> = ({
  title = null,
  subtitle = null,
  backRoute = null,
  classname = '',
  extra = null,
  machineId,
}) => {
  const { t } = useTranslation()
  const pageHeaderClass = classNames(styles['page-header'], 'page-header', classname)

  return (
    <section className={pageHeaderClass}>
      {backRoute && backRoute.key === 'allMachines' && (
        <Link to={generatePath(backRoute.url)} className="back-btn-link">
          <Button type="text" className="back-btn" icon={<ArrowIcon />}>
            {t(backRoute.headTitle)}
          </Button>
        </Link>
      )}
      {backRoute && backRoute.key === 'machineDetailType' && (
        <Link
          to={generatePath(
            backRoute.url.replace(':id', machineId.toString()).replace(':type', MachineDataType.Evaluations),
          )}
        >
          <Button type="text" className="back-btn" icon={<ArrowIcon />}>
            {t(backRoute.headTitle)}
          </Button>
        </Link>
      )}

      <div className="top">
        <div className="top-title">
          <div className="title-wrapper">{title}</div>
          {subtitle && (
            <Typography.Title level={5} className="caption">
              {subtitle}
            </Typography.Title>
          )}
        </div>
        <div className="extra">{extra}</div>
      </div>
    </section>
  )
}

export default PageHeader
