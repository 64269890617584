import React from 'react'
import { Radio, Spin, Tooltip } from 'antd'

import { MachineCard } from 'components/MachineCard'
import { ReactComponent as ListViewIcon } from 'assets/icon/list-icon.svg'
import { ReactComponent as MapViewIcon } from 'assets/icon/map-icon.svg'

import type * as Store from 'types/store'
import { ListMapView, ProductType } from 'types/enums'
import { useTranslation } from 'react-i18next'

interface Props {
  machineLoading?: boolean | undefined
  realtimeLoading?: boolean | undefined
  machines?: undefined | Store.MachineWithRealtime[]
  machineError?: unknown
  realtimeError?: unknown
  filterProduct?: undefined | ProductType
  setDataType?: (type: ListMapView) => void
}

export const MachineList: React.FC<Props> = ({
  machines = [],
  machineLoading,
  // realtimeLoading,
  machineError,
  realtimeError,
  filterProduct,
  setDataType,
}) => {
  const { t } = useTranslation()
  if (machineError || realtimeError) {
    // TODO: better error handling
  }

  if (machineLoading) {
    return (
      <div className="machine-list">
        <Spin size="large" />
      </div>
    )
  }

  const machinesFiltered = (
    filterProduct ? (machines ?? []).filter((m) => m.product === filterProduct) : machines ?? []
  ) as Store.MachineWithRealtime[]

  return (
    <div className="machine-list-wrapper">
      <div className="list-view">
        <Radio.Group
          className="view-type-switch"
          defaultValue={ListMapView.ListView}
          buttonStyle="solid"
          onChange={(e) => {
            setDataType(e.target.value)
          }}
        >
          <Tooltip placement="top" title={t('general.listView')}>
            <Radio.Button className="radio-list" value={ListMapView.ListView}>
              <ListViewIcon />
            </Radio.Button>
          </Tooltip>
          <Tooltip placement="top" className="radio-map" title={t('general.mapView')}>
            <Radio.Button value={ListMapView.MapView}>
              <MapViewIcon />
            </Radio.Button>
          </Tooltip>
        </Radio.Group>
      </div>
      <div className="machine-list">
        {machinesFiltered.map((m) => (
          <MachineCard key={m.id} machine={m} />
        ))}
      </div>
    </div>
  )
}

export default MachineList
