import * as Api from 'types/api'
import * as Store from 'types/store'

export const mapMachineRealtimePowerData = ({
  PotenzaAttivaOutFaseU,
  PotenzaAttivaOutFaseV,
  PotenzaAttivaOutFaseW,
  PotenzaAttivaOutAvg,
  PotenzaReattivaOutFaseU,
  PotenzaReattivaOutFaseV,
  PotenzaReattivaOutFaseW,
  PotenzaReattivaOutAvg,
  PotenzaApparenteOutFaseU,
  PotenzaApparenteOutFaseV,
  PotenzaApparenteOutFaseW,
  PotenzaApparenteOutAvg,
}: Api.RealtimePowerData): Store.RealtimePowerData => ({
  powerActiveOutPhaseU: +PotenzaAttivaOutFaseU,
  powerActiveOutPhaseV: +PotenzaAttivaOutFaseV,
  powerActiveOutPhaseW: +PotenzaAttivaOutFaseW,
  powerActiveOutAvg: +PotenzaAttivaOutAvg,
  powerReactiveOutPhaseU: +PotenzaReattivaOutFaseU,
  powerReactiveOutPhaseV: +PotenzaReattivaOutFaseV,
  powerReactiveOutPhaseW: +PotenzaReattivaOutFaseW,
  powerReactiveOutAvg: +PotenzaReattivaOutAvg,
  powerApparentOutPhaseU: +PotenzaApparenteOutFaseU,
  powerApparentOutPhaseV: +PotenzaApparenteOutFaseV,
  powerApparentOutPhaseW: +PotenzaApparenteOutFaseW,
  powerApparentOutAvg: +PotenzaApparenteOutAvg,
})

export default mapMachineRealtimePowerData
