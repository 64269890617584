import { EMPTY_DATE } from 'core/config'
import dayjs from 'dayjs'

import * as Api from 'types/api'
import * as Store from 'types/store'

import { mapMachineRealtimeSavingsData } from './map-realtime-savings'

export const mapAllMachinesRealTimeSavings = ({
  ts,
  metrics,
}: Api.AllMachineRealTimeSavingsResponse): Store.AllMachineRealTimeSavingsResponse => {
  return {
    metrics: Object.fromEntries(
      Object.entries(metrics).map(([key, { LastReset, ...value }]) => {
        const mappedValue = mapMachineRealtimeSavingsData(value) as Store.RealtimeSavingsData & {
          lastReset: Date | null
        }
        let lastResetDate: Date | null = null

        if (LastReset && LastReset !== EMPTY_DATE) {
          const d = dayjs(LastReset)

          if (d.isValid()) {
            lastResetDate = d.toDate()
          }
        }

        mappedValue.lastReset = lastResetDate

        return [key, mappedValue]
      }),
    ),
    ts: dayjs(ts).toDate(),
  }
}

export default mapAllMachinesRealTimeSavings
