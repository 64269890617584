import React from 'react'
import { Button, Form, Input, Modal, Space } from 'antd'
import { ReactComponent as CloseIcon } from 'assets/icon/close-icon.svg'

import { useTranslation } from 'react-i18next'

interface NewMachineModalProps {
  open: boolean
  handleCancel: () => void
  handleConfirm: (webcode: string) => void
}

export const NewMachineModal: React.FC<NewMachineModalProps> = ({ open, handleCancel, handleConfirm }) => {
  const { t } = useTranslation()

  return (
    <Modal
      centered
      open={open}
      maskClosable={false}
      footer={null}
      onCancel={handleCancel}
      okText={t('general.confirm')}
      cancelText={t('general.cancel')}
      title={t('machine.addTitle')}
      closeIcon={<CloseIcon />}
    >
      <Form
        layout="vertical"
        name="newMachine"
        autoCapitalize="off"
        key={new Date().getTime()}
        onFinish={({ webcode }) => handleConfirm(webcode)}
      >
        <Form.Item
          name="webcode"
          label={t('general.newMachine')}
          rules={[{ required: true, message: t('messages.requiredField') }]}
        >
          <Input placeholder={t('form.iremWebCode')} />
        </Form.Item>

        <Space className="mt-24 modal-footer">
          <Button type="primary" htmlType="submit">
            {t('general.confirm')}
          </Button>
          <Button onClick={handleCancel}>{t('general.cancel')}</Button>
        </Space>
      </Form>
    </Modal>
  )
}

export default NewMachineModal
