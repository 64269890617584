import React from 'react'
import { useFetchRealtimeData } from 'hooks'
import { MachineRealtimeDataType } from 'types/enums'
import type * as Store from 'types/store'

import DashboardCmp from 'components/boxes/DashboardBox'

interface DashboardProps {
  machine: Store.MachineDetail
}

export const DashboardBox: React.FC<DashboardProps> = ({ machine }) => {
  const {
    data: realtimeData,
    isLoading: realtimeIsLoading,
    error: realtimeError,
  } = useFetchRealtimeData(machine.id, MachineRealtimeDataType.All)

  return (
    <DashboardCmp
      machine={machine}
      realtimeData={realtimeData}
      realtimeIsLoading={realtimeIsLoading}
      realtimeError={realtimeError}
    />
  )
}

export default DashboardBox
