import { config } from 'core'
import { useLanguage } from 'providers'
import { useMemo } from 'react'

export const useNumberFormat = () => {
  const lang = useLanguage()

  return useMemo<(value: number) => string>(() => {
    const numberFormat = new Intl.NumberFormat(lang, {
      minimumFractionDigits: config.minimumFractionDigits,
      maximumFractionDigits: config.maximumFractionDigits,
    })

    return numberFormat.format.bind(numberFormat)
  }, [lang])
}

export default useNumberFormat
