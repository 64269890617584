import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Tabs } from 'antd'
import type { TabsProps } from 'antd/es/tabs'

import { TimelineTab } from 'types/enums'

interface Props {
  onTabChange: (tabKey: TimelineTab) => void
  activeTab: TimelineTab
}

export const TimelineTabs: React.FC<Props> = ({ activeTab, onTabChange }) => {
  const { t } = useTranslation()

  const items: TabsProps['items'] = useMemo(
    () => [
      {
        key: TimelineTab.Voltage,
        id: TimelineTab.Voltage,
        label: t('machineDetail.voltage'),
      },
      {
        key: TimelineTab.Current,
        id: TimelineTab.Current,
        label: t('machineDetail.current'),
      },
      {
        key: TimelineTab.Power,
        id: TimelineTab.Power,
        label: t('machineDetail.power'),
      },
      {
        key: TimelineTab.PowerFactor,
        id: TimelineTab.PowerFactor,
        label: t('machineDetail.powerFactor'),
      },
      {
        key: TimelineTab.VTHD,
        id: TimelineTab.VTHD,
        label: t('machineDetail.vthd'),
      },
      {
        key: TimelineTab.ITHD,
        id: TimelineTab.ITHD,
        label: t('machineDetail.ithd'),
      },
      {
        key: TimelineTab.Energy,
        id: TimelineTab.Energy,
        label: t('machineDetail.energy'),
      },
    ],
    [t],
  )

  return (
    <div className="timeline-tabs-wrapper">
      <Tabs
        className="timeline-tabs"
        size="small"
        activeKey={activeTab}
        items={items}
        onChange={onTabChange}
        renderTabBar={(props, TabNavList) => <TabNavList {...props} mobile={false} />}
      />
    </div>
  )
}

export default TimelineTabs
