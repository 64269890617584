import dayjs from 'dayjs'

import { MachineRealtimeDataType } from 'types/enums'

import type * as Api from 'types/api'
import type * as Store from 'types/store'

import { mapMachineRealtimeCurrentData } from './map-realtime-current'
import { mapMachineRealtimeEnergyData } from './map-realtime-energy'
import { mapMachineRealtimeIthdData } from './map-realtime-ithd'
import { mapMachineRealtimePowerData } from './map-realtime-power'
import { mapMachineRealtimePowerFactorData } from './map-realtime-power-factor'
import { mapMachineRealtimeSavingsData } from './map-realtime-savings'
import { mapMachineRealtimeVoltageData } from './map-realtime-voltage'
import { mapMachineRealtimeVthdData } from './map-realtime-vthd'
import { mapDate } from './map-date'

export const mapMachineRealtimeResponse = <
  T extends Api.MachineRealtimeResponseTypes,
  R extends Store.MachineRealtimeResponseTypes,
>(
  { lastReset, metrics: _metrics, ts }: Api.MachineRealTimeResponse<T>,
  type: MachineRealtimeDataType,
): Store.MachineRealTimeResponse<R> => {
  let metrics: R

  switch (type) {
    case MachineRealtimeDataType.Current:
      metrics = mapMachineRealtimeCurrentData(_metrics as Api.RealtimeCurrentData) as R
      break
    case MachineRealtimeDataType.Savings:
      metrics = mapMachineRealtimeSavingsData(_metrics as Api.RealtimeSavingsData) as R
      break
    case MachineRealtimeDataType.Energy:
      metrics = mapMachineRealtimeEnergyData(_metrics as Api.RealtimeEnergyData) as R
      break
    case MachineRealtimeDataType.Voltage:
      metrics = mapMachineRealtimeVoltageData(_metrics as Api.RealtimeVoltageData) as R
      break
    case MachineRealtimeDataType.Power:
      metrics = mapMachineRealtimePowerData(_metrics as Api.RealtimePowerData) as R
      break
    case MachineRealtimeDataType.PowerFactor:
      metrics = mapMachineRealtimePowerFactorData(_metrics as Api.RealtimePowerFactorData) as R
      break
    case MachineRealtimeDataType.Ithd:
      metrics = mapMachineRealtimeIthdData(_metrics as Api.RealtimeIthdData) as R
      break
    case MachineRealtimeDataType.Vthd:
      metrics = mapMachineRealtimeVthdData(_metrics as Api.RealtimeVthdData) as R
      break
    default:
      throw new Error(`Invalid machine realtime data type: "${type}"!`)
  }

  return {
    lastReset: mapDate(lastReset),
    metrics,
    ts: dayjs(ts).toDate(),
  }
}

export default mapMachineRealtimeResponse
