import { getAxios } from './apiService'
import type * as Api from 'types/api'
import { config } from 'core'
import { getApiError, getAuthenticationHeader } from './common'
import { AxiosError, AxiosResponse } from 'axios'

const axios = getAxios()

export async function getAdminLanguages(): Promise<Api.AdminLanguage[] | ApiError> {
  try {
    const response = await axios.get<Api.AdminLanguage[]>(
      config.api.getAdminLanguages(),
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function getAdminLanguageById(languageId: number): Promise<Api.AdminLanguage | ApiError> {
  try {
    const response = await axios.get<Api.AdminLanguage>(
      config.api.getAdminLanguageById(languageId),
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function postAdminLanguage(
  languagePayload: Api.AdminLanguagePayload,
): Promise<Api.AdminLanguage | ApiError> {
  try {
    const response = await axios.post<
      Api.AdminLanguage,
      AxiosResponse<Api.AdminLanguage>,
      Api.AdminLanguagePayload
    >(config.api.postAdminLanguage(), languagePayload, await getAuthenticationHeader())
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function putAdminLanguageById(
  languageId: number,
  langPayload: Api.AdminLanguagePayload,
): Promise<Api.AdminLanguage | ApiError> {
  try {
    const response = await axios.put<
      Api.AdminLanguage,
      AxiosResponse<Api.AdminLanguage, Api.AdminLanguagePayload>,
      Api.AdminLanguagePayload
    >(config.api.putAdminLanguageById(languageId), langPayload, await getAuthenticationHeader())
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function deleteAdminLanguageById(languageId: number): Promise<Api.AdminLanguage | ApiError> {
  try {
    const response = await axios.delete<
      Api.AdminLanguage,
      AxiosResponse<Api.AdminLanguage, Api.AdminLanguagePayload>,
      Api.LabelPayload
    >(config.api.deleteAdminLanguageById(languageId), await getAuthenticationHeader())
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}
