// TODO: check if it works and add tests
export const isNumeric = (input: unknown): input is number | bigint | `${number}` => {
  switch (typeof input) {
    case 'bigint':
      return BigInt(Number.MIN_SAFE_INTEGER) < input && input < BigInt(Number.MAX_SAFE_INTEGER)
    case 'string': {
      if (input.trim().length < 1) {
        return false
      }
      // NOTE: intentional fall-through
    }
    // eslint-disable-next-line no-fallthrough
    case 'number':
      return !isNaN(input as number) && isFinite(input as number)
    default:
      return false
  }
}

export default isNumeric
