import type * as Store from 'types/store'

export function getDataDashboard(realtimeData: Store.MachineRealTimeAllResponse) {
  return {
    current: {
      in: [
        realtimeData?.currents.currentInAvg,
        realtimeData?.currents.currentInPhaseR,
        realtimeData?.currents.currentInPhaseS,
        realtimeData?.currents.currentInPhaseT,
      ],
      out: [
        realtimeData?.currents.currentOutAvg,
        realtimeData?.currents.currentOutPhaseU,
        realtimeData?.currents.currentOutPhaseV,
        realtimeData?.currents.currentOutPhaseW,
      ],
    },
    voltage: {
      inVConcatenata: [
        realtimeData?.voltages.tensionInConcatenatedAvg,
        realtimeData?.voltages.tensionInConcatenatedPhaseRs,
        realtimeData?.voltages.tensionInConcatenatedFaseSt,
        realtimeData?.voltages.tensionInConcatenatedFaseTr,
      ],
      outVConcatenata: [
        realtimeData?.voltages.tensionOutConcatenatedAvg,
        realtimeData?.voltages.tensionOutConcatenatedPhaseUv,
        realtimeData?.voltages.tensionOutConcatenatedPhaseVw,
        realtimeData?.voltages.tensionOutConcatenatedPhaseWu,
      ],
      inVFase: [
        realtimeData?.voltages.tensionInAvg,
        realtimeData?.voltages.tensionInPhaseR,
        realtimeData?.voltages.tensionInPhaseS,
        realtimeData?.voltages.tensionInPhaseT,
      ],
      outVFase: [
        realtimeData?.voltages.tensionOutAvg,
        realtimeData?.voltages.tensionOutPhaseU,
        realtimeData?.voltages.tensionOutPhaseV,
        realtimeData?.voltages.tensionOutPhaseW,
      ],
    },
    ithd: {
      in: [
        realtimeData?.ithd.thdCurrentInAvg,
        realtimeData?.ithd.thdCurrentInPhaseR,
        realtimeData?.ithd.thdCurrentInPhaseS,
        realtimeData?.ithd.thdCurrentInPhaseT,
      ],
      out: [
        realtimeData?.ithd.thdCurrentOutAvg,
        realtimeData?.ithd.thdCurrentOutPhaseU,
        realtimeData?.ithd.thdCurrentOutPhaseV,
        realtimeData?.ithd.thdCurrentOutPhaseW,
      ],
    },
    vthd: {
      in: [
        realtimeData?.vthd.thdTensionInAvg,
        realtimeData?.vthd.thdTensionInPhaseR,
        realtimeData?.vthd.thdTensionInPhaseS,
        realtimeData?.vthd.thdTensionInPhaseT,
      ],
      out: [
        realtimeData?.vthd.thdTensionOutAvg,
        realtimeData?.vthd.thdTensionOutPhaseU,
        realtimeData?.vthd.thdTensionOutPhaseV,
        realtimeData?.vthd.thdTensionOutPhaseW,
      ],
    },
    powerFactor: {
      in: [
        realtimeData?.powerFactor.powerFactorInputEquivalent,
        realtimeData?.powerFactor.powerFactorInputPhaseR,
        realtimeData?.powerFactor.powerFactorInputPhaseS,
        realtimeData?.powerFactor.powerFactorInputPhaseT,
      ],
      out: [
        realtimeData?.powerFactor.powerFactorOutputEquivalent,
        realtimeData?.powerFactor.powerFactorOutputPhaseU,
        realtimeData?.powerFactor.powerFactorOutputPhaseV,
        realtimeData?.powerFactor.powerFactorOutputPhaseW,
      ],
    },
    power: {
      potenzaAttiva: [
        realtimeData?.power.powerActiveOutAvg,
        realtimeData?.power.powerActiveOutPhaseU,
        realtimeData?.power.powerActiveOutPhaseV,
        realtimeData?.power.powerActiveOutPhaseW,
      ],

      potenzaReattiva: [
        realtimeData?.power.powerReactiveOutAvg,
        realtimeData?.power.powerReactiveOutPhaseU,
        realtimeData?.power.powerReactiveOutPhaseV,
        realtimeData?.power.powerReactiveOutPhaseW,
      ],

      potenzaApparente: [
        realtimeData?.power.powerApparentOutAvg,
        realtimeData?.power.powerApparentOutPhaseU,
        realtimeData?.power.powerApparentOutPhaseV,
        realtimeData?.power.powerApparentOutPhaseW,
      ],
    },
  }
}
