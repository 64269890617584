import React from 'react'
import { AutoComplete, Form, Input, Select, Tooltip, Typography } from 'antd'
import { SuggestedAddress } from 'containers/MachineDetail'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'

export interface TitleValueProps {
  title: string
  value: string
  editable?: boolean
  onEditChange?: (newValue: string) => void
  type?: string
  registryPicklist?: string[]
  name: string
  required?: boolean
  errorMessage?: string
  suggestedAddress?: SuggestedAddress[]
  setAddress?: (address: string) => void
  resetAddressValidationErr?: () => void
  setSuggestedAddress?: (SuggestedAddress: SuggestedAddress[]) => void
}

export const TitleValue: React.FC<TitleValueProps> = ({
  title,
  value,
  editable,
  onEditChange,
  type,
  registryPicklist,
  required,
  errorMessage,
  name,
  suggestedAddress,
  setAddress,
  setSuggestedAddress,
  resetAddressValidationErr,
}) => {
  const { Option } = Select

  const { t } = useTranslation()

  const validate = async (rule: any, value: string) => {
    const isValueSuggested = suggestedAddress.some((address) => address.value === value)
    if (value && !isValueSuggested) {
      return Promise.reject(t('messages.invalidAddress'))
    }
  }

  if (type === 'select') {
    return (
      <div className="title-value-wrapper">
        <Typography.Title level={5} className="title">
          {title}
        </Typography.Title>

        {editable ? (
          <Form.Item name={name} rules={required ? [{ required, message: errorMessage }] : []}>
            <Select showSearch onChange={onEditChange} style={{ width: '100%' }}>
              {registryPicklist.map((rp, index) => (
                <Option key={index} value={rp}>
                  {rp}
                </Option>
              ))}
            </Select>
          </Form.Item>
        ) : (
          <Typography.Text className="value">{value}</Typography.Text>
        )}
      </div>
    )
  } else {
    return (
      <div className="title-value-wrapper">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography.Title level={5} className="title">
            {title}
          </Typography.Title>
          {title === t('machineDetail.address') && (
            <Tooltip
              title={<ReactMarkdown linkTarget={'_blank'}>{t('general.verifyAddress')}</ReactMarkdown>}
            >
              <QuestionCircleOutlined style={{ marginLeft: 4 }} />
            </Tooltip>
          )}
        </div>
        {editable ? (
          <Form.Item
            name={name}
            rules={[
              { required: type === 'nickname' ? false : true, message: t('messages.requiredField') },
              { validator: type === 'address' && validate },
            ]}
            validateTrigger={'onBlur'}
          >
            {type === 'address' ? (
              <AutoComplete
                style={{ width: '100%' }}
                options={suggestedAddress}
                onSelect={(value: string) => {
                  const selectedAdd = suggestedAddress.find((v) => v.value === value)
                  if (selectedAdd.place_formatted) {
                    onEditChange(value)
                  }
                  resetAddressValidationErr()
                }}
                allowClear={true}
                onSearch={(value: string) => {
                  if (value === '') {
                    onEditChange(value)
                    setSuggestedAddress([])
                  }
                  setAddress(value)
                }}
              />
            ) : (
              <Input
                onChange={(e) => {
                  onEditChange(e.target.value)
                }}
              />
            )}
          </Form.Item>
        ) : (
          <Typography.Text className="value">{value}</Typography.Text>
        )}
      </div>
    )
  }
}

export default TitleValue
