import { getAxios } from './apiService'
import type * as Api from 'types/api'

import { config } from 'core'
import { getApiError, getAuthenticationHeader } from './common'
import { AxiosError, AxiosResponse } from 'axios'

const axios = getAxios()

export async function getAdminMachines({ q, o, sz, n }: Api.PaginationParams = {}): Promise<
  Api.PaginatedResult<Api.AdminMachine> | ApiError
> {
  try {
    const { headers } = await getAuthenticationHeader()
    const response = await axios.get<Api.PaginatedResult<Api.AdminMachine>>(config.api.getAdminMachines(), {
      params: { q, o, sz: sz?.toString(), n: n?.toString() },
      headers,
    })
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function postAdminMachine(
  adminMachinePayload: Api.AdminMachinePayload,
): Promise<Api.AdminMachine | ApiError> {
  try {
    const response = await axios.post<
      Api.AdminMachine,
      AxiosResponse<Api.AdminMachine>,
      Api.AdminMachinePayload
    >(config.api.postAdminMachine(), adminMachinePayload, await getAuthenticationHeader())
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function deleteAdminMachineById(machineId: number): Promise<Api.AdminMachine | ApiError> {
  try {
    const response = await axios.delete<
      Api.AdminMachine,
      AxiosResponse<Api.AdminMachine, Api.AdminMachinePayload>,
      Api.AdminMachine
    >(config.api.deleteMachineForUser(machineId), await getAuthenticationHeader())
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function updateAdminMachineById(
  machineId: number,
  adminMachinePayload: Api.AdminMachinePayload,
): Promise<Api.AdminMachine | ApiError> {
  try {
    const response = await axios.put<
      Api.AdminMachine,
      AxiosResponse<Api.AdminMachine>,
      Api.AdminMachinePayload
    >(config.api.putAdminMachineById(machineId), adminMachinePayload, await getAuthenticationHeader())
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}
