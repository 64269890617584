import { useContext } from 'react'
import { LanguageContext } from './LanguageContext'

export { default, LanguageProvider } from './LanguageProvider'

export const useLanguage = () => {
  return useContext(LanguageContext).language
}

export const useChangeLanguage = () => {
  return useContext(LanguageContext).changeLanguage
}
