import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isToday from 'dayjs/plugin/isToday'
import relativeTime from 'dayjs/plugin/relativeTime'
import duration from 'dayjs/plugin/duration'

if (typeof dayjs.utc !== 'function') {
  dayjs.extend(utc)
}

if (typeof dayjs().isToday !== 'function') {
  dayjs.extend(isToday)
}

if (typeof dayjs().from !== 'function' || typeof dayjs().fromNow !== 'function') {
  dayjs.extend(relativeTime)
}

if (typeof dayjs.duration !== 'function') {
  dayjs.extend(duration)
}
