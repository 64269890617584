import React, { useState, useCallback } from 'react'
import { Button, Radio, Tooltip, Typography, notification } from 'antd'
import { useMount } from 'react-use'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { getUserClaims } from 'core/localStore'
import { ListMapView, ProductType, UserRoles } from 'types/enums'
import { useAppDispatch } from 'store'
import { fetchUserMachineList } from 'store/machines'
import AllMachinesTabs from 'containers/AllMachinesTabs'
import PageHeader from 'components/PageHeader/PageHeader'

import { themes } from './themes'

import { ReactComponent as AddIcon } from 'assets/icon/add-icon.svg'
import styles from './styles.module.scss'
import { NewMachineModal } from 'components/NewMachine'
import { config } from 'core'
import { getAxios } from 'api/apiService'
import { getAuthenticationHeader } from 'api/common'
import { MachineMap } from 'containers/MachineMap'
import getSwrFetcher from 'api/swr-fetcher'
import * as Api from 'types/api'
import * as Store from 'types/store'
import { mapMachineGeocode } from 'services'
import useSWR from 'swr'
import { useNavigate } from 'react-router-dom'
import { useMachinesListWithRealtime } from 'hooks'
import routes from 'config/routes'
import { ReactComponent as ListViewIcon } from 'assets/icon/list-icon.svg'
import { ReactComponent as MapViewIcon } from 'assets/icon/map-icon.svg'
import { useLanguage, useTheme } from 'providers'

const machineGeocodeFetcher = getSwrFetcher(
  { method: 'GET' },
  (data: Api.MachineGeocode[]): Store.MachineGeocode[] =>
    Array.isArray(data) ? data.map(mapMachineGeocode) : data,
)

export const MachineMapPage: React.FC = () => {
  const navigate = useNavigate()
  const params = new URLSearchParams(window.location.search)
  const id = +params.get('id')
  const [selectedMachinesId, setSelectedMachinesId] = useState<number[]>([id])
  const [activeTab, setActiveTab] = useState<ProductType>()
  const lang = useLanguage()

  const {
    data: machineGeocode,
    isLoading: isLoadingGeocode,
    error: errorGeocode,
  } = useSWR(config.api.geocodeAllUserMachinesAddresses, machineGeocodeFetcher, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
    refreshWhenOffline: false,
    revalidateOnMount: true, // Fetch only on mount
    revalidateOnReconnect: false,
    errorRetryCount: 0,
    shouldRetryOnError: false,
  })

  const { getStyles } = useTheme()
  const themeStyles = getStyles(themes)
  const allMachinesClass = classNames(styles['home-page'], themeStyles.theme)
  const [modalMode, setModalMode] = useState<'closed' | 'create'>('closed')
  const axios = getAxios()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const getUserRole = (): UserRoles => {
    try {
      const userClaims = getUserClaims()
      return userClaims.userRole
    } catch {
      return null
    }
  }
  const role = getUserRole()

  useMount(() => {
    if (role) {
      dispatch(fetchUserMachineList())
    }
  })

  if (!role) {
    return null
  }

  const putMachineForUserHandler = async (code: string): Promise<{ id: number } | ApiError> => {
    try {
      const response = await axios.put<{ id: number }>(
        config.api.putMachineForUser(code),
        {},
        await getAuthenticationHeader(),
      )
      return response.data
    } catch (error) {
      notification.error({ message: t('messages.invalidInput'), placement: 'topLeft' })
    }
  }

  const handleCancel = useCallback(() => {
    setModalMode('closed')
  }, [setModalMode])

  const openAddModal = useCallback(() => {
    setModalMode('create')
  }, [setModalMode])

  const handleConfirm = useCallback(
    async (webcode: string) => {
      const id = await putMachineForUserHandler(webcode)
      if (id) {
        dispatch(fetchUserMachineList())
        setModalMode('closed')
        notification.success({ message: t('messages.newMachineAdded'), placement: 'topLeft' })
      }
    },
    [putMachineForUserHandler],
  )

  const { data: machinesList, machinesListLoading } = useMachinesListWithRealtime()

  const selectedMachines = (machines: Store.MachineWithRealtime[], ids: number[]) => {
    return machines?.filter((machine) => ids?.includes(machine.id))
  }

  const filteredMachineGeocode = machineGeocode?.filter((value) => value?.coordinates)

  const filterMachineGeocodeByProduct = (
    machineWithRealtime: Store.MachineWithRealtime[],
    machineGeocode: Store.MachineGeocode[],
    product: ProductType,
  ): Store.MachineGeocode[] => {
    const filteredArray = machineWithRealtime?.filter((m) => m.product === product).map((m) => m.id)
    return machineGeocode?.filter((m) => filteredArray?.includes(m.id))
  }

  return machinesListLoading || isLoadingGeocode ? (
    <></>
  ) : (
    <section className={allMachinesClass}>
      <section className="app-container">
        <PageHeader
          title={<Typography.Title level={1}>{t('allMachines.title')}</Typography.Title>}
          extra={
            !(role === UserRoles.Admin || role === UserRoles.Tester) && (
              <Button
                className="add-machine-btn"
                size="large"
                type="primary"
                icon={<AddIcon className="btn-icon" />}
                onClick={openAddModal}
              >
                {t('general.newMachine')}
              </Button>
            )
          }
        />

        <NewMachineModal
          open={modalMode !== 'closed'}
          handleCancel={handleCancel}
          handleConfirm={handleConfirm}
        />

        <AllMachinesTabs
          isMapPage
          machineGeocode={filteredMachineGeocode}
          setActiveTab={(activeTab) => {
            setActiveTab(activeTab)
            navigate(`/map`)
          }}
          activeTab={activeTab}
        />

        <div className="list-view">
          <Radio.Group
            className="view-type-switch"
            defaultValue={ListMapView.MapView}
            buttonStyle="solid"
            onChange={(e) => {
              const type = e.target.value
              if (ListMapView.MapView === type) {
                navigate(routes.allMachines.url.concat(type))
              } else {
                navigate(routes.allMachines.url)
              }
            }}
          >
            <Tooltip placement="top" title={t('general.listView')}>
              <Radio.Button className="radio-list" value={ListMapView.ListView}>
                <ListViewIcon />
              </Radio.Button>
            </Tooltip>
            <Tooltip placement="top" className="radio-map" title={t('general.mapView')}>
              <Radio.Button value={ListMapView.MapView}>
                <MapViewIcon />
              </Radio.Button>
            </Tooltip>
          </Radio.Group>
        </div>

        <MachineMap
          lang={lang}
          machineGeocode={
            activeTab === ProductType.Ecostab || activeTab === ProductType.Sterostab
              ? filterMachineGeocodeByProduct(machinesList, filteredMachineGeocode, activeTab)
              : filteredMachineGeocode
          }
          isLoadingGeocode={isLoadingGeocode}
          errorGeocode={errorGeocode}
          machines={selectedMachines(machinesList, selectedMachinesId)}
          setSelectedMachinesId={setSelectedMachinesId}
        />
      </section>
    </section>
  )
}

export default MachineMapPage
