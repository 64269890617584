export enum RequestStatus {
  None = 0,
  Requesting = 1,
  Success = 2,
  Error = 3,
}

export enum EnergyTimelineTabIndex {
  Att_Out = 0,
  Reac_Out = 1,
  Reai_Out = 2,
}

export enum ThemeType {
  Light = 'light',
  Dark = 'dark',
}

export enum ErrorCode {
  Unknown = 0,
  Api = 1,
  Parameters = 3,
  PageUndefined = 4,
  GraphQlApi = 11,
  BootstrapFailed = 101,
  Login = 102,
  Register = 103,
  RegisterCodeNotFound = 104,
  RegisterInvalidPassword = 105,
  RegisterEmailTaken = 106,
  UserAlreadyRegistered = 107,
  AuthenticationFailed = 108,
  GenericError = 999,
}

export enum LoadingState {
  Unset = 'unset',
  Loading = 'loading',
  Loaded = 'loaded',
  LoadFailure = 'load failure',
  Deleting = 'deleting',
  Deleted = 'deleted',
  DeleteFailed = 'deleting failed',
  Creating = 'creating',
  Created = 'created',
  CreateFailure = 'created failure',
  Updating = 'updating',
  Updated = 'updated',
  UpdateFailure = 'update failure',
}

export enum UserRoles {
  User = 'user',
  Pro = 'pro',
  Admin = 'admin',
  Tester = 'tester',
  Sales = 'sales',
}

export enum MachineRealtimeDataType {
  All = 'all',
  Current = 'current',
  Energy = 'energy',
  Ithd = 'ithd',
  Power = 'power',
  PowerFactor = 'powerfactor',
  Savings = 'savings',
  Voltage = 'voltage',
  Vthd = 'vthd',
}

export enum MachineMetricsDataType {
  Current = 'current',
  Energy = 'energy',
  Power = 'power',
  PowerFactor = 'powerfactor',
  Voltage = 'voltage',
  Vthd = 'vthd',
  Ithd = 'ithd',
}

export enum MachineDataTypeByPeriod {
  Energy = 'energy',
  Voltage = 'voltage',
  Current = 'current',
  VTHD = 'vthd',
  ITHD = 'ithd',
  Power = 'power',
  PowerFactor = 'powerfactor',
  // ...
}

export enum ProductType {
  Ecostab = 'ECOSTAB',
  Sterostab = 'STEROSTAB',
  Ecometer = 'ESMeter',
}

export enum ValueUnitType {
  ActivePowerMetricUnit = 'activePowerMetricUnit',
  EnergyMetricUnit = 'energyMetricUnit',
  PercMetricUnit = 'percMetricUnit',
  VoltageMetricUnit = 'voltageMetricUnit',
  CurrentMetricUnit = 'currentMetricUnit',
  ReactivePowerMetricUnit = 'reactivePowerMetricUnit',
  ApparentPowerMetricUnit = 'apparentPowerMetricUnit',
}

export enum BoxType {
  PotenzaAttiva = 'potenzaAttiva',
  EnergiaAttivaTotale = 'energiaAttivaTotale',
  SavingLifetime = 'savingLifetime',
  VConcatenata = 'VConcatenata',
  VFase = 'VFase',
  Current = 'current',
  PowerFactor = 'cosphi',
  VTHD = 'vthd',
  ITHD = 'ithd',
  PotenzaReattiva = 'potenzaReattiva',
  PotenzaApparente = 'potenzaApparente',
}

export enum RealtimeTab {
  Savings = 'savings',
  Voltage = 'voltage',
  Current = 'current',
  Power = 'power',
  PowerFactor = 'powerfactor',
  ITHD = 'ithd',
  VTHD = 'vthd',
  Energy = 'energy',
  Dashboard = 'dashboard',
}

export enum TimelineTab {
  Voltage = 'voltage',
  Current = 'current',
  Power = 'power',
  PowerFactor = 'powerfactor',
  ITHD = 'ithd',
  VTHD = 'vthd',
  Energy = 'energy',
  Report = 'report',
}

export enum MachineDataType {
  Realtime = 'realtime',
  Timeline = 'timeline',
  Evaluations = 'evaluations',
}

export enum TimelineTableRecordType {
  Energy = 'energy',
  Saving = 'saving',
  EsmSaving = 'esmsaving',
}

export enum RegistrationStep {
  FillForm = 'fillForm',
  EmailSent = 'emailSent',
  EmailConfirmed = 'emailConfirmed',
}

export enum MachineDetailsBoxMode {
  View = 'view',
  Edit = 'edit',
}

export enum ListMapView {
  ListView = 'view',
  MapView = 'map',
}

export enum TableSortDirection {
  Ascend = 'ascend',
  Descend = 'descend',
}
export enum ApiSortDirection {
  Asc = 'asc',
  Desc = 'desc',
}
