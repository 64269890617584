import type * as Api from 'types/api'
import type * as Store from 'types/store'

export const mapTranslations = ({
  id: labelId,
  labelTag,
  translations,
}: Api.LabelTag): Store.Translation => ({
  labelId,
  labelTag,
  translations: Object.fromEntries(
    translations.map(({ languageIso, id: translationId, translation, languageName, languageId }) => [
      languageIso,
      {
        translationId,
        translation,
        languageName,
        languageId,
      },
    ]),
  ),
})

export default mapTranslations
