import React, { useState } from 'react'
import useSWR from 'swr'
import dayjs from 'dayjs'

import TimelineDataCmp from 'components/TimelineData'
import getSwrFetcher from 'api/swr-fetcher'
import { mapMachineEnergyByPeriod } from 'services'
import { config } from 'core'
import TimelineChartBox from 'containers/TimelineChartBox'
import { MachineDetailsBoxMode, UserRoles } from 'types/enums'

import type * as Store from 'types/store'
import { getUserClaims } from 'core/localStore'
import { SuggestedAddress } from 'containers/MachineDetail'

interface UpdateMachineDetailsProps {
  address: string
  sector: string
}

interface Props {
  tab?: string
  machine: Store.MachineDetail
  registryPicklist: Store.RegistryPicklist
  doUpdateMachineDetails?: (machineDetails: UpdateMachineDetailsProps) => void
  doUpdateNickname?: (nickname: string, evalID?: string) => void
  doDeleteMachine?: () => void
  detailsMode?: MachineDetailsBoxMode
  setDetailsMode?: (
    detailsMode: MachineDetailsBoxMode | ((detailsMode: MachineDetailsBoxMode) => MachineDetailsBoxMode),
  ) => void

  setAddress: (value: string) => void
  suggestedAddress: SuggestedAddress[]
  setSuggestedAddress: (SuggestedAddress: SuggestedAddress[]) => void
  dateValues?: {
    start: Date
    end: Date
  }
}

const energyByPeriodFetcher = getSwrFetcher({ method: 'GET' }, mapMachineEnergyByPeriod)

export const TimelineData: React.FC<Props> = ({
  tab,
  machine,
  registryPicklist,
  doDeleteMachine,
  doUpdateMachineDetails,
  doUpdateNickname,
  detailsMode,
  setDetailsMode,
  setSuggestedAddress,
  suggestedAddress,
  setAddress,
  dateValues,
}) => {
  const [end, setEnd] = useState(
    dateValues?.end || dayjs(machine.latestCollectedDataTs).endOf('day').toDate(),
  )
  const [start, setStart] = useState(
    dateValues?.start || dayjs(end).subtract(29, 'days').startOf('day').toDate(),
  )
  const claims = getUserClaims()
  const { userRole } = claims

  const machineHasRegistrySet = (machine: Store.MachineDetail): boolean => {
    return (!machine.address || !machine.sector) &&
      (userRole === UserRoles.Pro || userRole === UserRoles.User)
      ? false
      : true
  }

  const now = dayjs()
  const endOfMonth = now.endOf('month')
  const currentMonth = now.month()

  const {
    data: data,
    isLoading,
    error,
  } = useSWR(
    dayjs(end).isSame(endOfMonth) ||
      (dayjs(end).isBefore(endOfMonth) &&
        machineHasRegistrySet(machine) &&
        machine.latestCollectedDataTs &&
        dayjs(machine.latestCollectedDataTs).isValid())
      ? config.api.getMachineEnergyData(machine.id, start, end)
      : null,
    energyByPeriodFetcher,
  )

  let _data: {
    data: Store.EnergyByPeriodDataPoint[]
    begin: Date
    end: Date
  }

  if (data?.data && dayjs(data.data[0].day).month() === currentMonth) {
    _data = { ...data, data: data.data.slice() }
  }

  return (
    <TimelineDataCmp
      tab={tab}
      machine={machine}
      registryPicklist={registryPicklist}
      timelineData={dayjs(data?.data[0].day).month() === currentMonth ? _data : data}
      timelineLoading={isLoading}
      timelineError={error}
      doUpdateMachineDetails={doUpdateMachineDetails}
      doUpdateNickname={doUpdateNickname}
      doDeleteMachine={doDeleteMachine}
      doUpdateMachineDateRange={(startNew: dayjs.Dayjs, endNew: dayjs.Dayjs) => {
        setStart(startNew.toDate())
        setEnd(endNew.toDate())
      }}
      detailsMode={detailsMode}
      setDetailsMode={setDetailsMode}
      tableExpandedRowRenderer={(record: Store.EnergyByPeriodDataPoint) => (
        <TimelineChartBox tab={tab} day={record.day} machineId={machine.id} />
      )}
      setAddress={setAddress}
      suggestedAddress={suggestedAddress}
      setSuggestedAddress={setSuggestedAddress}
    />
  )
}

export default TimelineData
