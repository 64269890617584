import { Button, Drawer, Menu, MenuProps, Spin } from 'antd'
import classNames from 'classnames'
import { useTheme } from 'providers/ThemeProvider'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getUserClaims } from 'core/localStore'
import { useAppSelector } from 'store'
import { selectLoadingMachinesMenu } from 'store/machines'

import { ReactComponent as AdminIcon } from 'assets/icon/admin-icon.svg'
import { ReactComponent as AllMachinesIcon } from 'assets/icon/all-machines-icon.svg'
import { ReactComponent as CloseIcon } from 'assets/icon/close-icon.svg'
import { ReactComponent as FavouriteIcon } from 'assets/icon/favourite-icon.svg'
import { ReactComponent as MenuIcon } from 'assets/icon/menu-icon.svg'
import { ReactComponent as SettingsIcon } from 'assets/icon/settings-icon.svg'
import MainLogoDark from 'assets/logo-dark-theme.png'
import MainLogoLight from 'assets/logo-light-theme.png'

import routes from 'config/routes'
import { Link } from 'react-router-dom'
import { ThemeType, UserRoles } from 'types/enums'
import type * as Store from 'types/store'
import styles from './styles.module.scss'
import { themes } from './themes'

export interface MachineMenuProps {
  mobile: boolean
  machines: Store.MachineMenuItem[]
  selectedKey?: undefined | string | `${number}`
}

export const MachineMenuComponent: React.FC<MachineMenuProps> = ({
  mobile = false,
  machines,
  selectedKey,
}) => {
  const { t } = useTranslation()
  const { getStyles, theme } = useTheme()
  const themeStyles = getStyles(themes)
  const hamburgerMenuClass = classNames(styles['hamburger-menu'], themeStyles.theme, 'hamburger-menu')
  const isDark = theme === ThemeType.Dark
  const [visible, setVisible] = useState(false)
  const machinesMenuLoading = useAppSelector(selectLoadingMachinesMenu)

  const claims = getUserClaims()
  const { userRole } = claims

  const items: MenuProps['items'] = [
    {
      label: <Link to={routes.allMachines.url}>{t('menu.allMachines')}</Link>,
      key: 'ALL_MACHINES',
      onClick: () => {
        visible && setVisible(false)
      },
      icon: <AllMachinesIcon />,
    },
    ...machines.map((m) => ({
      label: (
        <Link to={routes.machineDetail.url.replace(':id', m.id.toString())}>{m.note ? m.note : m.name}</Link>
      ),
      key: `${m.id}`,
      icon: (
        <FavouriteIcon
          className="favourite-icon"
          style={{ color: m.isFavourite ? '#FDCA40' : 'transparent' }}
        />
      ),
      onClick: () => {
        visible && setVisible(false)
      },
    })),
    ...(userRole === UserRoles.Admin
      ? [
          {
            label: t('general.admin'),
            key: 'ADMIN_FUNC',
            icon: <AdminIcon />,
            onClick: () => {
              visible && setVisible(false)
            },
            children: [
              {
                key: 'adminMachines',
                label: <Link to={routes.adminMachines.url}>{t('menu.items.adminMachines')}</Link>,
              },
              {
                key: 'adminUsers',
                label: <Link to={routes.adminUsers.url}>{t('menu.items.adminUsers')}</Link>,
              },
              {
                key: 'adminCodes',
                label: <Link to={routes.adminCodes.url}>{t('menu.items.adminCodes')}</Link>,
              },
            ],
          },
          {
            label: t('general.settings'),
            key: 'ADMIN_SETTINGS',
            icon: <SettingsIcon />,
            onClick: () => {
              visible && setVisible(false)
            },
            children: [
              {
                key: 'adminLanguages',
                label: <Link to={routes.languages.url}>{t('menu.items.languages')}</Link>,
              },
              {
                key: 'adminTranslations',
                label: <Link to={routes.translations.url}>{t('menu.items.translations')}</Link>,
              },
            ],
          },
        ]
      : []),
  ]

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const menuContent = machinesMenuLoading ? (
    <Spin />
  ) : (
    <Menu
      className="menu-scrollbar"
      defaultSelectedKeys={['ALL_MACHINES']}
      mode="inline"
      items={items}
      selectedKeys={[selectedKey]}
    />
  )

  return mobile ? (
    <div className={hamburgerMenuClass}>
      <Button
        className="p-0"
        type="text"
        icon={<MenuIcon className="hamburger-icon" />}
        onClick={showDrawer}
      />
      <div className="logo-wrapper">
        <img src={isDark ? MainLogoDark : MainLogoLight} className="app-logo full-logo" />
      </div>
      <Drawer
        className="menu-drawer"
        title={
          <div className="logo-wrapper">
            <img src={isDark ? MainLogoDark : MainLogoLight} width="40" className="app-logo" />
            <Button type="text" className="close-icon p-0" icon={<CloseIcon />} onClick={onClose} />
          </div>
        }
        placement="left"
        closable={false}
        onClose={onClose}
        open={visible}
      >
        {menuContent}
      </Drawer>
    </div>
  ) : (
    <div className={styles['sider-menu']}>{menuContent}</div>
  )
}

export default MachineMenuComponent
