import { createSlice } from '@reduxjs/toolkit'

import * as Store from 'types/store'
import { LoadingState } from 'types/enums'

import { STATE_KEY } from './constants'
import { loginThunk, logoutThunk, registerThunk } from './thunks'
import { ResponseCode } from 'types/enums.api'

// The initial state of the reducer
export const initialState: Store.UserState = {
  state: LoadingState.Unset,
  user: null,
  error: null,
  errorMessage: null,
  lastLogin: null,
  register: null,
}

// NOTE: uses "immer" library, internally
export const userSlice = createSlice({
  name: STATE_KEY,
  initialState,
  reducers: {},
  // Handle async thunk states in the extraReducers
  extraReducers: (builder) => {
    // LOGIN
    builder.addCase(loginThunk.pending, (state) => {
      state.state = LoadingState.Loading
    })
    builder.addCase(loginThunk.fulfilled, (state, action) => {
      state.state = LoadingState.Loaded
      state.error = null
      state.lastLogin = new Date()
      state.user = action.payload
    })
    builder.addCase(loginThunk.rejected, (state) => {
      state.state = LoadingState.LoadFailure
      state.error = ResponseCode.GenericError
    })

    // REGISTER
    builder.addCase(registerThunk.pending, (state) => {
      state.state = LoadingState.Creating
    })

    builder.addCase(registerThunk.fulfilled, (state, action) => {
      state.state = LoadingState.Created
      state.error = null
      state.register = action.payload
    })

    builder.addCase(registerThunk.rejected, (state, action) => {
      state.state = LoadingState.CreateFailure
      state.errorMessage = action.payload.message
    })

    // LOGOUT
    builder.addCase(logoutThunk.pending, (state) => {
      state.state = LoadingState.Deleting
    })
    builder.addCase(logoutThunk.fulfilled, (state) => {
      state.state = LoadingState.Deleted
      state.user = null
      state.error = null
    })
  },
})

export default userSlice
