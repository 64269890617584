import React from 'react'

import { useFetchRealtimeData } from 'hooks'
import PowerFactorBoxCmp from 'components/boxes/PowerFactorBox'
import { MachineRealtimeDataType } from 'types/enums'
import type * as Store from 'types/store'

interface PowerFactorBoxProps {
  boxes: Store.MachineBox[]
  machineId: number | null | undefined
}

export const PowerFactorBox: React.FC<PowerFactorBoxProps> = ({ boxes, machineId }) => {
  const {
    data: realtimeData,
    isLoading: realtimeIsLoading,
    error: realtimeError,
  } = useFetchRealtimeData(machineId, MachineRealtimeDataType.PowerFactor)

  return (
    <PowerFactorBoxCmp
      boxes={boxes}
      realtimeData={realtimeData}
      realtimeIsLoading={realtimeIsLoading}
      realtimeError={realtimeError}
    />
  )
}

export default PowerFactorBox
