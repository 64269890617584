import React from 'react'
import classNames from 'classnames'
import { ThemeType } from 'types/enums'
import { useTheme } from 'providers/ThemeProvider'

import styles from './style.module.scss'
import { themes } from './themes'
import { Button } from 'antd'

const ThemeSwitcher: React.FC = () => {
  const { setTheme, theme, getStyles } = useTheme()

  const themeStyles = getStyles(themes)

  const switcherClass = classNames(styles.switcher, themeStyles.theme, 'p-0')

  return (
    <Button
      type="link"
      className={switcherClass}
      onClick={() => (theme === ThemeType.Light ? setTheme(ThemeType.Dark) : setTheme(ThemeType.Light))}
    >
      <div className={theme === ThemeType.Light ? 'sun' : 'moon'}></div>
    </Button>
  )
}

export default ThemeSwitcher
