import { Button, Dropdown, MenuProps } from 'antd'
import { getEvalWordDocument, getTimelineWordDocument } from 'api/timelineData'
import { generateDownloadLink } from 'core/helpers'
import { getUserRole } from 'core/localStore'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ProductType, TimelineTableRecordType, UserRoles } from 'types/enums'

interface Props {
  machineType: string
  machineId: number
  start?: Date
  end?: Date
  evalId?: number
  lang: string
}

const downloadWordDocument = async (
  machineType: string,
  start: Date,
  end: Date,
  reportType: TimelineTableRecordType,
  machineId: number,
  evalId: number,
  lang: string,
): Promise<string> => {
  //!! ARGH set times to avoid Timezone format to sgarelling
  start.setHours(10)
  end.setHours(10)

  const data =
    machineType == ProductType.Ecometer
      ? await getEvalWordDocument(machineId, evalId, reportType, lang, start.toISOString(), end.toISOString())
      : await getTimelineWordDocument(machineId, reportType, lang, start.toISOString(), end.toISOString())
  const errorCase = data as ApiError
  if (errorCase?.status) {
    return null
  }

  return data as string
}

export const DownloadTimelineDocument: React.FC<Props> = ({
  start,
  end,
  machineType,
  machineId,
  evalId,
  lang,
}) => {
  const [isLoading, setLoading] = useState(false)
  const { t } = useTranslation()

  const downloadFile = async (reportType: TimelineTableRecordType) => {
    setLoading(true)
    const data = await downloadWordDocument(machineType, start, end, reportType, machineId, evalId, lang)
    setLoading(false)
    if (data) {
      generateDownloadLink(data, 'File.docx')
    }
  }

  const reportMenuItems = (machineType: string): MenuProps['items'] => {
    const energyItem = {
      key: TimelineTableRecordType.Energy,
      label: (
        <Button onClick={() => downloadFile(TimelineTableRecordType.Energy)}>
          {t('machine.downloadEnergyReport')}
        </Button>
      ),
    }
    const savingItem = {
      key: TimelineTableRecordType.Saving,
      label: (
        <Button onClick={() => downloadFile(TimelineTableRecordType.Saving)}>
          {t('machine.downloadSavingReport')}
        </Button>
      ),
    }
    const esmSavingItem = {
      key: TimelineTableRecordType.Saving,
      label: (
        <Button onClick={() => downloadFile(TimelineTableRecordType.EsmSaving)}>
          {t('machine.downloadSavingReport')}
        </Button>
      ),
    }
    switch (machineType) {
      case ProductType.Sterostab:
        return [energyItem]
      case ProductType.Ecostab:
        return [savingItem]
      case ProductType.Ecometer:
        return [esmSavingItem, energyItem]
      default:
        break
    }
  }

  const userRole = getUserRole()
  if (
    userRole &&
    (userRole === UserRoles.Admin ||
      userRole === UserRoles.Tester ||
      userRole === UserRoles.Sales ||
      userRole === UserRoles.Pro)
  ) {
    return (
      <Dropdown menu={{ items: reportMenuItems(machineType) }} placement="bottom" disabled={isLoading}>
        <Button loading={isLoading}>{t('machine.downloadReport')}</Button>
      </Dropdown>
    )
  }
  return null
}

export default DownloadTimelineDocument
