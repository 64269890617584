import type * as Api from 'types/api'
import type * as Store from 'types/store'

type ApiBoxValueDetails = Api.MachineBox['valueDetails'][0]
type StoreBoxValueDetails = Store.MachineBox['valueDetails'][0]

const mapMachineBoxValueDetails = ({ label, binding, unit }: ApiBoxValueDetails): StoreBoxValueDetails => ({
  label,
  binding,
  unit,
})

export const mapMachineBox = ({
  mainValueLabel,
  mainValueBinding,
  mainValueUnit,
  mainValueSubtext,
  isInput,
  isLifetime,
  valueDetails,
  order,
  orderMobile,
}: Api.MachineBox): Store.MachineBox => ({
  mainValueLabel,
  mainValueBinding,
  mainValueUnit,
  mainValueSubtext,
  isInput,
  isLifetime,
  valueDetails: Array.isArray(valueDetails) ? valueDetails.map(mapMachineBoxValueDetails) : valueDetails,
  order,
  orderMobile,
})

export default mapMachineBox
