import React from 'react'
import { Col, Row, Typography } from 'antd'
import type { Gutter } from 'antd/es/grid/row'
import { isNumeric, toNumber } from 'core'
import { useNumberFormat } from 'hooks'
import { useTranslation } from 'react-i18next'

interface SummaryData {
  description: string
  unit: string
  value: number
}

export interface SummaryProps {
  data: SummaryData[]
}

const gutter: [Gutter, Gutter] = [16, 0]

export const Summary: React.FC<SummaryProps> = ({ data }) => {
  const { t } = useTranslation()
  const formatNumber = useNumberFormat()

  return (
    <Row className="values-wrapper pdf-report-summary" gutter={gutter}>
      {data.map(({ description, unit, value }, i) => (
        <Col key={i}>
          <Typography.Title level={5} className="card-subtitle">
            {description}
          </Typography.Title>
          <Typography.Text className="box-value">
            <span className="numeric">
              {isNumeric(value) ? formatNumber(toNumber(value)) : t('general.NA')}
            </span>
            <span className="unit">{unit}</span>
          </Typography.Text>
        </Col>
      ))}
    </Row>
  )
}

export default Summary
