import React from 'react'
import RealtimeInOutBox from 'components/RealtimeInOutBox'
import type * as Store from 'types/store'
import { groupBoxesByLabel } from '../VoltageBox'
import { BoxType } from 'types/enums'

interface PowerFactorBoxProps {
  boxes: Store.MachineBox[]
  realtimeData: Store.MachineRealTimeResponse<Store.RealtimePowerFactorData>
  realtimeIsLoading: boolean
  realtimeError: unknown
}

export const PowerFactorBox: React.FC<PowerFactorBoxProps> = ({ boxes, realtimeData, realtimeError }) => {
  if (realtimeError) {
    // TODO: better error handling
  }

  const powerFactorBoxByLabel = groupBoxesByLabel(boxes)

  const value = realtimeData?.metrics
    ? {
        inPowerFactor: [
          realtimeData.metrics.powerFactorInputEquivalent,
          realtimeData.metrics.powerFactorInputPhaseR,
          realtimeData.metrics.powerFactorInputPhaseS,
          realtimeData.metrics.powerFactorInputPhaseT,
        ],
        outPowerFactor: [
          realtimeData.metrics.powerFactorOutputEquivalent,
          realtimeData.metrics.powerFactorOutputPhaseU,
          realtimeData.metrics.powerFactorOutputPhaseV,
          realtimeData.metrics.powerFactorOutputPhaseW,
        ],
      }
    : null

  return (
    <>
      <div className="realtime-boxes">
        <RealtimeInOutBox
          box={powerFactorBoxByLabel && powerFactorBoxByLabel[BoxType.PowerFactor]}
          inValue={value?.inPowerFactor}
          outValue={value?.outPowerFactor}
          title={BoxType.PowerFactor}
        />
      </div>
    </>
  )
}

export default PowerFactorBox
