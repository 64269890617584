import { TFunction } from 'i18next'
import { BoxType } from 'types/enums'

function createStructured(labels, value, object) {
  const property = labels.pop()
  if (labels.length === 0) {
    object[property] = value
    return
  }

  if (Object.prototype.hasOwnProperty.call(object, property)) {
    createStructured(labels, value, object[property])
  } else {
    object[property] = {}
    createStructured(labels, value, object[property])
  }
}

export const labelParser = (data: string) => {
  if (!data) {
    return {}
  }
  const parsed = JSON.parse(data)

  const normalized = Object.entries(parsed)
    .map(([k, v]) => ({ key: k, value: v }))
    .reduce((prev, current) => {
      prev[current.key] = current.value
      return prev
    }, {})

  const structured = {}

  Object.keys(normalized).forEach((key) => {
    try {
      const value = normalized[key]
      const labels = key.split('.').reverse()
      return createStructured(labels, value, structured)
    } catch (e) {
      console.error(`labelParser exception: label=${key}`, e)
    }
  })

  return structured
}

const getData = async (
  options: any,
  url: string,
  payload: any,
  callback: (err: any, response: any) => void,
) => {
  try {
    const response: Response = await fetch(url)
    if (!response.ok) {
      callback(response.statusText || 'Error', { status: response.status })
    }
    const text = await response.text()
    const output = {
      status: response.status,
      data: text,
    }
    callback(null, output)
  } catch (error) {
    callback(error, null)
  }
}

export const requestLabel = (
  options: any,
  url: string,
  payload: any,
  callback: (err: any, response: any) => void,
) => {
  getData(options, url, payload, callback)
}

export const getBoxTitle = (boxType: BoxType, t: TFunction<'translation', undefined, 'translation'>) => {
  switch (boxType) {
    case BoxType.PotenzaAttiva:
      return t('machineDetail.box.realPower')
    case BoxType.EnergiaAttivaTotale:
      return t('machineDetail.box.totalActiveEnergy')
    case BoxType.SavingLifetime:
      return t('machineDetail.box.saving')
    case BoxType.VConcatenata:
      return t('machineDetail.box.phaseToPhaseVoltage')
    case BoxType.VFase:
      return t('machineDetail.box.phaseNeutralVoltage')
    case BoxType.Current:
      return t('machineDetail.current')
    case BoxType.PowerFactor:
      return t('machineDetail.powerFactor')
    case BoxType.VTHD:
      return t('machineDetail.box.totalHarmonicDistortionVoltage')
    case BoxType.ITHD:
      return t('machineDetail.box.totalHarmonicDistortionCurrent')
    case BoxType.PotenzaReattiva:
      return t('machineDetail.box.reactivePower')
    case BoxType.PotenzaApparente:
      return t('machineDetail.box.apparentPower')
    default:
      return boxType
  }
}
