import * as Api from 'types/api'
import * as Store from 'types/store'

export const mapMachineGeocode = ({
  id,
  address,
  coordinates,
  error,
}: Api.MachineGeocode): Store.MachineGeocode => ({
  id,
  error,
  coordinates,
  address,
})
