import type * as Api from 'types/api'
import type * as Store from 'types/store'

export const mapMachineVoltageByDay = ({
  v12,
  v12_min,
  v12_max,
  v23,
  v23_min,
  v23_max,
  v31,
  v31_min,
  v31_max,
  vout,
  vout_min,
  vout_max,
}: Api.MachineVoltageByDay): Store.MachineVoltageByDay => ({
  v12: v12,
  v12Min: v12_min,
  v12Max: v12_max,
  v23: v23,
  v23Min: v23_min,
  v23Max: v23_max,
  v31: v31,
  v31Min: v31_min,
  v31Max: v31_max,
  vOut: vout,
  vOutMin: vout_min,
  vOutMax: vout_max,
})

export default mapMachineVoltageByDay
