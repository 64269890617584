import { useFetchMachineLatestData } from 'hooks'
import React from 'react'

import RealtimeChartBox from 'components/RealtimeChartBox'
import { MachineMetricsDataType } from 'types/enums'

interface Props {
  machineId: number
}

export const IthdChartBox: React.FC<Props> = ({ machineId }) => {
  const {
    data: latestData,
    isLoading,
    error,
  } = useFetchMachineLatestData(machineId, MachineMetricsDataType.Ithd)

  if (isLoading || error) {
    // TODO: better error handling and loading
    return null
  }

  return (
    <div className="chart-box">
      <RealtimeChartBox type={MachineMetricsDataType.Ithd} metrics={latestData} />
    </div>
  )
}

export default IthdChartBox
