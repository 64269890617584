import { MachineMetricsDataType } from 'types/enums'
import type * as Store from 'types/store'
import type * as Api from 'types/api'

import { mapDate } from './map-date'
import { mapMachineVoltageByDay } from './map-machine-voltage-by-day'
import { mapMachineCurrentByDay } from './map-machine-current-by-day'
import { mapMachinePowerByDay } from './map-machine-power-by-day'
import { mapMachinePowerFactorByDay } from './map-machine-power-factor-by-day'
import { mapMachineVthdByDay } from './map-machine-vthd-by-day'
import { mapMachineIthdByDay } from './map-machine-ithd-by-day'
import { mapMachineEnergyByDay } from './map-machine-energy-by-day'

export const mapMachineByDayResponse = <
  T extends Api.MachineByDayResponseTypes,
  R extends Store.MachineByDayResponseTypes,
>(
  { day, data: _data }: Api.MachineDataByDayResponse<T>,
  type: MachineMetricsDataType,
): Store.MachineDataByDayResponse<R> => {
  let data: null | Store.MachineDataByDayResponse<R>['data'] = null

  if (Array.isArray(_data)) {
    switch (type) {
      case MachineMetricsDataType.Voltage:
        data = _data.map((d: any) => ({ ...mapMachineVoltageByDay(d), t: mapDate(d.t) })) as any
        break
      case MachineMetricsDataType.Current:
        data = _data.map((d: any) => ({ ...mapMachineCurrentByDay(d), t: mapDate(d.t) })) as any
        break
      case MachineMetricsDataType.Power:
        data = _data.map((d: any) => ({ ...mapMachinePowerByDay(d), t: mapDate(d.t) })) as any
        break
      case MachineMetricsDataType.PowerFactor:
        data = _data.map((d: any) => ({ ...mapMachinePowerFactorByDay(d), t: mapDate(d.t) })) as any
        break
      case MachineMetricsDataType.Vthd:
        data = _data.map((d: any) => ({ ...mapMachineVthdByDay(d), t: mapDate(d.t) })) as any
        break
      case MachineMetricsDataType.Ithd:
        data = _data.map((d: any) => ({ ...mapMachineIthdByDay(d), t: mapDate(d.t) })) as any
        break
      case MachineMetricsDataType.Energy:
        data = _data.map((d: any) => ({ ...mapMachineEnergyByDay(d), t: mapDate(d.t) })) as any
        break
      default:
        throw new Error(`Invalid machine by day type: "${type}"!`)
    }
  }

  return {
    day: mapDate(day),
    data,
  }
}

export default mapMachineByDayResponse
