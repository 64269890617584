import { ChartData, Scale } from 'chart.js'
import dayjs from 'dayjs'
import { TFunction } from 'i18next'

import { config } from 'core'
import { getThemeColors } from 'core/chartThemeColors'

import { MachineMetricsDataType, ThemeType } from 'types/enums'

import type * as Store from 'types/store'

type Data =
  | {
      type: MachineMetricsDataType.Voltage
      metrics: Store.MachineLatestVoltageData
    }
  | {
      type: MachineMetricsDataType.Current
      metrics: Store.MachineLatestCurrentData
    }
  | {
      type: MachineMetricsDataType.Power
      metrics: Store.MachineLatestPowerData
    }
  | {
      type: MachineMetricsDataType.PowerFactor
      metrics: Store.MachineLatestPowerFactorData
    }
  | {
      type: MachineMetricsDataType.Vthd | MachineMetricsDataType.Ithd
      metrics: Store.MachineLatestThdData
    }
// TODO: add the other latest data types

const getLabels = (dataPoints: Store.MachineLatestDataPoint[]) =>
  dataPoints.map(({ t }) => dayjs(t).format(config.getDateTimeFormat()))

export function getChartData(
  theme: ThemeType,
  data: Data,
  t: TFunction<'translation', undefined, 'translation'>,
): ChartData<'line', number[], string> {
  const colors = getThemeColors(theme)
  switch (data.type) {
    case MachineMetricsDataType.Voltage:
      return {
        labels: getLabels(data.metrics.vInConc),
        datasets: [
          {
            label: t('machineDetail.voltageBox.inMin'),
            data: data.metrics.vInMinConc.map((v) => v.vInMinConcAvg),
            fill: '+2',
            borderWidth: 1,
            borderColor: colors.redBorderColor,
            backgroundColor: colors.redFillerColor,
            tension: 0.8,
            pointRadius: 0,
            hidden: false,
          },
          {
            label: t('machineDetail.voltageBox.in'),
            data: data.metrics.vInConc.map((v) => v.vInConcAvg),
            borderWidth: 1,
            borderColor: colors.redColor,
            backgroundColor: colors.redColor,
            pointStyle: 'circle',
            pointRadius: 3,
            pointHoverRadius: 5,
            tension: 0.8,
            hidden: false,
            fill: false,
          },
          {
            label: t('machineDetail.voltageBox.inMax'),
            data: data.metrics.vInMaxConc.map((v) => v.vInMaxConcAvg),
            borderWidth: 1,
            borderColor: colors.redBorderColor,
            backgroundColor: colors.redFillerColor,
            tension: 0.8,
            pointRadius: 0,
            hidden: false,
            fill: false,
          },
          {
            label: t('machineDetail.voltageBox.outMin'),
            data: data.metrics.vOutMinConc.map((v) => v.vOutMinConcAvg),
            fill: '+2',
            borderWidth: 1,
            borderColor: colors.greenBorderColor,
            backgroundColor: colors.greenFillerColor,
            tension: 0.8,
            pointRadius: 0,
            hidden: false,
          },
          {
            label: t('machineDetail.voltageBox.out'),
            data: data.metrics.vOutConc.map((v) => v.vOutConcAvg),
            borderWidth: 1,
            borderColor: colors.greenColor,
            backgroundColor: colors.greenColor,
            pointStyle: 'circle',
            pointRadius: 3,
            pointHoverRadius: 5,
            tension: 0.8,
            hidden: false,
            fill: false,
          },
          {
            label: t('machineDetail.voltageBox.outMax'),
            data: data.metrics.vOutMaxConc.map((v) => v.vOutMaxConcAvg),
            borderWidth: 1,
            borderColor: colors.greenBorderColor,
            backgroundColor: colors.greenFillerColor,
            tension: 0.8,
            pointRadius: 0,
            hidden: false,
            fill: false,
          },
        ],
      }
    case MachineMetricsDataType.Current:
      return {
        labels: getLabels(data.metrics.cIn),
        datasets: [
          {
            label: t('machineDetail.currentBox.cInMin'),
            data: data.metrics.cInMin.map((v) => v.cInMinAvg),
            fill: '+2',
            borderWidth: 1,
            borderColor: colors.redBorderColor,
            backgroundColor: colors.redFillerColor,
            tension: 0.8,
            pointRadius: 0,
            hidden: false,
          },
          {
            label: t('machineDetail.currentBox.cIn'),
            data: data.metrics.cIn.map((v) => v.cInAvg),
            borderWidth: 1,
            borderColor: colors.redColor,
            backgroundColor: colors.redColor,
            pointStyle: 'circle',
            pointRadius: 3,
            pointHoverRadius: 5,
            tension: 0.8,
            hidden: false,
            fill: false,
          },
          {
            label: t('machineDetail.currentBox.cInMax'),
            data: data.metrics.cInMax.map((v) => v.cInMaxAvg),
            borderWidth: 1,
            borderColor: colors.redBorderColor,
            backgroundColor: colors.redFillerColor,
            tension: 0.8,
            pointRadius: 0,
            hidden: false,
            fill: false,
          },
          {
            label: t('machineDetail.currentBox.cOutMin'),
            data: data.metrics.cOutMin.map((v) => v.cOutMinAvg),
            fill: '+2',
            borderWidth: 1,
            borderColor: colors.greenBorderColor,
            backgroundColor: colors.greenFillerColor,
            tension: 0.8,
            pointRadius: 0,
            hidden: false,
          },
          {
            label: t('machineDetail.currentBox.cOut'),
            data: data.metrics.cOut.map((v) => v.cOutAvg),
            borderWidth: 1,
            borderColor: colors.greenColor,
            backgroundColor: colors.greenColor,
            pointStyle: 'circle',
            pointRadius: 3,
            pointHoverRadius: 5,
            tension: 0.8,
            hidden: false,
            fill: false,
          },
          {
            label: t('machineDetail.currentBox.cOutMax'),
            data: data.metrics.cOutMax.map((v) => v.cOutMaxAvg),
            borderWidth: 1,
            borderColor: colors.greenBorderColor,
            backgroundColor: colors.greenFillerColor,
            tension: 0.8,
            pointRadius: 0,
            hidden: false,
            fill: false,
          },
        ],
      }
    case MachineMetricsDataType.Power:
      return {
        labels: getLabels(data.metrics.pOutTotal),
        datasets: [
          {
            label: t('machineDetail.powerBox.p1'),
            data: data.metrics.pOutU.map((v) => v.pOutU),
            borderWidth: 1,
            borderColor: colors.brownColor,
            backgroundColor: colors.brownColor,
            pointStyle: 'circle',
            pointRadius: 3,
            pointHoverRadius: 5,
            tension: 0.8,
            hidden: false,
            fill: false,
          },
          {
            label: t('machineDetail.powerBox.p2'),
            data: data.metrics.pOutV.map((v) => v.pOutV),
            borderWidth: 1,
            borderColor: colors.grayColor,
            backgroundColor: colors.grayColor,
            pointStyle: 'circle',
            pointRadius: 3,
            pointHoverRadius: 5,
            tension: 0.8,
            hidden: false,
            fill: false,
          },
          {
            label: t('machineDetail.powerBox.p3'),
            data: data.metrics.pOutW.map((v) => v.pOutW),
            borderWidth: 1,
            borderColor: colors.blackOrWhiteColor,
            backgroundColor: colors.blackOrWhiteColor,
            pointStyle: 'circle',
            pointRadius: 3,
            pointHoverRadius: 5,
            tension: 0.8,
            hidden: false,
            fill: false,
          },
          {
            label: t('machineDetail.powerBox.totalW'),
            data: data.metrics.pOutTotal.map((v) => v.pOutTotal),
            borderWidth: 1,
            borderColor: colors.greenColor,
            backgroundColor: colors.greenColor,
            pointStyle: 'circle',
            pointRadius: 3,
            pointHoverRadius: 5,
            tension: 0.8,
            hidden: false,
            fill: false,
          },
        ],
      }
    case MachineMetricsDataType.PowerFactor:
      return {
        labels: getLabels(data.metrics.cosPhi),
        datasets: [
          {
            label: t('machineDetail.powerFactorBox.pFIn'),
            data: data.metrics.cosPhi.map((v) => v.cosPhiIn),
            borderWidth: 1,
            borderColor: colors.redColor,
            backgroundColor: colors.redColor,
            pointStyle: 'circle',
            pointRadius: 3,
            pointHoverRadius: 5,
            tension: 0.8,
            hidden: false,
            fill: false,
          },
          {
            label: t('machineDetail.powerFactorBox.pFOut'),
            data: data.metrics.cosPhi.map((v) => v.cosPhiOut),
            borderWidth: 1,
            borderColor: colors.greenColor,
            backgroundColor: colors.greenColor,
            pointStyle: 'circle',
            pointRadius: 3,
            pointHoverRadius: 5,
            tension: 0.8,
            hidden: false,
            fill: false,
          },
        ],
      }
    case MachineMetricsDataType.Vthd:
      return {
        labels: getLabels(data.metrics.vthd),
        datasets: [
          {
            label: t('machineDetail.VTHD.in'),
            data: data.metrics.vthd.map((v) => v.vthdIn),
            borderWidth: 1,
            borderColor: colors.redColor,
            backgroundColor: colors.redColor,
            pointStyle: 'circle',
            pointRadius: 3,
            pointHoverRadius: 5,
            tension: 0.8,
            hidden: false,
            fill: false,
          },
          {
            label: t('machineDetail.VTHD.out'),
            data: data.metrics.vthd.map((v) => v.vthdOut),
            borderWidth: 1,
            borderColor: colors.greenColor,
            backgroundColor: colors.greenColor,
            pointStyle: 'circle',
            pointRadius: 3,
            pointHoverRadius: 5,
            tension: 0.8,
            hidden: false,
            fill: false,
          },
        ],
      }
    case MachineMetricsDataType.Ithd:
      return {
        labels: getLabels(data.metrics.ithd),
        datasets: [
          {
            label: t('machineDetail.ITHD.in'),
            data: data.metrics.ithd.map((v) => v.ithdIn),
            borderWidth: 1,
            borderColor: colors.redColor,
            backgroundColor: colors.redColor,
            pointStyle: 'circle',
            pointRadius: 3,
            pointHoverRadius: 5,
            tension: 0.8,
            hidden: false,
            fill: false,
          },
          {
            label: t('machineDetail.ITHD.out'),
            data: data.metrics.ithd.map((v) => v.ithdOut),
            borderWidth: 1,
            borderColor: colors.greenColor,
            backgroundColor: colors.greenColor,
            pointStyle: 'circle',
            pointRadius: 3,
            pointHoverRadius: 5,
            tension: 0.8,
            hidden: false,
            fill: false,
          },
        ],
      }

    default:
      throw new Error('Invalid chart type')
  }
}

export const getChartOptions = (theme: ThemeType) => {
  const colors = getThemeColors(theme)

  return {
    responsive: true,
    maintainAspectRatio: false,
    cubicInterpolationMode: 'monotone',
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      filler: {
        propagate: false,
      },
      'samples-filler-analyser': {
        target: 'chart-analyser',
      },
    },
    scales: {
      x: {
        color: colors.tickColor,
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          callback: function (this: Scale, val: number, index: number) {
            return index % 2 === 0 ? this.getLabelForValue(val) : ''
          },
          maxRotation: 0,
          minRotation: 0,
        },
      },
      y: {
        color: colors.tickColor,
        border: {
          display: false,
        },
        grid: {
          color: colors.gridColor,
        },
      },
    },
  }
}
