import React from 'react'
import { MachineMap as MachineMapCmp } from 'components/MachineMap'

import * as Store from 'types/store'

interface Props {
  machineGeocode: Store.MachineGeocode[]
  isLoadingGeocode: boolean
  errorGeocode: string
  machines: Store.MachineWithRealtime[]
  setSelectedMachinesId: (selectedMachineId: number[]) => void
  lang?: string
}

export const MachineMap: React.FC<Props> = ({
  machineGeocode,
  isLoadingGeocode,
  errorGeocode,
  machines,
  setSelectedMachinesId,
  lang,
}) => {
  return isLoadingGeocode || errorGeocode ? (
    <></>
  ) : (
    <MachineMapCmp
      selectedMachines={machines}
      machineGeocode={machineGeocode}
      setSelectedMachinesId={setSelectedMachinesId}
      lang={lang}
    />
  )
}

export default MachineMap
