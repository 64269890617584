import { Col, Radio, Row, Spin, Tooltip, Typography } from 'antd'
import { ReactComponent as FavouriteIcon } from 'assets/icon/favourite-icon.svg'
import { ReactComponent as RealtimeIcon } from 'assets/icon/realtime-icon.svg'
import { ReactComponent as ReportIcon } from 'assets/icon/report-icon.svg'
import { ReactComponent as TimelineIcon } from 'assets/icon/timeline-icon.svg'
import routes from 'config/routes'
import { config } from 'core'
import dayjs from 'dayjs'
import React, { useCallback, useEffect, useState } from 'react'
import { useAppDispatch } from 'store'
import { fetchUserMachineMenu } from 'store/machines'
import { useSWRConfig } from 'swr'
import type * as Store from 'types/store'

import PageHeader from 'components/PageHeader'
import { MachineDataType, MachineDetailsBoxMode, ProductType, RealtimeTab, TimelineTab } from 'types/enums'

import { getAxios } from 'api/apiService'
import { getApiError } from 'api/common'
import getSwrFetcher from 'api/swr-fetcher'
import { AxiosError } from 'axios'
import EvaluationData from 'containers/EvaluationData/EvaluationData'
import RealtimeData from 'containers/RealtimeData'
import TimelineData from 'containers/TimelineData'
import { debounce } from 'lodash'
import { useLanguage } from 'providers'
import { useTranslation } from 'react-i18next'
import useSWRMutation from 'swr/mutation'

export interface UpdateMachineDetailsProps {
  address: string
  sector: string
}

export interface SuggestedAddress {
  value: string
  place_formatted: string
  label?: JSX.Element
  disabled?: boolean
}
interface SuggestedAddressResponse {
  properties: {
    name: string
    place_formatted: string
  }
}

export interface MachineDetailProps {
  tab?: string
  machine: Store.MachineDetail
  isLoading: boolean
  machineId: number
  dataType: MachineDataType
  setDataType?: (type: MachineDataType) => void
  activeTab: RealtimeTab
  setActiveTab?: (tab: RealtimeTab) => void
  registryPicklist: Store.RegistryPicklist
  doUpdateMachineDetails?: (machineDetails: UpdateMachineDetailsProps) => void
  doUpdateNickname?: (nickname: string, evalID?: string) => void
  doDeleteMachine?: () => void
  detailsMode?: MachineDetailsBoxMode
  setDetailsMode?: (
    detailsMode: MachineDetailsBoxMode | ((detailsMode: MachineDetailsBoxMode) => MachineDetailsBoxMode),
  ) => void
  idEval?: number
  evaluations?: Store.MachineEvalutionItem[]
  evaluationByIdEval?: Store.MachineEvalutionItem
  isLoadingEvaluations?: boolean
  isLoadingEvaluationByIdEval?: boolean
  dateValues?: {
    start: Date
    end: Date
  }
}

export const updateFavoriteById = getSwrFetcher<void, { isFavorite: boolean }>({ method: 'PUT' })

export const MachineDetail: React.FC<MachineDetailProps> = ({
  tab,
  machine,
  isLoading,
  machineId,
  dataType,
  setDataType,
  activeTab,
  setActiveTab,
  registryPicklist,
  doDeleteMachine,
  doUpdateMachineDetails,
  doUpdateNickname,
  detailsMode,
  setDetailsMode,
  idEval,
  evaluations,
  evaluationByIdEval,
  isLoadingEvaluations,
  isLoadingEvaluationByIdEval,
  dateValues,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { mutate } = useSWRConfig()
  const axios = getAxios()
  const lang = useLanguage()

  const isReportTab = tab && tab === TimelineTab.Report

  const [address, setAddress] = useState<string>()
  const [suggestedAddress, setSuggestedAddress] = useState<SuggestedAddress[]>([
    {
      value: null,
      place_formatted: null,
      label: null,
    },
  ])

  const { trigger: doUpdateFavoriteById } = useSWRMutation(
    config.api.putFavoriteById(machineId),
    updateFavoriteById,
  )

  const setFavourite = async (isFavorite: boolean) => {
    try {
      await doUpdateFavoriteById({ isFavorite: !isFavorite })
      dispatch(fetchUserMachineMenu())
      mutate(config.api.getUserMachineById(machineId))
    } catch (error) {
      console.error(error)
    }
  }

  const renderItem = (feature: any): SuggestedAddress => {
    const { place_formatted } = feature.properties
    const name = feature.properties.name ? feature.properties.name : feature.properties.context.street.name
    return {
      value: `${name}, ${place_formatted}`,
      place_formatted: place_formatted,
      label: (
        <Row>
          <Col span={24} style={{ fontWeight: 'bold' }}>
            {name}
          </Col>
          <Col style={{ fontSize: '12px' }}>{place_formatted}</Col>
        </Row>
      ),
    }
  }

  const getSuggestedAddress = useCallback(
    debounce(async (addressParam: string, language: string): Promise<void | ApiError> => {
      try {
        if (addressParam) {
          const response = await axios.get<{ features: SuggestedAddressResponse[] }>(
            config.api.getSuggestedAddress(addressParam, language),
          )

          let formattedResult: SuggestedAddress[] = []
          if (!response) {
            setSuggestedAddress([])
          } else {
            setSuggestedAddress([])
            formattedResult = response.data.features.map((feature) => renderItem(feature))
            setSuggestedAddress(formattedResult)
          }
        }
      } catch (error) {
        throw getApiError(error as AxiosError)
      }
    }, 500),
    [setSuggestedAddress],
  )

  useEffect(() => {
    getSuggestedAddress(address, lang)
  }, [address, lang, getSuggestedAddress, setSuggestedAddress])

  return isLoading ? (
    <Spin size="large" />
  ) : (
    <>
      {true && (
        <PageHeader
          backRoute={idEval ? routes.machineDetailType : routes.allMachines}
          classname={idEval ? 'evaluation' : null}
          machineId={machine.id}
          title={
            idEval ? (
              <Typography.Title level={1}>
                {evaluationByIdEval?.note ? evaluationByIdEval?.note : t('general.evalNr', { nr: idEval })}
              </Typography.Title>
            ) : (
              <>
                <FavouriteIcon
                  className="favourite-icon"
                  style={{
                    color: machine?.isFavourite ? '#FDCA40' : 'transparent',
                    cursor: 'pointer',
                  }}
                  onClick={() => setFavourite(machine?.isFavourite)}
                />
                <Typography.Title level={1} style={{ wordBreak: 'normal' }}>
                  {machine?.note ? machine?.note : machine?.name}
                </Typography.Title>
              </>
            )
          }
          subtitle={
            idEval ? (
              <>
                {dayjs(evaluationByIdEval?.begin).format(config.getMonthDayYearFormat())} -
                {dayjs(evaluationByIdEval?.end).format(config.getMonthDayYearFormat())}
                <br />
                <br />
                <span className="pdf-report-esmsn">
                  {machine?.product}: {machine?.name}
                </span>
              </>
            ) : (
              <div style={{ wordBreak: 'normal' }}>
                {machine?.product} {machine?.note ? machine?.name : ''}
              </div>
            )
          }
          extra={
            !idEval && (
              <Radio.Group
                style={{ display: 'flex' }}
                className="data-type-switch"
                buttonStyle="solid"
                onChange={(e) => {
                  setDataType(e.target.value)
                }}
                value={dataType}
              >
                <Tooltip placement="top" title={t('general.realtime')}>
                  <Radio.Button className="radio-realtime" value={MachineDataType.Realtime}>
                    <RealtimeIcon />
                  </Radio.Button>
                </Tooltip>
                {machine.product === ProductType.Ecometer ? (
                  <Tooltip placement="top" className="radio-evaluations" title={t('general.evaluations')}>
                    <Radio.Button value={MachineDataType.Evaluations}>
                      <ReportIcon />
                    </Radio.Button>
                  </Tooltip>
                ) : (
                  <Tooltip placement="top" className="radio-timeline" title={t('general.timeline')}>
                    <Radio.Button value={MachineDataType.Timeline}>
                      <TimelineIcon />
                    </Radio.Button>
                  </Tooltip>
                )}
              </Radio.Group>
            )
          }
        />
      )}

      {dataType === MachineDataType.Realtime && (
        <RealtimeData
          registryPicklist={registryPicklist}
          machine={machine}
          machineId={machineId}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          doUpdateMachineDetails={doUpdateMachineDetails}
          doUpdateNickname={doUpdateNickname}
          doDeleteMachine={doDeleteMachine}
          detailsMode={detailsMode}
          setDetailsMode={setDetailsMode}
          setAddress={setAddress}
          suggestedAddress={suggestedAddress}
          setSuggestedAddress={setSuggestedAddress}
        />
      )}

      {dataType === MachineDataType.Timeline && (
        <TimelineData
          tab={tab}
          machine={machine}
          registryPicklist={registryPicklist}
          doUpdateMachineDetails={doUpdateMachineDetails}
          doUpdateNickname={doUpdateNickname}
          doDeleteMachine={doDeleteMachine}
          detailsMode={detailsMode}
          setDetailsMode={setDetailsMode}
          setAddress={setAddress}
          suggestedAddress={suggestedAddress}
          setSuggestedAddress={setSuggestedAddress}
          dateValues={dateValues}
        />
      )}

      {dataType === MachineDataType.Evaluations && (
        <EvaluationData
          machine={machine}
          evaluations={evaluations}
          evaluationByIdEval={evaluationByIdEval}
          idEval={idEval}
          isLoadingEvaluations={isLoadingEvaluations}
          isLoadingEvaluationByIdEval={isLoadingEvaluationByIdEval}
          detailsMode={detailsMode}
          setDetailsMode={setDetailsMode}
          registryPicklist={registryPicklist}
          doUpdateNickname={doUpdateNickname}
          doDeleteMachine={doDeleteMachine}
          doUpdateMachineDetails={doUpdateMachineDetails}
          tab={tab}
        />
      )}
    </>
  )
}

export default MachineDetail
