import { config } from 'core'
import { getMapboxUUID } from 'core/localStore'
import dayjs from 'dayjs'
import { MachineDataTypeByPeriod, MachineMetricsDataType, MachineRealtimeDataType } from 'types/enums'

const homepage = process.env.REACT_APP_HOMEPAGE

export const apiProvider = function provider(baseUrl: string) {
  let lang: string = null
  const uuid = getMapboxUUID()

  return {
    api: {
      //server resources
      vocabulary: () => `${baseUrl}/label/{{lng}}`,
      //local resources
      localVocabulary: () => `${homepage}/translations/{{lng}}/labels.json`,

      healthCheck: () => `${baseUrl}/keepalive`,

      // user api
      login: () => `${baseUrl}/auth/login`,
      register: () => `${baseUrl}/auth/register`,
      changePwd: () => `${baseUrl}/auth/pwd`,
      forgotPwd: () => `${baseUrl}/auth/reset-pwd-req`,
      resetPwd: () => `${baseUrl}/auth/reset-pwd`,
      confirm: () => `${baseUrl}/auth/confirm`,

      getAdminLanguages: () => `${baseUrl}/admin/language`,
      getAdminLanguageById: (id: number) => `${baseUrl}/admin/language/${id}`,
      postAdminLanguage: () => `${baseUrl}/admin/language`,
      putAdminLanguageById: (id: number) => `${baseUrl}/admin/language/${id}`,
      deleteAdminLanguageById: (id: number) => `${baseUrl}/admin/language/${id}`,
      putMachineForUser: (code: string) => `${baseUrl}/machine/add/${code}`,

      getAdminCodes: () => `${baseUrl}/admin/code`,
      postAdminCode: () => `${baseUrl}/admin/code`,

      getAdminUsers: () => `${baseUrl}/admin/user`,
      getAdminUserById: (id: number) => `${baseUrl}/admin/user/${id}`,
      postAdminUser: () => `${baseUrl}/admin/user`,
      putAdminUserById: (id: number) => `${baseUrl}/admin/user/${id}`,
      deleteAdminUserById: (id: number) => `${baseUrl}/admin/user/${id}`,

      getAdminMachines: () => `${baseUrl}/admin/machine`,
      postAdminMachine: () => `${baseUrl}/admin/machine`,
      putAdminMachineById: (id: number) => `${baseUrl}/admin/machine/${id}`,

      getLanguagePickList: () => `${baseUrl}/picklist/language`,
      getRegistryPickList: () => `${baseUrl}/picklist/registry`,
      getLatestNewsArticle: (language = lang) => `${baseUrl}/news/${language || lang}/latest`,

      getAdminLabels: () => `${baseUrl}/admin/label`,
      getAdminLabelById: (id: number) => `${baseUrl}/admin/label/${id}`,
      postAdminLabel: () => `${baseUrl}/admin/label`,
      putAdminLabelById: (id: number) => `${baseUrl}/admin/label/${id}`,
      deleteAdminLabelById: (id: number) => `${baseUrl}/admin/label/${id}`,

      getUserMachineList: () => `${baseUrl}/machines/list`,
      getUserMachineMenu: () => `${baseUrl}/machines/menu`,

      getUserMachineById: (id: number) => `${baseUrl}/machine/${id}`,
      putFavoriteById: (id: number) => `${baseUrl}/machine/${id}/favorite`,
      postMachineRegistry: (id: number) => `${baseUrl}/machine/${id}/registry`,
      postMachineNickname: (id: number) => `${baseUrl}/machine/${id}/nick`,
      deleteMachineForUser: (id: number) => `${baseUrl}/machine/${id}`,
      getMachineEvaluationsById: (id: number) => `${baseUrl}/machine/${id}/evaluations`,
      getMachineEvaluationsByIdAndByIdEval: (id: number, ideval: number) =>
        `${baseUrl}/machine/${id}/evaluations/${ideval}`,

      getMachineLatestData: (id: number, type: MachineMetricsDataType) => {
        const t = [MachineMetricsDataType.Vthd, MachineMetricsDataType.Ithd].includes(type) ? 'thd' : type

        return `${baseUrl}/machine/${id}/data/${t}/latest`
      },

      getAllMachinesRealtimeSavings: () =>
        `${baseUrl}/machine/all/data/realtime/${MachineRealtimeDataType.Savings}`,

      getMachineRealtimeData: (id: number, type: MachineRealtimeDataType = MachineRealtimeDataType.All) =>
        `${baseUrl}/machine/${id}/data/realtime/${type}`,

      getMachineDataByPeriod: (id: number, type: MachineDataTypeByPeriod, begin: Date, end: Date) =>
        `${baseUrl}/machine/${id}/data/${type}/period/${dayjs(begin).utc().toDate().getTime()}/${dayjs(end)
          .utc()
          .toDate()
          .getTime()}`,

      getMachineEnergyData: (id: number, begin: Date, end: Date) =>
        `${baseUrl}/machine/${id}/data/energy-data/${dayjs(begin).utc().toDate().getTime()}/${dayjs(end)
          .utc()
          .toDate()
          .getTime()}`,

      getMachineDataByDay: (id: number, day: Date, type: MachineMetricsDataType) =>
        `${baseUrl}/machine/${id}/data/${type}/day/${dayjs(day).utc().toDate().getTime()}`,

      getCSVMachineDetails: (id: number, dimension: string, day: Date) =>
        `${baseUrl}/machine/${id}/csv/${dimension}/day/${dayjs(day).utc().toDate().getTime()}`,

      getMachineDataCSV: (dateFrom: Date, dateTo: Date) =>
        `${baseUrl}/admin/machine/csv/period/${dayjs(dateFrom).utc().toDate().getTime()}/${dayjs(dateTo)
          .utc()
          .toDate()
          .getTime()}`,

      deleteMachineDataCSV: (dateFrom: Date, dateTo: Date) =>
        `${baseUrl}/admin/machine/csv/period/${dayjs(dateFrom).utc().toDate().getTime()}/${dayjs(dateTo)
          .utc()
          .toDate()
          .getTime()}`,

      getSuggestedAddress: (address: string, language: string) =>
        `https://api.mapbox.com/search/geocode/v6/forward?q=${address}&language=${language}&session_token=$${uuid}&access_token=${config.mapboxToken}`,
      geocodeAllUserMachinesAddresses: () => `${baseUrl}/map/geocode/machine/all`,

      getTimelineWordDocument: (id: number, type: string, lang: string, start: string, end: string) =>
        `${baseUrl}/machine/${id}/report/${lang}/period/${start}/${end}/${type}`,
      getEvalWordDocument: (
        id: number,
        evalId: number,
        type: string,
        lang: string,
        start: string,
        end: string,
      ) => `${baseUrl}/machine/${id}/report/${lang}/eval/${evalId}/period/${start}/${end}/${type}`,
    },
    getLanguage: () => lang,
    setLanguage: (language: string): void => {
      lang = language
    },
  }
}
