import { adminSlice } from './slice'

export const {} = adminSlice.actions

export {
  fetchAdminLanguagesThunk as fetchAdminLanguages,
  fetchAdminLanguageByIdThunk as fetchAdminLanguageById,
  createAdminLanguageThunk as createAdminLanguage,
  updateAdminLanguageThunk as updateAdminLanguage,
  deleteAdminLanguageThunk as deleteAdminLanguage,
  fetchAdminLabelsThunk as fetchAdminLabels,
  fetchAdminLabelByIdThunk as fetchAdminLabelById,
  createAdminLabelThunk as createAdminLabel,
  updateAdminLabelThunk as updateAdminLabel,
  deleteAdminLabelThunk as deleteAdminLabel,
  fetchAdminCodesThunk as fetchAdminCodes,
  createAdminCodeThunk as createAdminCode,
  fetchAdminUsersThunk as fetchAdminUsers,
  fetchAdminUserByIdThunk as fetchAdminUserById,
  createAdminUserThunk as createAdminUser,
  updateAdminUserThunk as updateAdminUser,
  deleteAdminUserThunk as deleteAdminUser,
  fetchAdminMachinesThunk as fetchAdminMachines,
  createAdminMachineThunk as createAdminMachine,
  deleteAdminMachineThunk as deleteAdminMachine,
  updateAdminMachineThunk as updateAdminMachine,
} from './thunks'
