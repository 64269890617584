import { ChartData, Scale } from 'chart.js'
import dayjs from 'dayjs'
import { TFunction } from 'i18next'

import { config } from 'core'
import { getThemeColors } from 'core/chartThemeColors'
import { MachineMetricsDataType, ThemeType } from 'types/enums'

import type * as Store from 'types/store'

type Data =
  | {
      type: MachineMetricsDataType.Voltage
      data: Store.MachineDataByDayResponse<Store.MachineVoltageByDay>
    }
  | {
      type: MachineMetricsDataType.Current
      data: Store.MachineDataByDayResponse<Store.MachineCurrentByDay>
    }
  | {
      type: MachineMetricsDataType.Power
      data: Store.MachineDataByDayResponse<Store.MachinePowerByDay>
    }
  | {
      type: MachineMetricsDataType.PowerFactor
      data: Store.MachineDataByDayResponse<Store.MachinePowerFactorByDay>
    }
  | {
      type: MachineMetricsDataType.Vthd
      data: Store.MachineDataByDayResponse<Store.MachineVthdByDay>
    }
  | {
      type: MachineMetricsDataType.Ithd
      data: Store.MachineDataByDayResponse<Store.MachineIthdByDay>
    }
  | {
      type: MachineMetricsDataType.Energy
      data: Store.MachineDataByDayResponse<Store.MachineEnergyByDay>
    }

const getLabels = (dataPoints: Store.MachineByDayDataPoint[]) =>
  dataPoints.map(({ t }) => dayjs(t).format(config.getHoursFormat()))

export function getChartData(
  theme: ThemeType,
  props: Data,
  t: TFunction<'translation', undefined, 'translation'>,
): ChartData<'line', number[], string> {
  const colors = getThemeColors(theme)

  switch (props.type) {
    case MachineMetricsDataType.Voltage:
      return {
        labels: getLabels(props.data.data ?? []),
        datasets: [
          {
            label: t('machineDetail.voltageTimeline.v12Min'),
            data: (props.data.data ?? []).map((v) => v.v12Min),
            fill: '+2',
            borderWidth: 1,
            borderColor: colors.yellowBorderColor,
            backgroundColor: colors.yellowFillerColor,
            tension: 0.8,
            pointRadius: 0,
            hidden: false,
          },
          {
            label: t('machineDetail.voltageTimeline.v12'),
            data: (props.data.data ?? []).map((v) => v.v12),
            borderWidth: 1,
            borderColor: colors.yellowColor,
            backgroundColor: colors.yellowColor,
            pointStyle: 'circle',
            pointRadius: 3,
            pointHoverRadius: 5,
            tension: 0.8,
            hidden: false,
            fill: false,
          },
          {
            label: t('machineDetail.voltageTimeline.v12Max'),
            data: (props.data.data ?? []).map((v) => v.v12Max),
            borderWidth: 1,
            borderColor: colors.yellowBorderColor,
            backgroundColor: colors.yellowFillerColor,
            tension: 0.8,
            pointRadius: 0,
            hidden: false,
            fill: false,
          },
          {
            label: t('machineDetail.voltageTimeline.v23Min'),
            data: (props.data.data ?? []).map((v) => v.v23Min),
            fill: '+2',
            borderWidth: 1,
            borderColor: colors.blueBorderColor,
            backgroundColor: colors.blueFillerColor,
            tension: 0.8,
            pointRadius: 0,
            hidden: false,
          },
          {
            label: t('machineDetail.voltageTimeline.v23'),
            data: (props.data.data ?? []).map((v) => v.v23),
            borderWidth: 1,
            borderColor: colors.blueColor,
            backgroundColor: colors.blueColor,
            pointStyle: 'circle',
            pointRadius: 3,
            pointHoverRadius: 5,
            tension: 0.8,
            hidden: false,
            fill: false,
          },
          {
            label: t('machineDetail.voltageTimeline.v23Max'),
            data: (props.data.data ?? []).map((v) => v.v23Max),
            borderWidth: 1,
            borderColor: colors.blueBorderColor,
            backgroundColor: colors.blueFillerColor,
            tension: 0.8,
            pointRadius: 0,
            hidden: false,
            fill: false,
          },
          {
            label: t('machineDetail.voltageTimeline.v31Min'),
            data: (props.data.data ?? []).map((v) => v.v31Min),
            fill: '+2',
            borderWidth: 1,
            borderColor: colors.redBorderColor,
            backgroundColor: colors.redFillerColor,
            tension: 0.8,
            pointRadius: 0,
            hidden: false,
          },
          {
            label: t('machineDetail.voltageTimeline.v31'),
            data: (props.data.data ?? []).map((v) => v.v31),
            borderWidth: 1,
            borderColor: colors.redColor,
            backgroundColor: colors.redColor,
            pointStyle: 'circle',
            pointRadius: 3,
            pointHoverRadius: 5,
            tension: 0.8,
            hidden: false,
            fill: false,
          },
          {
            label: t('machineDetail.voltageTimeline.v31Max'),
            data: (props.data.data ?? []).map((v) => v.v31Max),
            borderWidth: 1,
            borderColor: colors.redBorderColor,
            backgroundColor: colors.redFillerColor,
            tension: 0.8,
            pointRadius: 0,
            hidden: false,
            fill: false,
          },
          {
            label: t('machineDetail.voltageTimeline.vOutMin'),
            data: (props.data.data ?? []).map((v) => v.vOutMin),
            fill: '+2',
            borderWidth: 1,
            borderColor: colors.blackOrWhiteBorderColor,
            backgroundColor: colors.blackOrWhiteFillerColor,
            tension: 0.8,
            pointRadius: 0,
            hidden: false,
          },
          {
            label: t('machineDetail.voltageTimeline.vOut'),
            data: (props.data.data ?? []).map((v) => v.vOut),
            borderWidth: 1,
            borderColor: colors.blackOrWhiteColor,
            backgroundColor: colors.blackOrWhiteColor,
            pointStyle: 'circle',
            pointRadius: 3,
            pointHoverRadius: 5,
            tension: 0.8,
            hidden: false,
            fill: false,
          },
          {
            label: t('machineDetail.voltageTimeline.vOutMax'),
            data: (props.data.data ?? []).map((v) => v.vOutMax),
            borderWidth: 1,
            borderColor: colors.blackOrWhiteBorderColor,
            backgroundColor: colors.blackOrWhiteFillerColor,
            tension: 0.8,
            pointRadius: 0,
            hidden: false,
            fill: false,
          },
        ],
      }
    case MachineMetricsDataType.Current:
      return {
        labels: getLabels(props.data.data ?? []),
        datasets: [
          {
            label: t('machineDetail.currentTimeline.i12Min'),
            data: (props.data.data ?? []).map((v) => v.irMin),
            fill: '+2',
            borderWidth: 1,
            borderColor: colors.yellowBorderColor,
            backgroundColor: colors.yellowFillerColor,
            tension: 0.8,
            pointRadius: 0,
            hidden: false,
          },
          {
            label: t('machineDetail.currentTimeline.i12'),
            data: (props.data.data ?? []).map((v) => v.ir),
            borderWidth: 1,
            borderColor: colors.yellowColor,
            backgroundColor: colors.yellowColor,
            pointStyle: 'circle',
            pointRadius: 3,
            pointHoverRadius: 5,
            tension: 0.8,
            hidden: false,
            fill: false,
          },
          {
            label: t('machineDetail.currentTimeline.i12Max'),
            data: (props.data.data ?? []).map((v) => v.irMax),
            borderWidth: 1,
            borderColor: colors.yellowBorderColor,
            backgroundColor: colors.yellowFillerColor,
            tension: 0.8,
            pointRadius: 0,
            hidden: false,
            fill: false,
          },
          {
            label: t('machineDetail.currentTimeline.i23Min'),
            data: (props.data.data ?? []).map((v) => v.isMin),
            fill: '+2',
            borderWidth: 1,
            borderColor: colors.blueBorderColor,
            backgroundColor: colors.blueFillerColor,
            tension: 0.8,
            pointRadius: 0,
            hidden: false,
          },
          {
            label: t('machineDetail.currentTimeline.i23'),
            data: (props.data.data ?? []).map((v) => v.is),
            borderWidth: 1,
            borderColor: colors.blueColor,
            backgroundColor: colors.blueColor,
            pointStyle: 'circle',
            pointRadius: 3,
            pointHoverRadius: 5,
            tension: 0.8,
            hidden: false,
            fill: false,
          },
          {
            label: t('machineDetail.currentTimeline.i23Max'),
            data: (props.data.data ?? []).map((v) => v.isMax),
            borderWidth: 1,
            borderColor: colors.blueBorderColor,
            backgroundColor: colors.blueFillerColor,
            tension: 0.8,
            pointRadius: 0,
            hidden: false,
            fill: false,
          },
          {
            label: t('machineDetail.currentTimeline.i31Min'),
            data: (props.data.data ?? []).map((v) => v.itMin),
            fill: '+2',
            borderWidth: 1,
            borderColor: colors.redBorderColor,
            backgroundColor: colors.redFillerColor,
            tension: 0.8,
            pointRadius: 0,
            hidden: false,
          },
          {
            label: t('machineDetail.currentTimeline.i31'),
            data: (props.data.data ?? []).map((v) => v.it),
            borderWidth: 1,
            borderColor: colors.redColor,
            backgroundColor: colors.redColor,
            pointStyle: 'circle',
            pointRadius: 3,
            pointHoverRadius: 5,
            tension: 0.8,
            hidden: false,
            fill: false,
          },
          {
            label: t('machineDetail.currentTimeline.i31Max'),
            data: (props.data.data ?? []).map((v) => v.itMax),
            borderWidth: 1,
            borderColor: colors.redBorderColor,
            backgroundColor: colors.redFillerColor,
            tension: 0.8,
            pointRadius: 0,
            hidden: false,
            fill: false,
          },
        ],
      }
    case MachineMetricsDataType.Power:
      return {
        labels: getLabels(props.data.data ?? []),
        datasets: [
          {
            label: t('machineDetail.powerTimeline.p12'),
            data: (props.data.data ?? []).map((v) => v.pr),
            borderWidth: 1,
            borderColor: colors.yellowColor,
            backgroundColor: colors.yellowColor,
            pointStyle: 'circle',
            pointRadius: 3,
            pointHoverRadius: 5,
            tension: 0.8,
            hidden: false,
            fill: false,
          },
          {
            label: t('machineDetail.powerTimeline.p23'),
            data: (props.data.data ?? []).map((v) => v.ps),
            borderWidth: 1,
            borderColor: colors.blueColor,
            backgroundColor: colors.blueColor,
            pointStyle: 'circle',
            pointRadius: 3,
            pointHoverRadius: 5,
            tension: 0.8,
            hidden: false,
            fill: false,
          },
          {
            label: t('machineDetail.powerTimeline.p31'),
            data: (props.data.data ?? []).map((v) => v.pt),
            borderWidth: 1,
            borderColor: colors.redColor,
            backgroundColor: colors.redColor,
            pointStyle: 'circle',
            pointRadius: 3,
            pointHoverRadius: 5,
            tension: 0.8,
            hidden: false,
            fill: false,
          },
        ],
      }

    case MachineMetricsDataType.PowerFactor:
      return {
        labels: getLabels(props.data.data ?? []),
        datasets: [
          {
            label: t('machineDetail.powerFactorTimeline.PF1'),
            data: (props.data.data ?? []).map((v) => v.pfr),
            borderWidth: 1,
            borderColor: colors.yellowColor,
            backgroundColor: colors.yellowColor,
            pointStyle: 'circle',
            pointRadius: 3,
            pointHoverRadius: 5,
            tension: 0.8,
            hidden: false,
            fill: false,
          },
          {
            label: t('machineDetail.powerFactorTimeline.PF2'),
            data: (props.data.data ?? []).map((v) => v.pfs),
            borderWidth: 1,
            borderColor: colors.blueColor,
            backgroundColor: colors.blueColor,
            pointStyle: 'circle',
            pointRadius: 3,
            pointHoverRadius: 5,
            tension: 0.8,
            hidden: false,
            fill: false,
          },
          {
            label: t('machineDetail.powerFactorTimeline.PF3'),
            data: (props.data.data ?? []).map((v) => v.pft),
            borderWidth: 1,
            borderColor: colors.redColor,
            backgroundColor: colors.redColor,
            pointStyle: 'circle',
            pointRadius: 3,
            pointHoverRadius: 5,
            tension: 0.8,
            hidden: false,
            fill: false,
          },
        ],
      }

    case MachineMetricsDataType.Vthd:
      return {
        labels: getLabels(props.data.data ?? []),
        datasets: [
          {
            label: t('machineDetail.powerFactorTimeline.VTHD1'),
            data: (props.data.data ?? []).map((v) => v.vuthd),
            borderWidth: 1,
            borderColor: colors.yellowColor,
            backgroundColor: colors.yellowColor,
            pointStyle: 'circle',
            pointRadius: 3,
            pointHoverRadius: 5,
            tension: 0.8,
            hidden: false,
            fill: false,
          },
          {
            label: t('machineDetail.powerFactorTimeline.VTHD2'),
            data: (props.data.data ?? []).map((v) => v.vvthd),
            borderWidth: 1,
            borderColor: colors.blueColor,
            backgroundColor: colors.blueColor,
            pointStyle: 'circle',
            pointRadius: 3,
            pointHoverRadius: 5,
            tension: 0.8,
            hidden: false,
            fill: false,
          },
          {
            label: t('machineDetail.powerFactorTimeline.VTHD3'),
            data: (props.data.data ?? []).map((v) => v.vwthd),
            borderWidth: 1,
            borderColor: colors.redColor,
            backgroundColor: colors.redColor,
            pointStyle: 'circle',
            pointRadius: 3,
            pointHoverRadius: 5,
            tension: 0.8,
            hidden: false,
            fill: false,
          },
        ],
      }

    case MachineMetricsDataType.Ithd:
      return {
        labels: getLabels(props.data.data ?? []),
        datasets: [
          {
            label: t('machineDetail.powerFactorTimeline.ithd12'),
            data: (props.data.data ?? []).map((v) => v.iuthd),
            borderWidth: 1,
            borderColor: colors.yellowColor,
            backgroundColor: colors.yellowColor,
            pointStyle: 'circle',
            pointRadius: 3,
            pointHoverRadius: 5,
            tension: 0.8,
            hidden: false,
            fill: false,
          },
          {
            label: t('machineDetail.powerFactorTimeline.ithd23'),
            data: (props.data.data ?? []).map((v) => v.ivthd),
            borderWidth: 1,
            borderColor: colors.blueColor,
            backgroundColor: colors.blueColor,
            pointStyle: 'circle',
            pointRadius: 3,
            pointHoverRadius: 5,
            tension: 0.8,
            hidden: false,
            fill: false,
          },
          {
            label: t('machineDetail.powerFactorTimeline.ithd31'),
            data: (props.data.data ?? []).map((v) => v.iwthd),
            borderWidth: 1,
            borderColor: colors.redColor,
            backgroundColor: colors.redColor,
            pointStyle: 'circle',
            pointRadius: 3,
            pointHoverRadius: 5,
            tension: 0.8,
            hidden: false,
            fill: false,
          },
        ],
      }

    case MachineMetricsDataType.Energy:
      return {
        labels: getLabels(props.data.data ?? []),
        datasets: [
          {
            label: t('machineDetail.powerFactorTimeline.enAttOut'),
            data: (props.data.data ?? []).map((v) => v.enAttTotOut),
            borderWidth: 1,
            borderColor: colors.yellowColor,
            backgroundColor: colors.yellowColor,
            pointStyle: 'circle',
            pointRadius: 3,
            pointHoverRadius: 5,
            tension: 0.8,
            hidden: false,
            fill: false,
          },
          {
            label: t('machineDetail.powerFactorTimeline.enReacOut'),
            data: (props.data.data ?? []).map((v) => v.enReacTotOut),
            borderWidth: 1,
            borderColor: colors.blueColor,
            backgroundColor: colors.blueColor,
            pointStyle: 'circle',
            pointRadius: 3,
            pointHoverRadius: 5,
            tension: 0.8,
            hidden: false,
            fill: false,
          },
          {
            label: t('machineDetail.powerFactorTimeline.enReaiOut'),
            data: (props.data.data ?? []).map((v) => v.enReaiTotOut),
            borderWidth: 1,
            borderColor: colors.redColor,
            backgroundColor: colors.redColor,
            pointStyle: 'circle',
            pointRadius: 3,
            pointHoverRadius: 5,
            tension: 0.8,
            hidden: false,
            fill: false,
          },
        ],
      }

    default:
      throw new Error('Invalid chart type')
  }
}

export const getChartOptions = (theme: ThemeType) => {
  const colors = getThemeColors(theme)

  return {
    responsive: true,
    maintainAspectRatio: false,
    cubicInterpolationMode: 'monotone',
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      filler: {
        propagate: false,
      },
      'samples-filler-analyser': {
        target: 'chart-analyser',
      },
    },
    scales: {
      x: {
        color: colors.tickColor,
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          callback: function (this: Scale, val: number, index: number) {
            return index % 2 === 0 ? this.getLabelForValue(val) : ''
          },
          maxRotation: 0,
          minRotation: 0,
        },
      },
      y: {
        color: colors.tickColor,
        border: {
          display: false,
        },
        grid: {
          color: colors.gridColor,
        },
      },
    },
  }
}
