import React from 'react'
import { Popup } from 'react-map-gl'
import { Card, Typography, Carousel } from 'antd'
import * as Store from 'types/store'
import { useTranslation } from 'react-i18next'
import { ProductType, ThemeType } from 'types/enums'
import { Link } from 'react-router-dom'
import routes from 'config/routes'
import { ReactComponent as ArrowIcon } from 'assets/icon/arrow-icon.svg'
import { ReactComponent as FavouriteIcon } from 'assets/icon/favourite-icon.svg'
import styles from './styles.module.scss'
import classNames from 'classnames'
import { useTheme } from 'providers/ThemeProvider'
import { themes } from './themes'
import { fetchUserMachineMenu, setMachineFavorite } from 'store/machines'
import { useAppDispatch } from 'store'
import useSWRMutation from 'swr/mutation'
import { updateFavoriteById } from 'containers/MachineDetail'
import { config } from 'core'
import dayjs from 'dayjs'

interface Props {
  machines: Store.MachineWithRealtime[]
  popupInfo: Store.MachineGeocode
  onClose: () => void
}

const PopupMap: React.FC<Props> = ({ machines, popupInfo, onClose }) => {
  const { theme, getStyles } = useTheme()
  const themeStyles = getStyles(themes)
  const isDark = theme === ThemeType.Dark

  const machineCardClass = classNames(styles['machine-card'], themeStyles.theme, 'machine-card')
  const popupWrapperClass = classNames(styles['popup-wrapper'], themeStyles.theme, 'popup-wrapper')
  const carouselWrapperClass = classNames(
    styles['carousel-wrapper'],
    isDark ? 'carousel-dark' : 'carousel-light',
    'carousel-wrapper',
  )

  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const isValidNumber = (input: unknown): input is number => {
    return typeof input === 'number' && !Number.isNaN(input) && Number.isFinite(input)
  }

  const getMachineTitle = (machine: Store.MachineWithRealtime) => {
    return (
      <Typography.Title
        level={5}
        ellipsis={{
          rows: 1,
          tooltip: (
            <Typography.Text style={{ color: 'white' }}>
              {machine?.note ? machine?.note : machine?.name}
            </Typography.Text>
          ),
        }}
      >
        {machine?.note ? machine?.note : machine?.name}
      </Typography.Title>
    )
  }

  const setFavourite = async (machine: Store.MachineWithRealtime, isFavorite: boolean) => {
    const { trigger: doUpdateFavoriteById } = useSWRMutation(
      config.api.putFavoriteById(machine?.id),
      updateFavoriteById,
    )

    try {
      await doUpdateFavoriteById({ isFavorite: !isFavorite })
      dispatch(fetchUserMachineMenu())
      dispatch(setMachineFavorite({ id: machine.id, isFavorite: !isFavorite }))
    } catch (error) {
      console.error(error)
    }
  }

  const renderMachineCard = (machine: Store.MachineWithRealtime) => {
    const showSavingLabel =
      machine?.product === ProductType.Ecostab || machine?.product === ProductType.Ecometer

    const savingsPerc = isValidNumber(machine?.realtimeData?.savingLifetimePerc)
      ? machine.product === ProductType.Sterostab
        ? t('general.online')
        : machine.realtimeData.savingLifetimePerc.toFixed(2) + ' %'
      : ''

    const lastReset = machine?.realtimeData?.lastReset && dayjs(machine.realtimeData.lastReset)

    return (
      <Link to={routes.machineDetail.url.replace(':id', machine?.id.toString())}>
        <Card className={machineCardClass} bordered={false}>
          <div className="top-wrapper">
            <div className="title-wrapper">
              <div className="title-wrapper__left">
                <FavouriteIcon
                  className="favourite-icon"
                  style={{ color: machine?.isFavourite ? '#FDCA40' : 'transparent', cursor: 'pointer' }}
                  onClick={(event) => {
                    setFavourite(machine, machine?.isFavourite)
                    event.stopPropagation()
                    event.preventDefault()
                  }}
                />
                {getMachineTitle(machine)}
              </div>
            </div>
            <div className="name-wrapper">
              {machine?.note && (
                <>
                  <Typography.Text className="name">{machine?.name}</Typography.Text>
                  {'-'}
                </>
              )}

              <Typography.Text className="serial">{machine?.serial}</Typography.Text>
            </div>
            <Typography.Text className="card-subtitle">{machine?.sector}</Typography.Text>
          </div>

          <div className="content-wrapper">
            {savingsPerc && (
              <Typography.Text className="card-value">
                {`${showSavingLabel ? t('general.savingLifetime') + ': ' : ''}${savingsPerc}`}
              </Typography.Text>
            )}

            <div className="bottom-bar-wrapper">
              <Typography.Paragraph className="card-date">
                {t('general.since')}{' '}
                {lastReset?.isValid() ? lastReset.format(config.getDateTimeFormat()) : '-'}
              </Typography.Paragraph>
              <ArrowIcon />
            </div>
          </div>
        </Card>
      </Link>
    )
  }

  return (
    <Popup
      className={popupWrapperClass}
      anchor="top"
      maxWidth="260px"
      longitude={Number(popupInfo?.coordinates.longitude)}
      latitude={Number(popupInfo?.coordinates.latitude)}
      onClose={onClose}
    >
      {machines.length > 1 ? (
        <div className={carouselWrapperClass}>
          <Carousel swipeToSlide={true}>{machines.map((machine) => renderMachineCard(machine))} </Carousel>
        </div>
      ) : (
        renderMachineCard(machines[0])
      )}
    </Popup>
  )
}

export default PopupMap
