import type * as Api from 'types/api'
import type * as Store from 'types/store'

export const mapMachineVthdByDay = ({
  vuthd,
  vvthd,
  vwthd,
}: Api.MachineVthdByDay): Store.MachineVthdByDay => ({
  vuthd,
  vvthd,
  vwthd,
})

export default mapMachineVthdByDay
