import type * as Api from 'types/api'
import type * as Store from 'types/store'

export const mapMachine = ({
  id,
  at,
  serial,
  product,
  name,
  ip,
  tz,
  realTimeVersion,
  sector,
  country,
  city,
  province,
  address,
}: Api.AdminMachine): Store.AdminMachine => ({
  id,
  at,
  serial,
  product,
  name,
  ip,
  tz,
  realTimeVersion,
  sector,
  country,
  city,
  province,
  address,
})

export default mapMachine
