import { createAsyncThunk } from '@reduxjs/toolkit'

import * as K from './constants'
import * as localStore from 'core/localStore'
import * as userApi from 'api/user'

import type * as Api from 'types/api'
import type * as Store from 'types/store'

interface ApiErrorMessage {
  message: string
}

export const loginThunk = createAsyncThunk(K.LOGIN, async (payload: Api.LoginRequest) => {
  const response = (await userApi.login(payload)) as Api.LoginResponse
  localStore.saveUser(response)
  const user: Store.User = {
    email: payload.username,
  }

  return user
})

export const registerThunk = createAsyncThunk<
  Api.RegisterResponse,
  Api.RegisterRequest,
  { rejectValue: ApiErrorMessage }
>(K.REGISTER, async (payload: Api.RegisterRequest, thunkApi) => {
  try {
    const response = (await userApi.register(payload)) as Api.RegisterResponse
    return response
  } catch (error) {
    return thunkApi.rejectWithValue(error.data as ApiErrorMessage)
  }
})

export const logoutThunk = createAsyncThunk(K.LOGOUT, async () => {
  localStore.clearUser()
})
