import React from 'react'
import { useTranslation } from 'react-i18next'

import { useFetchTimelineData } from 'hooks'
import TimelineChartBox from 'components/TimelineChartBox'
import { MachineDataTypeByPeriod, MachineMetricsDataType } from 'types/enums'
import getSwrFetcher from 'api/swr-fetcher'
import useSWR from 'swr'
import { mapMachineMetricsByPeriod } from 'services'
import { config } from 'core'

interface Props {
  tab?: string
  machineId: number
  day: Date
}

const ithdByPeriodFetcher = getSwrFetcher({ method: 'GET' }, mapMachineMetricsByPeriod)

export const IthdTimelineChart: React.FC<Props> = ({ machineId, day, tab }) => {
  const { t } = useTranslation()
  const { data, isLoading, error } = useFetchTimelineData(machineId, day, MachineMetricsDataType.Ithd)

  const {
    data: periodData,
    isLoading: periodIsLoading,
    error: periodError,
  } = useSWR(
    config.api.getMachineDataByPeriod(machineId, MachineDataTypeByPeriod.ITHD, day, day),
    ithdByPeriodFetcher,
  )

  if (isLoading || error || periodIsLoading || periodError) {
    // TODO: better error handling and loading
    return null
  }

  const { max, min, avg } = periodData?.data

  return (
    <TimelineChartBox
      tab={tab}
      type={MachineMetricsDataType.Ithd}
      data={data}
      machineId={machineId}
      summary={
        // TODO: verify labels and add translations and correct values
        [
          {
            description: t('timelineCharts.max'),
            value: max,
            unit: t('metricUnit.percentage'),
          },
          {
            description: t('timelineCharts.min'),
            value: min,
            unit: t('metricUnit.percentage'),
          },
          {
            description: t('timelineCharts.avg'),
            value: avg,
            unit: t('metricUnit.percentage'),
          },
        ]
      }
    />
  )
}

export default IthdTimelineChart
