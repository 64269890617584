import { changeLanguage, config } from 'core'
import { getLanguage } from 'core/localStore'
import { createContext } from 'react'

export interface LanguageState {
  language: string
  changeLanguage: (language: string) => Promise<void>
}

export const LanguageContext = createContext<LanguageState>({
  language: getLanguage(),
  changeLanguage: (language: string) => changeLanguage(language).then(() => config.setLanguage(language)),
})
