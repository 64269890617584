import React from 'react'
import { useFetchMachineLatestData } from 'hooks'
import RealtimeChartBox from 'components/RealtimeChartBox'
import { MachineMetricsDataType } from 'types/enums'

interface Props {
  machineId: number
}

export const VthdChartBox: React.FC<Props> = ({ machineId }) => {
  const {
    data: latestData,
    isLoading,
    error,
  } = useFetchMachineLatestData(machineId, MachineMetricsDataType.Vthd)

  if (isLoading || error) {
    // TODO: better error handling and loading
    return null
  }

  return (
    <div className="chart-box">
      <RealtimeChartBox type={MachineMetricsDataType.Vthd} metrics={latestData} />
    </div>
  )
}

export default VthdChartBox
