import { createSlice } from '@reduxjs/toolkit'

import * as Store from 'types/store'

import { STATE_KEY } from './constants'
import {
  createAdminLabelThunk,
  deleteAdminLabelThunk,
  fetchAdminLabelByIdThunk,
  fetchAdminLabelsThunk,
  fetchAdminLanguagesThunk,
  createAdminLanguageThunk,
  deleteAdminLanguageThunk,
  fetchAdminLanguageByIdThunk,
  updateAdminLanguageThunk,
  updateAdminLabelThunk,
  fetchAdminCodesThunk,
  createAdminCodeThunk,
  fetchAdminUsersThunk,
  createAdminUserThunk,
  fetchAdminUserByIdThunk,
  deleteAdminUserThunk,
  fetchAdminMachinesThunk,
  createAdminMachineThunk,
  deleteAdminMachineThunk,
  updateAdminMachineThunk,
} from './thunks'

export const initialState: Store.AdminState = {
  languages: [],
  loadingLanguages: false,

  translations: null,
  translationsCurrentPage: 0,
  translationsTotalPages: 0,
  translationsTotalItems: 0,
  loadingLabels: false,
  loadingError: null,

  codes: [],
  loadingCodes: false,
  codesCurrentPage: 0,
  codesTotalPages: 0,
  codesTotalItems: 0,

  users: [],
  loadingUsers: false,
  usersCurrentPage: 0,
  usersTotalPages: 0,
  usersTotalItems: 0,

  machines: [],
  loadingMachines: false,
  machinesCurrentPage: 0,
  machinesTotalItems: 0,
  machinesTotalPages: 0,
}

export const adminSlice = createSlice({
  name: STATE_KEY,
  initialState,
  reducers: {
    // NOTE: creates both action and reducer in one go
  },
  // Handle async thunk states in the extraReducers
  extraReducers: (builder) => {
    // FETCH LANGUAGES
    builder.addCase(fetchAdminLanguagesThunk.pending, (state) => {
      state.loadingLanguages = true
    })
    builder.addCase(fetchAdminLanguagesThunk.rejected, (state, action) => {
      state.loadingLanguages = false
      state.loadingError = action.error
    })
    builder.addCase(fetchAdminLanguagesThunk.fulfilled, (state, action) => {
      state.loadingLanguages = false
      state.languages = action.payload
    })

    // FETCH LANGUAGE BY ID
    builder.addCase(fetchAdminLanguageByIdThunk.pending, (state) => {
      state.loadingLanguages = true
    })
    builder.addCase(fetchAdminLanguageByIdThunk.rejected, (state, action) => {
      state.loadingLanguages = false
      state.loadingError = action.error
    })
    builder.addCase(fetchAdminLanguageByIdThunk.fulfilled, (state, action) => {
      state.loadingLanguages = false

      const result = action.payload
      let found = false
      const updated = state.languages.map((t) => {
        if (t.id === result.id) {
          found = true
          return result
        }

        return t
      })

      state.languages = found ? updated : [...updated, result]
    })

    // CREATE ADMIN LANGUAGE
    builder.addCase(createAdminLanguageThunk.pending, (state) => {
      state.loadingLanguages = true
    })
    builder.addCase(createAdminLanguageThunk.rejected, (state, action) => {
      state.loadingLanguages = false
      state.loadingError = action.error
    })
    builder.addCase(createAdminLanguageThunk.fulfilled, (state, action) => {
      state.loadingLanguages = false
      state.languages = [...state.languages, action.payload]
    })

    // UPDATE ADMIN LANGUAGE
    builder.addCase(updateAdminLanguageThunk.pending, (state) => {
      state.loadingLanguages = true
    })
    builder.addCase(updateAdminLanguageThunk.rejected, (state, action) => {
      state.loadingLanguages = false
      state.loadingError = action.error
    })
    builder.addCase(updateAdminLanguageThunk.fulfilled, (state, action) => {
      state.loadingLanguages = false

      const result = action.payload
      let found = false
      const updated = state.languages.map((t) => {
        if (t.id === result.id) {
          found = true
          return result
        }

        return t
      })

      state.languages = found ? updated : [...updated, result]
    })

    // DELETE ADMIN LANGUAGE
    builder.addCase(deleteAdminLanguageThunk.pending, (state) => {
      state.loadingLanguages = true
    })
    builder.addCase(deleteAdminLanguageThunk.rejected, (state, action) => {
      state.loadingLanguages = false
      state.loadingError = action.error
    })
    builder.addCase(deleteAdminLanguageThunk.fulfilled, (state, action) => {
      state.loadingLanguages = false

      const result = action.payload
      state.languages = state.languages.filter((t) => t.id !== result.id)
    })

    /**LABELS **/
    // FETCH LABELS
    builder.addCase(fetchAdminLabelsThunk.pending, (state) => {
      state.loadingLabels = true
    })
    builder.addCase(fetchAdminLabelsThunk.rejected, (state, action) => {
      state.loadingLabels = false
      state.loadingError = action.error
    })
    builder.addCase(fetchAdminLabelsThunk.fulfilled, (state, action) => {
      state.loadingLabels = false
      state.translations = action.payload.result
      state.translationsCurrentPage = action.payload.currentPage
      state.translationsTotalPages = action.payload.totalPages
      state.translationsTotalItems = action.payload.totalItems
    })

    // FETCH LABEL BY ID
    builder.addCase(fetchAdminLabelByIdThunk.pending, (state) => {
      state.loadingLabels = true
    })
    builder.addCase(fetchAdminLabelByIdThunk.rejected, (state, action) => {
      state.loadingLabels = false
      state.loadingError = action.error
    })
    builder.addCase(fetchAdminLabelByIdThunk.fulfilled, (state, action) => {
      state.loadingLabels = false

      const result = action.payload
      let found = false
      const updated = state.translations.map((t) => {
        if (t.labelId === result.labelId) {
          found = true
          return result
        }

        return t
      })

      state.translations = found ? updated : [...updated, result]
    })

    // CREATE ADMIN LABEL
    builder.addCase(createAdminLabelThunk.pending, (state) => {
      state.loadingLabels = true
    })
    builder.addCase(createAdminLabelThunk.rejected, (state, action) => {
      state.loadingLabels = false
      state.loadingError = action.error
    })
    builder.addCase(createAdminLabelThunk.fulfilled, (state, action) => {
      state.loadingLabels = false
      state.translations = [...state.translations, action.payload]
    })

    // UPDATE ADMIN LABEL
    builder.addCase(updateAdminLabelThunk.pending, (state) => {
      state.loadingLabels = true
    })
    builder.addCase(updateAdminLabelThunk.rejected, (state, action) => {
      state.loadingLabels = false
      state.loadingError = action.error
    })
    builder.addCase(updateAdminLabelThunk.fulfilled, (state, action) => {
      state.loadingLabels = false

      const result = action.payload
      let found = false
      const updated = state.translations.map((t) => {
        if (t.labelId === result.labelId) {
          found = true
          return result
        }

        return t
      })

      state.translations = found ? updated : [...updated, result]
    })

    // DELETE ADMIN LABEL
    builder.addCase(deleteAdminLabelThunk.pending, (state) => {
      state.loadingLabels = true
    })
    builder.addCase(deleteAdminLabelThunk.rejected, (state, action) => {
      state.loadingLabels = false
      state.loadingError = action.error
    })
    builder.addCase(deleteAdminLabelThunk.fulfilled, (state, action) => {
      state.loadingLabels = false

      const result = action.payload
      state.translations = state.translations.filter((t) => t.labelId !== result.labelId)
    })

    // FETCH CODES
    builder.addCase(fetchAdminCodesThunk.pending, (state) => {
      state.loadingCodes = true
    })
    builder.addCase(fetchAdminCodesThunk.rejected, (state, action) => {
      state.loadingCodes = false
      state.loadingError = action.error
    })
    builder.addCase(fetchAdminCodesThunk.fulfilled, (state, action) => {
      state.loadingCodes = false
      state.codes = action.payload.result
      state.codesCurrentPage = action.payload.currentPage
      state.codesTotalPages = action.payload.totalPages
      state.codesTotalItems = action.payload.totalItems
    })

    // CREATE ADMIN CODE
    builder.addCase(createAdminCodeThunk.pending, (state) => {
      state.loadingCodes = true
    })
    builder.addCase(createAdminCodeThunk.rejected, (state, action) => {
      state.loadingCodes = false
      state.loadingError = action.error
    })
    builder.addCase(createAdminCodeThunk.fulfilled, (state, action) => {
      state.loadingCodes = false
      state.codes = [...state.codes, action.payload]
    })

    // FETCH USERS
    builder.addCase(fetchAdminUsersThunk.pending, (state) => {
      state.loadingUsers = true
    })
    builder.addCase(fetchAdminUsersThunk.rejected, (state, action) => {
      state.loadingUsers = false
      state.loadingError = action.error
    })
    builder.addCase(fetchAdminUsersThunk.fulfilled, (state, action) => {
      state.loadingUsers = false
      state.users = action.payload.result
      state.usersCurrentPage = action.payload.currentPage
      state.usersTotalPages = action.payload.totalPages
      state.usersTotalItems = action.payload.totalItems
    })

    // CREATE ADMIN USER
    builder.addCase(createAdminUserThunk.pending, (state) => {
      state.loadingUsers = true
    })
    builder.addCase(createAdminUserThunk.rejected, (state, action) => {
      state.loadingUsers = false
      state.loadingError = action.error
    })
    builder.addCase(createAdminUserThunk.fulfilled, (state, action) => {
      state.loadingUsers = false
      state.users = [...state.users, action.payload]
    })

    // // FETCH USER BY ID
    builder.addCase(fetchAdminUserByIdThunk.pending, (state) => {
      state.loadingUsers = true
    })
    builder.addCase(fetchAdminUserByIdThunk.rejected, (state, action) => {
      state.loadingUsers = false
      state.loadingError = action.error
    })
    builder.addCase(fetchAdminUserByIdThunk.fulfilled, (state, action) => {
      state.loadingUsers = false
      const result = action.payload
      let found = false
      const updated = state.users.map((t) => {
        if (t.id === result.id) {
          found = true
          return result
        }

        return t
      })

      state.users = found ? updated : [...updated, result]
    })

    // // UPDATE ADMIN USER
    // builder.addCase(updateAdminUserThunk.pending, (state) => {
    //   state.loadingUsers = true
    // })
    // builder.addCase(updateAdminUserThunk.rejected, (state, action) => {
    //   state.loadingUsers = false
    //   state.loadingError = action.error
    // })
    // builder.addCase(updateAdminUserThunk.fulfilled, (state, action) => {
    //   state.loadingUsers = false

    //   const result = action.payload
    //   let found = false
    //   const updated = state.users.map((t) => {
    //     if (t.id === result.id) {
    //       found = true
    //       return result
    //     }

    //     return t
    //   })

    //   state.users = found ? updated : [...updated, result]
    // })

    // DELETE ADMIN USER
    builder.addCase(deleteAdminUserThunk.pending, (state) => {
      state.loadingUsers = true
    })
    builder.addCase(deleteAdminUserThunk.rejected, (state, action) => {
      state.loadingUsers = false
      state.loadingError = action.error
    })
    builder.addCase(deleteAdminUserThunk.fulfilled, (state, action) => {
      state.loadingUsers = false

      const result = action.payload
      state.users = state.users.filter((t) => t.id !== result.id)
    })

    // FETCH ADMIN MACHINE
    builder.addCase(fetchAdminMachinesThunk.pending, (state) => {
      state.loadingMachines = true
    })

    builder.addCase(fetchAdminMachinesThunk.fulfilled, (state, action) => {
      state.loadingMachines = false
      state.machines = action.payload.result
      state.machinesCurrentPage = action.payload.currentPage
      state.machinesTotalItems = action.payload.totalItems
      state.machinesTotalPages = action.payload.totalPages
    })

    builder.addCase(fetchAdminMachinesThunk.rejected, (state, action) => {
      state.loadingMachines = false
      state.loadingError = action.error
    })

    // CREATE ADMIN MACHINE
    builder.addCase(createAdminMachineThunk.pending, (state) => {
      state.loadingMachines = true
    })

    builder.addCase(createAdminMachineThunk.fulfilled, (state, action) => {
      state.loadingMachines = false
      state.machines = [...state.machines, action.payload]
    })
    builder.addCase(createAdminMachineThunk.rejected, (state, action) => {
      state.loadingMachines = false
      state.loadingError = action.error
    })

    // DELETE ADMIN MACHINE
    builder.addCase(deleteAdminMachineThunk.pending, (state) => {
      state.loadingMachines = true
    })

    builder.addCase(deleteAdminMachineThunk.fulfilled, (state, action) => {
      state.loadingMachines = false
      const result = action.payload
      state.machines = state.machines.filter((m) => m.id !== result.id)
    })

    builder.addCase(deleteAdminMachineThunk.rejected, (state, action) => {
      state.loadingMachines = false
      state.loadingError = action.error
    })

    // UPDATE ADMIN MACHINE
    builder.addCase(updateAdminMachineThunk.pending, (state) => {
      state.loadingMachines = true
    })
    builder.addCase(updateAdminMachineThunk.rejected, (state) => {
      state.loadingLabels = false
      state.loadingError = true
    })
    builder.addCase(updateAdminMachineThunk.fulfilled, (state, action) => {
      state.loadingMachines = false
      const result = action.payload
      let found = false

      const updated = state.machines.map((machine) => {
        if (machine.id === result.id) {
          found = true
          return result
        }

        return machine
      })
      state.machines = found ? updated : [...updated, result]
    })
  },
})

export default adminSlice
