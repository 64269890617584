// NOTE: uses the "reselect" library, internally
import { createSelector } from '@reduxjs/toolkit'

import { STATE_KEY } from './constants'

import type { RootState } from '../'

const selectSelf = (state: RootState) => state[STATE_KEY]

// Memoized selector
export const selectUser = createSelector(selectSelf, (appState) => appState.user)
export const selectUserError = createSelector(selectSelf, (appState) => appState.error)
export const selectUserState = createSelector(selectSelf, (appState) => appState.state)
export const selectLastLogin = createSelector(selectSelf, (appState) => appState.lastLogin)
