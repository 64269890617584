import type * as Api from 'types/api'
import type * as Store from 'types/store'

export const mapMachineIthdByDay = ({
  iuthd,
  ivthd,
  iwthd,
}: Api.MachineIthdByDay): Store.MachineIthdByDay => ({
  iuthd,
  ivthd,
  iwthd,
})

export default mapMachineIthdByDay
