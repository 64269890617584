import * as Api from 'types/api'
import * as Store from 'types/store'

export const mapMachineRealtimePowerFactorData = ({
  PowerFactorInputFaseR,
  PowerFactorInputFaseS,
  PowerFactorInputFaseT,
  PowerFactorInputEquivalente,
  PowerFactorOutputFaseU,
  PowerFactorOutputFaseV,
  PowerFactorOutputFaseW,
  PowerFactorOutputEquivalente,
}: Api.RealtimePowerFactorData): Store.RealtimePowerFactorData => ({
  powerFactorInputPhaseR: +PowerFactorInputFaseR,
  powerFactorInputPhaseS: +PowerFactorInputFaseS,
  powerFactorInputPhaseT: +PowerFactorInputFaseT,
  powerFactorInputEquivalent: +PowerFactorInputEquivalente,
  powerFactorOutputPhaseU: +PowerFactorOutputFaseU,
  powerFactorOutputPhaseV: +PowerFactorOutputFaseV,
  powerFactorOutputPhaseW: +PowerFactorOutputFaseW,
  powerFactorOutputEquivalent: +PowerFactorOutputEquivalente,
})

export default mapMachineRealtimePowerFactorData
