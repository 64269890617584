import React from 'react'
import useSWR from 'swr'

import { useFetchTimelineData } from 'hooks'
import { TimelineChartBox } from 'components/TimelineChartBox'
import { MachineDataTypeByPeriod, MachineMetricsDataType } from 'types/enums'
import { mapMachineEnergyMetricsByPeriod } from 'services'

import getSwrFetcher from 'api/swr-fetcher'
import { config } from 'core'

interface Props {
  tab?: string
  machineId: number
  day: Date
}

const energyByPeriodFetcher = getSwrFetcher({ method: 'GET' }, mapMachineEnergyMetricsByPeriod)

export const EnergyTimelineChart: React.FC<Props> = ({ machineId, day, tab }) => {
  const { data, isLoading, error } = useFetchTimelineData(machineId, day, MachineMetricsDataType.Energy)

  const {
    data: periodData,
    isLoading: periodIsLoading,
    error: periodError,
  } = useSWR(
    config.api.getMachineDataByPeriod(machineId, MachineDataTypeByPeriod.Energy, day, day),
    energyByPeriodFetcher,
  )

  if (isLoading || error || periodIsLoading || periodError) {
    // TODO: better error hanlding
    return null
  }

  return (
    <TimelineChartBox
      tab={tab}
      type={MachineMetricsDataType.Energy}
      data={data}
      machineId={machineId}
      summaryType={periodData?.data}
    />
  )
}

export default EnergyTimelineChart
