import * as Api from 'types/api'
import * as Store from 'types/store'

export const mapMachineRealtimeIthdData = ({
  ThdCorrenteIngressoAvg,
  ThdCorrenteIngressoFaseR,
  ThdCorrenteIngressoFaseS,
  ThdCorrenteIngressoFaseT,
  ThdCorrenteUscitaAvg,
  ThdCorrenteUscitaFaseU,
  ThdCorrenteUscitaFaseV,
  ThdCorrenteUscitaFaseW,
}: Api.RealtimeIthdData): Store.RealtimeIthdData => ({
  thdCurrentInAvg: +ThdCorrenteIngressoAvg,
  thdCurrentInPhaseR: +ThdCorrenteIngressoFaseR,
  thdCurrentInPhaseS: +ThdCorrenteIngressoFaseS,
  thdCurrentInPhaseT: +ThdCorrenteIngressoFaseT,
  thdCurrentOutAvg: +ThdCorrenteUscitaAvg,
  thdCurrentOutPhaseU: +ThdCorrenteUscitaFaseU,
  thdCurrentOutPhaseV: +ThdCorrenteUscitaFaseV,
  thdCurrentOutPhaseW: +ThdCorrenteUscitaFaseW,
})

export default mapMachineRealtimeIthdData
