import { useMemo } from 'react'
import useSWR from 'swr'

import getSwrFetcher from 'api/swr-fetcher'
import { config } from 'core'
import { mapAllMachinesRealTimeSavings } from 'services'
import { useAppSelector } from 'store'
import { selectLoadingMachinesList, selectMachinesList, selectMachinesLoadingError } from 'store/machines'

import type * as Api from 'types/api'
import type * as Store from 'types/store'

const allRealtimeFetcher = getSwrFetcher(
  { method: 'GET' },
  (data: Api.AllMachineRealTimeSavingsResponse): Store.AllMachineRealTimeSavingsResponse =>
    mapAllMachinesRealTimeSavings(data),
)

interface MachineRealtimeResult {
  machinesListLoading: boolean
  realtimeLoading: boolean
  machinesError?: unknown
  realtimeError?: unknown
  data?: undefined | Store.MachineWithRealtime[]
}

export const useMachinesListWithRealtime = () => {
  const machinesList = useAppSelector(selectMachinesList)
  const machinesListLoading = useAppSelector(selectLoadingMachinesList)
  const machinesError = useAppSelector(selectMachinesLoadingError)

  const {
    data: realtimeData,
    isLoading: realtimeLoading,
    error: realtimeError,
  } = useSWR(config.api.getAllMachinesRealtimeSavings(), allRealtimeFetcher, {
    refreshInterval: config.allMachinesRealtimePollingInterval,
  })

  return useMemo<MachineRealtimeResult>(() => {
    if (machinesError || realtimeError) {
      return {
        machinesListLoading: false,
        realtimeLoading: false,
        machinesError,
        realtimeError,
      }
    }

    if (machinesListLoading) {
      return {
        machinesListLoading: true,
        realtimeLoading,
      }
    }

    if (!Array.isArray(machinesList)) {
      return {
        machinesListLoading: false,
        realtimeLoading,
      }
    }

    const map = new Map<number, Store.MachineWithRealtime>(
      machinesList.map((machine) => [machine.id, { ...machine } as Store.MachineWithRealtime]),
    )

    if (!realtimeLoading && realtimeData?.metrics) {
      for (const [id, value] of Object.entries(realtimeData.metrics)) {
        const machine = map.get(+id)

        if (machine) {
          machine.realtimeData = value
        }
      }
    }

    return {
      machinesListLoading: false,
      realtimeLoading,
      data: Array.from(map.values()),
    }
  }, [machinesListLoading, realtimeLoading, realtimeError, machinesList, realtimeData, machinesError])
}

export default useMachinesListWithRealtime
