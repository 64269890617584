import React from 'react'
import { Link } from 'react-router-dom'

import { Card, Typography } from 'antd'
import { config } from 'core'
import { useTranslation } from 'react-i18next'
import { MachineDataType } from 'types/enums'

import type * as Store from 'types/store'

import classNames from 'classnames'
import routes from 'config/routes'
import dayjs from 'dayjs'
import styles from './styles.module.scss'
import { themes } from './themes'

import { ReactComponent as DashIcon } from 'assets/icon/more-icon.svg'
import { useTheme } from 'providers'
interface Props {
  evaluation: Store.MachineEvalutionItem
  machine: Store.MachineDetail
}

const EvaluationCard: React.FC<Props> = ({ evaluation, machine }) => {
  const { t } = useTranslation()
  const { getStyles } = useTheme()
  const themeStyles = getStyles(themes)

  const evaluationCardClass = classNames(styles['evaluation-card'], themeStyles.theme, 'evaluation-card')

  const getMachineTitle = () => {
    return (
      <Typography.Title level={5}>
        {evaluation?.note ? evaluation?.note : t('general.evalNr', { nr: evaluation.evalId })}
      </Typography.Title>
    )
  }

  return (
    <Link
      to={routes.evaluationDetail.url
        .replace(':id', machine.id.toString())
        .replace(':type', MachineDataType.Evaluations)
        .replace(':ideval', evaluation.evalId.toString())}
    >
      <Card className={evaluationCardClass} bordered={false}>
        <div className="top-wrapper">
          <div className="title-wrapper">{getMachineTitle()}</div>
          <div className="range-wrapper">
            <Typography.Text className="card-value">
              {dayjs(evaluation.begin).isValid() &&
                dayjs(evaluation.begin).format(config.getMonthDayYearFormat())}
            </Typography.Text>

            <DashIcon />

            <Typography.Text className="card-value">
              {dayjs(evaluation.end).isValid() &&
                dayjs(evaluation.end).format(config.getMonthDayYearFormat())}
            </Typography.Text>
          </div>
          <Typography.Paragraph className="card-date" style={{ margin: '0 auto ' }}>
            {evaluation.duration}
          </Typography.Paragraph>
        </div>

        <div className="content-wrapper">
          <div style={{ margin: 'auto' }}>
            <Typography.Text className="card-subtitle">{t(`evaluationCard.totalSaving`)}</Typography.Text>
          </div>

          <div className="bottom-bar-wrapper">
            <div className="box-value">
              <Typography.Text className="numeric">{evaluation.cumulativeSavingKwh}</Typography.Text>
              <Typography.Text className="unit">{t('metricUnit.energy')}</Typography.Text>
            </div>

            <div className="box-value">
              <Typography.Text className="numeric">{evaluation.cumulativeSavingPerc}</Typography.Text>
              <Typography.Text className="unit">{t('metricUnit.percentage')}</Typography.Text>
            </div>
          </div>
        </div>
      </Card>
    </Link>
  )
}

export default EvaluationCard
