import { createAsyncThunk } from '@reduxjs/toolkit'

import * as machinesApi from 'api/machines'
import { mapMachineList, mapMachineMenu } from 'services'

import type * as Api from 'types/api'

import * as K from './constants'

export const fetchUserMachineListThunk = createAsyncThunk(K.FETCH_USER_MACHINES_LIST, async () => {
  const data = (await machinesApi.getUserMachineList()) as Api.MachineResponse<Api.MachineListItem>
  const mapped = Array.isArray(data?.machines) ? data.machines.map(mapMachineList) : []
  return mapped
})

export const fetchUserMachineMenuThunk = createAsyncThunk(K.FETCH_USER_MACHINES_MENU, async () => {
  const data = (await machinesApi.getUserMachineMenu()) as Api.MachineResponse<Api.MachineMenuItem>
  const mapped = Array.isArray(data?.machines) ? data.machines.map(mapMachineMenu) : []
  return mapped
})
