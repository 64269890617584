import * as Api from 'types/api'
import * as Store from 'types/store'

export const mapMachineRealtimeVthdData = ({
  ThdTensioneIngressoAvg,
  ThdTensioneIngressoFaseR,
  ThdTensioneIngressoFaseS,
  ThdTensioneIngressoFaseT,
  ThdTensioneUscitaAvg,
  ThdTensioneUscitaFaseU,
  ThdTensioneUscitaFaseV,
  ThdTensioneUscitaFaseW,
}: Api.RealtimeVthdData): Store.RealtimeVthdData => ({
  thdTensionInAvg: +ThdTensioneIngressoAvg,
  thdTensionInPhaseR: +ThdTensioneIngressoFaseR,
  thdTensionInPhaseS: +ThdTensioneIngressoFaseS,
  thdTensionInPhaseT: +ThdTensioneIngressoFaseT,
  thdTensionOutAvg: +ThdTensioneUscitaAvg,
  thdTensionOutPhaseU: +ThdTensioneUscitaFaseU,
  thdTensionOutPhaseV: +ThdTensioneUscitaFaseV,
  thdTensionOutPhaseW: +ThdTensioneUscitaFaseW,
})

export default mapMachineRealtimeVthdData
