import React from 'react'
import { Tabs, TabsProps } from 'antd'
import { useTranslation } from 'react-i18next'

import { RealtimeTab, UserRoles } from 'types/enums'
import { getUserClaims } from 'core/localStore'
import { ProductType } from 'types/enums'

interface Props {
  onTabChange: (tabKey: RealtimeTab) => void
  activeTab: RealtimeTab
  machineType: ProductType
}

const getDefaultActiveKey = (machineType: ProductType) => {
  switch (machineType) {
    case ProductType.Ecostab:
      return RealtimeTab.Savings
    case ProductType.Sterostab:
      return RealtimeTab.Voltage
    case ProductType.Ecometer:
      return RealtimeTab.Dashboard
    default:
      return null
  }
}

export const RealtimeTabs: React.FC<Props> = ({ onTabChange, activeTab, machineType }) => {
  const { t } = useTranslation()
  const claims = getUserClaims()

  const { userRole } = claims

  let items: TabsProps['items']

  switch (machineType) {
    case ProductType.Ecostab:
      items = [
        {
          key: RealtimeTab.Savings,
          id: RealtimeTab.Savings,
          label: t('machineDetail.saving'),
        },
        {
          key: RealtimeTab.Voltage,
          id: RealtimeTab.Voltage,
          label: t('machineDetail.voltage'),
        },
        {
          key: RealtimeTab.Current,
          id: RealtimeTab.Current,
          label: t('machineDetail.current'),
        },
        {
          key: RealtimeTab.Power,
          id: RealtimeTab.Power,
          label: t('machineDetail.power'),
        },
        {
          key: RealtimeTab.PowerFactor,
          id: RealtimeTab.PowerFactor,
          label: t('machineDetail.powerFactor'),
        },
        {
          key: RealtimeTab.VTHD,
          id: RealtimeTab.VTHD,
          label: t('machineDetail.vthd'),
        },
        {
          key: RealtimeTab.ITHD,
          id: RealtimeTab.ITHD,
          label: t('machineDetail.ithd'),
        },
        {
          key: RealtimeTab.Energy,
          id: RealtimeTab.Energy,
          label: t('machineDetail.energy'),
        },
        userRole &&
          userRole === UserRoles.Admin && {
            key: RealtimeTab.Dashboard,
            id: RealtimeTab.Dashboard,
            label: t('machineDetail.dashboard'),
          },
      ]
      break

    case ProductType.Sterostab:
      items = [
        {
          key: RealtimeTab.Voltage,
          id: RealtimeTab.Voltage,
          label: t('machineDetail.voltage'),
        },
        {
          key: RealtimeTab.Current,
          id: RealtimeTab.Current,
          label: t('machineDetail.current'),
        },
        {
          key: RealtimeTab.Power,
          id: RealtimeTab.Power,
          label: t('machineDetail.power'),
        },
        {
          key: RealtimeTab.PowerFactor,
          id: RealtimeTab.PowerFactor,
          label: t('machineDetail.powerFactor'),
        },
        {
          key: RealtimeTab.VTHD,
          id: RealtimeTab.VTHD,
          label: t('machineDetail.vthd'),
        },
        {
          key: RealtimeTab.ITHD,
          id: RealtimeTab.ITHD,
          label: t('machineDetail.ithd'),
        },
        {
          key: RealtimeTab.Energy,
          id: RealtimeTab.Energy,
          label: t('machineDetail.energy'),
        },
        userRole &&
          userRole === UserRoles.Admin && {
            key: RealtimeTab.Dashboard,
            id: RealtimeTab.Dashboard,
            label: t('machineDetail.dashboard'),
          },
      ]
      break

    case ProductType.Ecometer:
      items = [
        {
          key: RealtimeTab.Savings,
          id: RealtimeTab.Savings,
          label: t('machineDetail.saving'),
        },
        userRole &&
          userRole === UserRoles.Admin && {
            key: RealtimeTab.Dashboard,
            id: RealtimeTab.Dashboard,
            label: t('machineDetail.dashboard'),
          },
      ]
      break
    default:
      items = []
  }

  return (
    <div className="realtime-tabs-wrapper">
      <Tabs
        className="realtime-tabs"
        defaultActiveKey={getDefaultActiveKey(machineType as ProductType)}
        activeKey={activeTab}
        items={items}
        onChange={onTabChange}
        renderTabBar={(props, TabNavList) => <TabNavList {...props} mobile={false} />}
      />
    </div>
  )
}

export default RealtimeTabs
