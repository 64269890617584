import React, { useEffect, useState } from 'react'
import useSWR from 'swr'
import dayjs from 'dayjs'
import { Spin } from 'antd'

import getSwrFetcher from 'api/swr-fetcher'
import type * as Store from 'types/store'
import { mapMachineEnergyByPeriod } from 'services'
import { config } from 'core'
import { MachineDetailsBoxMode } from 'types/enums'

import EvaluationDataCmp from 'components/EvaluationData'
import TimelineChartBox from 'containers/TimelineChartBox'
import { UpdateMachineDetailsProps } from 'containers/MachineDetail'

const energyByPeriodFetcher = getSwrFetcher({ method: 'GET' }, mapMachineEnergyByPeriod)

interface Props {
  machine: Store.MachineDetail
  idEval: number
  evaluations: Store.MachineEvalutionItem[]
  evaluationByIdEval?: Store.MachineEvalutionItem
  isLoadingEvaluations?: boolean
  isLoadingEvaluationByIdEval?: boolean
  doUpdateNickname?: (nickname: string, evalID?: string) => void
  doDeleteMachine?: () => void
  doUpdateMachineDetails?: (machineDetails: UpdateMachineDetailsProps) => void
  detailsMode?: MachineDetailsBoxMode
  setDetailsMode?: (
    detailsMode: MachineDetailsBoxMode | ((detailsMode: MachineDetailsBoxMode) => MachineDetailsBoxMode),
  ) => void
  registryPicklist: Store.RegistryPicklist
  tab?: string
}

const EvaluationData: React.FC<Props> = ({
  machine,
  idEval,
  evaluations,
  isLoadingEvaluations,
  evaluationByIdEval,
  isLoadingEvaluationByIdEval,
  doDeleteMachine,
  doUpdateNickname,
  detailsMode,
  setDetailsMode,
  registryPicklist,
  doUpdateMachineDetails,
  tab,
}) => {
  const endDate = evaluationByIdEval && dayjs(evaluationByIdEval.end).endOf('day').toDate()
  const startDate = evaluationByIdEval && dayjs(evaluationByIdEval.begin).startOf('day').toDate()

  const [start, setStart] = useState<Date>()
  const [end, setEnd] = useState<Date>()

  useEffect(() => {
    if (evaluationByIdEval) {
      const newEndDate = dayjs(endDate).subtract(31, 'day').toDate()
      setStart(newEndDate < startDate ? startDate : newEndDate)
      setEnd(endDate)
    }
  }, [evaluationByIdEval])

  const { data: timelineData, isLoading: timelineLoading } = useSWR(
    evaluationByIdEval && config.api.getMachineEnergyData(machine.id, start, end),
    energyByPeriodFetcher,
  )

  const evalChartHasNextDate =
    dayjs(endDate)
      .subtract(1, 'day')
      .diff(dayjs(timelineData?.data?.[timelineData?.data.length - 1]?.day), 'day') > 0
  const evalChartHasPrevDate = dayjs(startDate).diff(dayjs(timelineData?.data?.[0]?.day), 'day') < 0

  if (isLoadingEvaluations || isLoadingEvaluationByIdEval) {
    return <Spin size="large" />
  }

  return (
    <EvaluationDataCmp
      evaluations={evaluations}
      evaluationByIdEval={evaluationByIdEval}
      timelineLoading={timelineLoading}
      machine={machine}
      idEval={idEval}
      timelineData={timelineData}
      detailsMode={detailsMode}
      setDetailsMode={setDetailsMode}
      doUpdateNickname={doUpdateNickname}
      doDeleteMachine={doDeleteMachine}
      doUpdateMachineDetails={doUpdateMachineDetails}
      doUpdateMachineEvalDateRange={(operation: string) => {
        switch (operation) {
          case 'add':
            setStart(end)
            setEnd(dayjs(end).add(31, 'day').toDate())
            break
          case 'subtract':
            const newStartDate = dayjs(start).subtract(31, 'day').toDate()
            setEnd(start)
            setStart(newStartDate < startDate ? startDate : newStartDate)
            break
          default:
            break
        }
      }}
      registryPicklist={registryPicklist}
      tab={tab}
      evalChartHasNextDate={evalChartHasNextDate}
      evalChartHasPrevDate={evalChartHasPrevDate}
      tableExpandedRowRenderer={(record: Store.EnergyByPeriodDataPoint) => (
        <TimelineChartBox day={record.day} machineId={machine.id} tab={tab} />
      )}
    />
  )
}

export default EvaluationData
