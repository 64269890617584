import { isNumeric, toNumber } from 'core'
import * as Api from 'types/api'
import * as Store from 'types/store'

export const mapMachineRealtimeSavingsData = ({
  PotenzaAttivaOutAvg,
  EnergiaAttivaOutTotale,
  RisparmioLifetime,
  RisparmioLifetimePerc,
  Online,
  Version,
}: Api.RealtimeSavingsData): Store.RealtimeSavingsData => ({
  powerActiveOutAvg: isNumeric(PotenzaAttivaOutAvg) ? toNumber(PotenzaAttivaOutAvg) : null,
  energyActiveOutTotal: isNumeric(EnergiaAttivaOutTotale) ? toNumber(EnergiaAttivaOutTotale) : null,
  savingLifetime: isNumeric(RisparmioLifetime) ? toNumber(RisparmioLifetime) : null,
  savingLifetimePerc: isNumeric(RisparmioLifetimePerc) ? toNumber(RisparmioLifetimePerc) : null,
  online: Online,
  version: Version,
})

export default mapMachineRealtimeSavingsData
