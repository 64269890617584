import type * as Api from 'types/api'
import type * as Store from 'types/store'

import { mapDate } from './map-date'
import { mapMachineBox } from './map-machine-box'

export const mapMachineDetail = ({
  address,
  city,
  country,
  createdAt,
  id,
  isFavourite,
  currOrder,
  lastReset,
  name,
  note,
  product,
  province,
  sector,
  sn,
  currentBoxes,
  ecostabBoxes,
  energyAllBoxes,
  energyBoxes,
  ithdBoxes,
  powerBoxes,
  powerFactorBoxes,
  voltageBoxes,
  vthdBoxes,
  latestCollectedDataTs,
  oldestCollectedDataTs,
}: Api.MachineDetail): Store.MachineDetail => {
  return {
    address,
    city,
    country,
    createdAt: mapDate(createdAt),
    id: +id,
    isFavourite,
    currOrder,
    lastReset: mapDate(lastReset),
    name,
    note,
    product,
    province,
    sector,
    sn,
    currentBoxes: Array.isArray(currentBoxes) ? currentBoxes.map(mapMachineBox) : currentBoxes,
    ecostabBoxes: Array.isArray(ecostabBoxes) ? ecostabBoxes.map(mapMachineBox) : ecostabBoxes,
    energyAllBoxes: Array.isArray(energyAllBoxes) ? energyAllBoxes.map(mapMachineBox) : energyAllBoxes,
    energyBoxes: Array.isArray(energyBoxes) ? energyBoxes.map(mapMachineBox) : energyBoxes,
    ithdBoxes: Array.isArray(ithdBoxes) ? ithdBoxes.map(mapMachineBox) : ithdBoxes,
    powerBoxes: Array.isArray(powerBoxes) ? powerBoxes.map(mapMachineBox) : powerBoxes,
    powerFactorBoxes: Array.isArray(powerFactorBoxes)
      ? powerFactorBoxes.map(mapMachineBox)
      : powerFactorBoxes,
    voltageBoxes: Array.isArray(voltageBoxes) ? voltageBoxes.map(mapMachineBox) : voltageBoxes,
    vthdBoxes: Array.isArray(vthdBoxes) ? vthdBoxes.map(mapMachineBox) : vthdBoxes,
    latestCollectedDataTs: mapDate(latestCollectedDataTs),
    oldestCollectedDataTs: mapDate(oldestCollectedDataTs),
  }
}

export default mapMachineDetail
