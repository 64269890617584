import * as Api from 'types/api'
import * as Store from 'types/store'

export const mapMachineRealtimeVoltageData = ({
  TensioneInConcatenataFaseRS,
  TensioneInConcatenataFaseST,
  TensioneInConcatenataFaseTR,
  TensioneInConcatenataAvg,
  TensioneOutConcatenataFaseUV,
  TensioneOutConcatenataFaseVW,
  TensioneOutConcatenataFaseWU,
  TensioneOutConcatenataAvg,
  TensioneInFaseR,
  TensioneInFaseS,
  TensioneInFaseT,
  TensioneInAvg,
  TensioneOutFaseU,
  TensioneOutFaseV,
  TensioneOutFaseW,
  TensioneOutAvg,
}: Api.RealtimeVoltageData): Store.RealtimeVoltageData => ({
  tensionInConcatenatedPhaseRs: +TensioneInConcatenataFaseRS,
  tensionInConcatenatedFaseSt: +TensioneInConcatenataFaseST,
  tensionInConcatenatedFaseTr: +TensioneInConcatenataFaseTR,
  tensionInConcatenatedAvg: +TensioneInConcatenataAvg,
  tensionOutConcatenatedPhaseUv: +TensioneOutConcatenataFaseUV,
  tensionOutConcatenatedPhaseVw: +TensioneOutConcatenataFaseVW,
  tensionOutConcatenatedPhaseWu: +TensioneOutConcatenataFaseWU,
  tensionOutConcatenatedAvg: +TensioneOutConcatenataAvg,
  tensionInPhaseR: +TensioneInFaseR,
  tensionInPhaseS: +TensioneInFaseS,
  tensionInPhaseT: +TensioneInFaseT,
  tensionInAvg: +TensioneInAvg,
  tensionOutPhaseU: +TensioneOutFaseU,
  tensionOutPhaseV: +TensioneOutFaseV,
  tensionOutPhaseW: +TensioneOutFaseW,
  tensionOutAvg: +TensioneOutAvg,
})

export default mapMachineRealtimeVoltageData
