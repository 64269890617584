// NOTE: uses the "reselect" library, internally
import { createSelector } from '@reduxjs/toolkit'

import { STATE_KEY } from './constants'

import type { RootState } from '../'

const selectSelf = (state: RootState) => state[STATE_KEY]

// Memoized selectors
export const selectAdminLanguages = createSelector(selectSelf, (adminState) => adminState.languages)
export const selectLoadingAdminLanguages = createSelector(
  selectSelf,
  (adminState) => adminState.loadingLanguages,
)
export const selectAdminLoadingError = createSelector(selectSelf, (adminState) => adminState.loadingError)
export const selectLoadingAdminLabels = createSelector(selectSelf, (adminState) => adminState.loadingLabels)
export const selectAdminTranslations = createSelector(selectSelf, (adminState) => adminState.translations)
export const selectAdminTranslationsCurrentPage = createSelector(
  selectSelf,
  (adminState) => adminState.translationsCurrentPage,
)
export const selectAdminTranslationsTotalItems = createSelector(
  selectSelf,
  (adminState) => adminState.translationsTotalItems,
)
export const selectAdminTranslationsTotalPages = createSelector(
  selectSelf,
  (adminState) => adminState.translationsTotalPages,
)

/* ADMIN CODES */
export const selectAdminCodes = createSelector(selectSelf, (adminState) => adminState.codes)
export const selectLoadingAdminCodes = createSelector(selectSelf, (adminState) => adminState.loadingCodes)
export const selectAdminCodesCurrentPage = createSelector(
  selectSelf,
  (adminState) => adminState.codesCurrentPage,
)
export const selectAdminCodesTotalItems = createSelector(
  selectSelf,
  (adminState) => adminState.codesTotalItems,
)
export const selectAdminCodesTotalPages = createSelector(
  selectSelf,
  (adminState) => adminState.codesTotalPages,
)

/* ADMIN USERS */
export const selectAdminUsers = createSelector(selectSelf, (adminState) => adminState.users)
export const selectLoadingAdminUsers = createSelector(selectSelf, (adminState) => adminState.loadingUsers)
export const selectAdminUsersCurrentPage = createSelector(
  selectSelf,
  (adminState) => adminState.usersCurrentPage,
)
export const selectAdminUsersTotalItems = createSelector(
  selectSelf,
  (adminState) => adminState.usersTotalItems,
)
export const selectAdminUsersTotalPages = createSelector(
  selectSelf,
  (adminState) => adminState.usersTotalPages,
)

/* ADMIN MACHINES */
export const selectAdminMachines = createSelector(selectSelf, (adminState) => adminState.machines)
export const selectLoadingAdminMachines = createSelector(
  selectSelf,
  (adminState) => adminState.loadingMachines,
)
export const selectAdminMachinesCurrentPage = createSelector(
  selectSelf,
  (adminState) => adminState.machinesCurrentPage,
)
export const selectAdminMachinesTotalItems = createSelector(
  selectSelf,
  (adminState) => adminState.machinesTotalItems,
)
export const selectAdminMachinesTotalPages = createSelector(
  selectSelf,
  (adminState) => adminState.machinesTotalPages,
)
