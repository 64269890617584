import React from 'react'
import useSWR from 'swr'

import LangSelector from 'components/LanguageSelector'
import { config } from 'core'
import mapLanguagePickList from 'services/map-language-picklist'
import getSwrFetcher from 'api/swr-fetcher'

import type * as Api from 'types/api'
import type * as Store from 'types/store'
import { useChangeLanguage, useLanguage } from 'providers/LanguageProvider'

const langFetcher = getSwrFetcher(
  { method: 'GET' },
  (data: Api.LanguagePickListItem[]): Store.LanguagePickListItem[] =>
    Array.isArray(data) ? data.map(mapLanguagePickList) : data,
)

export const LanguageSelector: React.FC = () => {
  const selectedLanguage = useLanguage()
  const changeLanguage = useChangeLanguage()

  const { data: languages, isLoading, error } = useSWR(config.api.getLanguagePickList(), langFetcher)

  if (isLoading || error) {
    // TODO: better error handling?
    return null
  }

  const onLanguageChange = (language: string) => {
    if (selectedLanguage !== language) {
      changeLanguage(language).catch((error) => {
        console.error(error)
      })
    }
  }

  return (
    <LangSelector languages={languages} selectedLanguage={selectedLanguage} onChange={onLanguageChange} />
  )
}

export default LanguageSelector
