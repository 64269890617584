import * as Api from 'types/api'
import * as Store from 'types/store'

export const mapMachineRealtimeCurrentData = ({
  CorrenteInFaseR,
  CorrenteInFaseS,
  CorrenteInFaseT,
  CorrenteInAvg,
  CorrenteOutFaseU,
  CorrenteOutFaseV,
  CorrenteOutFaseW,
  CorrenteOutAvg,
  CosphiInFaseR,
  CosphiInFaseS,
  CosphiInFaseT,
  CosphiInAvg,
  CosphiOutFaseU,
  CosphiOutFaseV,
  CosphiOutFaseW,
  CosphiOutAvg,
  ThdCorrenteInFaseR,
  ThdCorrenteInFaseS,
  ThdCorrenteInFaseT,
  ThdCorrenteInAvg,
  ThdCorrenteOutFaseU,
  ThdCorrenteOutFaseV,
  ThdCorrenteOutFaseW,
  ThdCorrenteOutAvg,
}: Api.RealtimeCurrentData): Store.RealtimeCurrentData => ({
  currentInPhaseR: +CorrenteInFaseR,
  currentInPhaseS: +CorrenteInFaseS,
  currentInPhaseT: +CorrenteInFaseT,
  currentInAvg: +CorrenteInAvg,
  currentOutPhaseU: +CorrenteOutFaseU,
  currentOutPhaseV: +CorrenteOutFaseV,
  currentOutPhaseW: +CorrenteOutFaseW,
  currentOutAvg: +CorrenteOutAvg,
  cosPhiInPhaseR: +CosphiInFaseR,
  cosPhiInPhaseS: +CosphiInFaseS,
  cosPhiInPhaseT: +CosphiInFaseT,
  cosPhiInAvg: +CosphiInAvg,
  cosPhiOutPhaseU: +CosphiOutFaseU,
  cosPhiOutPhaseV: +CosphiOutFaseV,
  cosPhiOutPhaseW: +CosphiOutFaseW,
  cosPhiOutAvg: +CosphiOutAvg,
  thdCurrentInPhaseR: +ThdCorrenteInFaseR,
  thdCurrentInPhaseS: +ThdCorrenteInFaseS,
  thdCurrentInPhaseT: +ThdCorrenteInFaseT,
  thdCurrentInAvg: +ThdCorrenteInAvg,
  thdCurrentOutPhaseU: +ThdCorrenteOutFaseU,
  thdCurrentOutPhaseV: +ThdCorrenteOutFaseV,
  thdCurrentOutPhaseW: +ThdCorrenteOutFaseW,
  thdCurrentOutAvg: +ThdCorrenteOutAvg,
})

export default mapMachineRealtimeCurrentData
