import { Col, Row, Spin } from 'antd'
import React from 'react'

import classNames from 'classnames'
import EvaluationCard from 'components/EvaluationCard/EvaluationCard'
import { MachineDetailsBox } from 'components/MachineDetailsBox'
import { TimelineBarChart } from 'components/TimelineBarChart'
import DownloadTimelineDocument from 'containers/DownloadTimelineDocument'
import { UpdateMachineDetailsProps } from 'containers/MachineDetail'
import TimelineTable from 'containers/TimelineTable'
import { config } from 'core'
import { useFeature } from 'flagged'
import { useTheme } from 'providers/ThemeProvider'
import { MachineDetailsBoxMode, ProductType, TimelineTab } from 'types/enums'
import type * as Store from 'types/store'
import SavingBox from './SavingBox'
import styles from './styles.module.scss'
import { themes } from './themes'

interface Props {
  evaluations: Store.MachineEvalutionItem[]
  evaluationByIdEval?: Store.MachineEvalutionItem
  timelineData: Store.MachineEnergyByPeriod
  tableExpandedRowRenderer: (record: Store.EnergyByPeriodDataPoint) => React.ReactNode
  machine: Store.MachineDetail
  timelineLoading: boolean
  idEval: number
  doUpdateNickname?: (nickname: string, evalID?: string) => void
  doDeleteMachine?: () => void
  detailsMode?: MachineDetailsBoxMode
  setDetailsMode?: (
    detailsMode: MachineDetailsBoxMode | ((detailsMode: MachineDetailsBoxMode) => MachineDetailsBoxMode),
  ) => void
  registryPicklist: Store.RegistryPicklist
  doUpdateMachineDetails?: (machineDetails: UpdateMachineDetailsProps) => void
  tab?: string
  evalChartHasNextDate?: boolean
  evalChartHasPrevDate?: boolean
  doUpdateMachineEvalDateRange?: (operation: string) => void
}

export const EvaluationData: React.FC<Props> = ({
  evaluations,
  evaluationByIdEval,
  machine,
  idEval,
  timelineData,
  timelineLoading,
  tableExpandedRowRenderer,
  doDeleteMachine,
  doUpdateNickname,
  detailsMode,
  setDetailsMode,
  registryPicklist,
  doUpdateMachineDetails,
  tab,
  evalChartHasNextDate,
  evalChartHasPrevDate,
  doUpdateMachineEvalDateRange,
}) => {
  const enableReportDownload = useFeature('enableReport')

  const { getStyles } = useTheme()
  const themeStyles = getStyles(themes)
  const evaluationData = classNames(styles['evaluation-data'], themeStyles.theme, 'timeline-data')
  const lang = config.getLanguage()

  return (
    <section className={evaluationData}>
      <Row className="body-wrapper">
        <Col className="timeline-boxes-col">
          {idEval ? (
            <>
              <SavingBox
                value={evaluationByIdEval?.cumulativeSavingKwh}
                valuePerc={evaluationByIdEval?.cumulativeSavingPerc}
              />
              {enableReportDownload && evaluationByIdEval && (
                <DownloadTimelineDocument
                  machineId={machine.id}
                  evalId={idEval}
                  machineType={ProductType.Ecometer}
                  lang={lang}
                  start={evaluationByIdEval?.begin}
                  end={evaluationByIdEval?.end}
                />
              )}
              {timelineLoading ? (
                <Spin></Spin>
              ) : (
                !timelineLoading &&
                timelineData && (
                  <>
                    <TimelineBarChart
                      begin={timelineData.begin}
                      end={timelineData.end}
                      data={timelineData.data}
                      isEval={true}
                      machineId={machine.id}
                      evalChartHasNextDate={evalChartHasNextDate}
                      evalChartHasPrevDate={evalChartHasPrevDate}
                      doUpdateMachineEvalDateRange={doUpdateMachineEvalDateRange}
                      lang={lang}
                    />
                    <TimelineTable
                      data={timelineData.data}
                      expandedRowRender={tableExpandedRowRenderer}
                      tab={tab}
                    />
                  </>
                )
              )}
            </>
          ) : (
            <div className="cards-list">
              {evaluations &&
                evaluations.map((evaluation) => (
                  <EvaluationCard key={evaluation.evalId} evaluation={evaluation} machine={machine} />
                ))}
            </div>
          )}
        </Col>

        {tab !== TimelineTab.Report && (
          <Col className="details-box-col">
            <MachineDetailsBox
              isEval={true}
              evaluationByIdEval={evaluationByIdEval}
              machine={machine}
              doUpdateNickname={doUpdateNickname}
              doDeleteMachine={doDeleteMachine}
              detailsMode={detailsMode}
              setDetailsMode={setDetailsMode}
              registryPicklist={registryPicklist}
              doUpdateMachineDetails={doUpdateMachineDetails}
            />
          </Col>
        )}
      </Row>
    </section>
  )
}

export default EvaluationData
