import React, { useMemo } from 'react'
import { Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { useTranslation } from 'react-i18next'
import { CaretRightOutlined, CaretDownOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'

import { config, isNumeric, toNumber } from 'core'

import type * as Store from 'types/store'
import { useNumberFormat } from 'hooks'
import { ProductType, TimelineTab } from 'types/enums'
import { TFunction } from 'i18next'

interface Props {
  tab?: string
  data: Store.EnergyByPeriodDataPoint[]
  expandedRowRender: (record: Store.EnergyByPeriodDataPoint) => React.ReactNode
  machineType?: string
}

interface RecordType {
  day: Date
  date: string
  dailyEnergyKwh: string
  cumulativeEnergyKwh: string
  dailySavingKwh: string
  dailySavingPerc: string
  cumulativeSavingKwh: string
  cumulativeSavingPerc: string
  ku: string
}

interface SavingCOlumnDataProps {
  kwhValue: string
  percValue: string
  trans: TFunction<'translation', undefined, 'translation'>
}

export const SavingColumnData: React.FC<SavingCOlumnDataProps> = ({ kwhValue, percValue, trans }) => {
  return (
    <div className="saving-column-data">
      <span>
        {kwhValue}
        <span className="table-metric-unit"> {trans('table.kwh')}</span>
      </span>
      <span>
        {percValue}
        <span className="table-metric-unit"> {trans('table.perc')}</span>
      </span>
    </div>
  )
}

export const TimelineDesktopTable: React.FC<Props> = ({ tab, data, expandedRowRender, machineType }) => {
  const { t } = useTranslation()
  const formatNumber = useNumberFormat()

  const mappedData = useMemo<RecordType[]>(
    () =>
      data?.map(({ day, eIn, eInSum, eRis, eRisP, eRisSum, eRispSum, ku }) => ({
        day: day,
        date: dayjs(day).format(config.getDateFormat()),
        dailyEnergyKwh: isNumeric(eIn) ? formatNumber(eIn) : '',
        cumulativeEnergyKwh: isNumeric(eInSum) ? formatNumber(eInSum) : '',
        dailySavingKwh: isNumeric(eRis) ? formatNumber(eRis) : '',
        dailySavingPerc: isNumeric(eRisP) ? formatNumber(eRisP) : '',
        cumulativeSavingKwh: isNumeric(eRisSum) ? formatNumber(eRisSum) : '',
        cumulativeSavingPerc: isNumeric(eRispSum) ? formatNumber(eRispSum) : '',
        ku: isNumeric(ku) ? formatNumber(ku) : '',
      })),
    [data],
  )

  const columns: ColumnsType<RecordType> = useMemo(
    () => [
      {
        key: 'date',
        title: t('table.date'),
        dataIndex: 'date',
        width: 100,
      },
      {
        key: 'energy',
        title: t('table.energy'),
        dataIndex: 'energy',
        align: 'center',
        width: 250,
        children: [
          {
            key: 'dailyEnergyKwh',
            title: t('table.daily'),
            dataIndex: 'dailyEnergyKwh',
            align: 'center',
            render: (text) => (
              <div>
                <span>{text}</span>
                <span className="table-metric-unit"> {t('table.kwh')}</span>
              </div>
            ),
          },
          {
            key: 'cumulativeEnergyKwh',
            title: t('table.cumulative'),
            dataIndex: 'cumulativeEnergyKwh',
            align: 'center',
            render: (text) => (
              <div>
                <span>{text}</span>
                <span className="table-metric-unit"> {t('table.kwh')}</span>
              </div>
            ),
          },
        ],
      },
      machineType !== ProductType.Sterostab
        ? {
            key: 'saving',
            title: t('table.saving'),
            dataIndex: 'saving',
            align: 'center',
            children: [
              {
                key: 'dailySaving',
                title: t('table.daily'),
                dataIndex: 'dailySaving',
                align: 'center',
                render: (_, record) => (
                  <SavingColumnData
                    kwhValue={record.dailySavingKwh}
                    percValue={record.dailySavingPerc}
                    trans={t}
                  />
                ),
              },
              {
                key: 'cumulativeSaving',
                title: t('table.cumulative'),
                dataIndex: 'cumulativeSaving',
                align: 'center',
                render: (_, record) => (
                  <SavingColumnData
                    kwhValue={record.cumulativeSavingKwh}
                    percValue={record.cumulativeSavingPerc}
                    trans={t}
                  />
                ),
              },
            ],
          }
        : {},
    ],
    [t],
  )

  return (
    <Table
      dataSource={mappedData}
      columns={columns}
      rowKey="date"
      pagination={false}
      expandable={{
        defaultExpandAllRows: tab === TimelineTab.Report,
        expandIcon: ({ expanded, onExpand, record }) =>
          expanded ? (
            <CaretDownOutlined onClick={(e) => onExpand(record, e)} />
          ) : (
            <CaretRightOutlined onClick={(e) => onExpand(record, e)} />
          ),
        expandRowByClick: true,
        expandedRowRender:
          typeof expandedRowRender === 'function'
            ? (record: RecordType) =>
                expandedRowRender({
                  ...record,
                  eIn: isNumeric(record.dailyEnergyKwh) ? toNumber(record.dailyEnergyKwh) : null,
                  eInSum: isNumeric(record.cumulativeEnergyKwh) ? toNumber(record.cumulativeEnergyKwh) : null,
                  eRis: isNumeric(record.dailySavingKwh) ? toNumber(record.dailySavingKwh) : null,
                  eRisP: isNumeric(record.dailySavingPerc) ? toNumber(record.dailySavingPerc) : null,
                  eRisSum: isNumeric(record.cumulativeSavingKwh)
                    ? toNumber(record.cumulativeSavingKwh)
                    : null,
                  eRispSum: isNumeric(record.cumulativeSavingPerc)
                    ? toNumber(record.cumulativeSavingPerc)
                    : null,
                  ku: isNumeric(record.ku) ? toNumber(record.ku) : null,
                })
            : void 0,
      }}
    />
  )
}

export default TimelineDesktopTable
