import React, { useMemo, useState } from 'react'

import { LanguageContext, LanguageState } from './LanguageContext'
import { changeLanguage, config } from 'core'

export const LanguageProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [language, setLanguage] = useState<string>(config.getLanguage())

  const value = useMemo<LanguageState>(
    () => ({
      language,
      changeLanguage: (language: string) =>
        changeLanguage(language).then(() => {
          config.setLanguage(language)
          setLanguage(language)
        }),
    }),
    [language, setLanguage],
  )

  return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>
}

export default LanguageProvider
