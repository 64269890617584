import dayjs from 'dayjs'
import type * as Api from 'types/api'
import type * as Store from 'types/store'

export const mapMachineLatestPowerFactorData = ({
  bounds: { begin, end },
  cosphi,
}: Api.MachineLatestPowerFactorData): Store.MachineLatestPowerFactorData => ({
  bounds: { begin: dayjs(begin).toDate(), end: dayjs(end).toDate() },
  cosPhi: cosphi.map(({ t, cosPhiIn, cosPhiOut }) => ({ t: dayjs(t).toDate(), cosPhiIn, cosPhiOut })),
})

export default mapMachineLatestPowerFactorData
