import { theme } from 'antd'
import type { ThemeConfig } from 'antd/es/config-provider'

export const lightTheme: ThemeConfig = {
  algorithm: theme.defaultAlgorithm,
  token: {
    colorTextHeading: '#231F20',
  },
  components: {
    Menu: {
      colorItemText: 'rgba(0, 0, 0, 0.64)',
      colorItemTextSelected: '#231F20',
      colorItemBgSelected: 'rgba(0, 0, 0, 0.08)',
      colorItemBgHover: 'rgba(0, 0, 0, 0.04)',
    },
    Tabs: {
      colorPrimary: '#F5A32C',
      colorSplit: 'rgba(0, 0, 0, 0.12)',
    },
    Card: {
      boxShadow:
        '0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)',
    },
    Button: {
      colorBorder: 'rgba(0,0,0, 0.12)',
      colorBgContainer: 'rgb(251, 250, 248)',
    },
    Radio: {
      colorBgContainer: 'rgba(0, 0, 0, 0.08)',
    },
    Input: {
      colorBorder: 'rgba(0,0,0, 0.12)',
      colorBgContainer: 'rgb(251, 250, 248)',
      colorTextPlaceholder: 'rgba(0,0,0, 0.4)',
    },
    Select: {
      colorBorder: 'rgba(0,0,0, 0.12)',
      colorBgContainer: 'rgb(251, 250, 248)',
      controlItemBgActive: '#FCDEB3',
    },
    DatePicker: {
      colorBorder: 'rgba(0,0,0, 0.12)',
      colorBgContainer: 'rgb(251, 250, 248)',
      colorTextPlaceholder: 'rgba(0,0,0, 0.4)',
    },
    Checkbox: {
      colorBorder: 'rgba(0,0,0, 0.12)',
      colorText: 'rgba(0, 0, 0, 0.64)',
      colorBgContainer: 'rgb(251, 250, 248)',
    },
    Table: {
      colorBorderSecondary: 'rgba(0,0,0, 0.08)',
      colorBgContainer: 'rgb(255,255,255)',
      colorFillAlter: 'rgb(242,242,242)',
    },
  },
}
