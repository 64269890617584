import * as Api from 'types/api'
import * as Store from 'types/store'

export const mapMachineRealtimeEnergyData = ({
  EnergiaAttivaOutTotale,
  EnergiaReattivaInduttivaOutTotale,
  EnergiaReattivaCapacitivaOutTotale,
  EnergiaApparenteOutTotale,
  EnergiaAttivaInTotale,
  EnergiaReattivaInduttivaInTotale,
  EnergiaReattivaCapacitivaInTotale,
  EnergiaApparenteInTotale,
  EnergiaAttivaOut24H,
  EnergiaReattivaInduttivaOut24H,
  EnergiaReattivaCapacitivaOut24H,
  EnergiaApparenteOut24H,
  EnergiaAttivaIn24H,
  EnergiaReattivaInduttivaIn24H,
  EnergiaReattivaCapacitivaIn24H,
  EnergiaApparenteIn24H,
}: Api.RealtimeEnergyData): Store.RealtimeEnergyData => ({
  energyActiveOutTotal: +EnergiaAttivaOutTotale,
  energyReactiveInductiveOutTotal: +EnergiaReattivaInduttivaOutTotale,
  energyReactiveCapacitiveOutTotal: +EnergiaReattivaCapacitivaOutTotale,
  energyApparentOutTotal: +EnergiaApparenteOutTotale,
  energyActiveInTotal: +EnergiaAttivaInTotale,
  energyReactiveInductiveInTotal: +EnergiaReattivaInduttivaInTotale,
  energyReactiveCapacitiveInTotal: +EnergiaReattivaCapacitivaInTotale,
  energyApparentInTotal: +EnergiaApparenteInTotale,
  energyActiveOut24h: +EnergiaAttivaOut24H,
  energyReactiveInductiveOut24h: +EnergiaReattivaInduttivaOut24H,
  energyReactiveCapacitiveOut24h: +EnergiaReattivaCapacitivaOut24H,
  energyApparentOut24h: +EnergiaApparenteOut24H,
  energyActiveIn24h: +EnergiaAttivaIn24H,
  energyReactiveInductiveIn24h: +EnergiaReattivaInduttivaIn24H,
  energyReactiveCapacitiveIn24h: +EnergiaReattivaCapacitivaIn24H,
  energyApparentIn24h: +EnergiaApparenteIn24H,
})

export default mapMachineRealtimeEnergyData
