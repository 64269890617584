import React from 'react'
import type * as Store from 'types/store'
import { groupBoxesByLabel } from '../VoltageBox'
import { RealtimeInOutBox } from 'components/RealtimeInOutBox'
import { BoxType } from 'types/enums'
import { useTranslation } from 'react-i18next'

interface IthdBoxProps {
  boxes: Store.MachineBox[]
  realtimeData: Store.MachineRealTimeResponse<Store.RealtimeIthdData>
  realtimeIsLoading: boolean
  realtimeError: unknown
}

const IthdBox: React.FC<IthdBoxProps> = ({ boxes, realtimeData, realtimeError }) => {
  const { t } = useTranslation()
  if (realtimeError) {
    // TODO: better error handling
  }

  const IthdBoxBylabel = groupBoxesByLabel(boxes)

  const value = realtimeData?.metrics
    ? {
        inIthd: [
          realtimeData.metrics.thdCurrentInAvg,
          realtimeData.metrics.thdCurrentInPhaseR,
          realtimeData.metrics.thdCurrentInPhaseS,
          realtimeData.metrics.thdCurrentInPhaseT,
        ],
        outIthd: [
          realtimeData.metrics.thdCurrentOutAvg,
          realtimeData.metrics.thdCurrentOutPhaseU,
          realtimeData.metrics.thdCurrentOutPhaseV,
          realtimeData.metrics.thdCurrentOutPhaseW,
        ],
        unit: t('metricUnit.percentage'),
      }
    : null

  return (
    <div className="realtime-boxes">
      <RealtimeInOutBox
        box={IthdBoxBylabel && IthdBoxBylabel[BoxType.ITHD]}
        inValue={value?.inIthd}
        outValue={value?.outIthd}
        unit={value?.unit}
        title={BoxType.ITHD}
      />
    </div>
  )
}

export default IthdBox
