import { useContext } from 'react'
import { NewsContext } from './NewsContext'

export const useNewsVisible = () => {
  return useContext(NewsContext).newsVisible
}

export const useSetNewsVisibility = () => {
  return useContext(NewsContext).setNewsVisibility
}
