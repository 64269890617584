import { theme } from 'antd'
import type { ThemeConfig } from 'antd/es/config-provider'

export const darkTheme: ThemeConfig = {
  algorithm: theme.darkAlgorithm,
  token: {
    colorTextHeading: '#FBFAF8',
  },
  components: {
    Menu: {
      colorItemText: 'rgba(255, 255, 255, 0.64)',
      colorItemTextSelected: '#FBFAF8',
      colorItemBgSelected: 'rgba(0, 0, 0, 0.4)',
      colorItemBgHover: 'rgba(255, 255, 255, 0.06)',
    },
    Button: {
      colorBorder: 'rgba(255,255,255, 0.12)',
      colorBgContainer: 'rgb(35, 31, 32)',
    },
    Input: {
      colorBorder: 'rgba(255,255,255, 0.12)',
      colorBgContainer: 'rgb(35, 31, 32)',
      colorTextPlaceholder: 'rgba(255,255,255, 0.4)',
    },
    Select: {
      colorBorder: 'rgba(255,255,255, 0.12)',
      colorBgContainer: 'rgb(35, 31, 32)',
      colorBgElevated: 'rgb(20, 18, 18)',
      controlItemBgActive: '#69491E',
    },
    Popover: {
      colorBgElevated: 'rgb(20, 18, 18)',
    },
    Dropdown: {
      colorBgElevated: 'rgb(20, 18, 18)',
    },
    Tabs: {
      colorPrimary: '#F5A32C',
      colorSplit: 'rgba(25, 22, 22, 0.7)',
    },
    Card: {
      colorBgContainer: '#191616',
    },
    Radio: {
      colorPrimary: '#F5A32C',
      colorBgContainer: 'rgba(255, 255, 255, 0.08)',
    },
    DatePicker: {
      colorBorder: 'rgba(255,255,255, 0.12)',
      colorBgContainer: 'rgb(35, 31, 32)',
      colorBgElevated: 'rgb(20, 18, 18)',
      colorTextPlaceholder: 'rgba(255,255,255, 0.4)',
    },
    Checkbox: {
      colorBorder: 'rgba(255,255,255, 0.12)',
      colorText: 'rgba(255, 255, 255, 0.64)',
      colorBgContainer: 'rgb(35, 31, 32)',
    },
    Table: {
      colorBorderSecondary: 'rgba(255,255,255, 0.08)',
      colorBgContainer: 'rgb(25, 22, 22)',
      colorFillAlter: 'rgb(20, 18, 18)',
    },
  },
}
