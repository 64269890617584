import jwt_decode from 'jwt-decode'

import type * as Api from 'types/api'
import { UserRoles } from 'types/enums'
import type * as Store from 'types/store'
import { v4 as uuidv4 } from 'uuid'

const { localStorage } = window

const AUTH_USER = 'AUTH_USER'
const LANGUAGE = 'i18nextLng'
const UUID_MAPBOX = 'UUID_MAPBOX'

export const clearUser = async (): Promise<void> => {
  localStorage.removeItem(AUTH_USER)
  localStorage.removeItem(UUID_MAPBOX)
}

export const getTokens = async (): Promise<Store.Tokens> => {
  const json = localStorage.getItem(AUTH_USER)
  if (!json) {
    return null
  }

  return JSON.parse(json) as Store.Tokens
}

export const getAuthToken = async (): Promise<string> => {
  const user = await getTokens()
  return user?.token
}

export const saveUser = async (response: Api.LoginResponse): Promise<Store.Tokens> => {
  const { token } = response

  const tokens = {
    token,
  }

  //Save in storage
  localStorage.setItem(AUTH_USER, JSON.stringify(tokens))
  localStorage.setItem(UUID_MAPBOX, uuidv4())

  return tokens
}

export const getUserClaims = () => {
  const value = localStorage.getItem(AUTH_USER)
  const tokens = JSON.parse(value)
  const decoded = jwt_decode<any>(tokens.token)

  return {
    userRole: decoded['role'],
    email: decoded['sub'],
  }
}

export const getUserRole = (): UserRoles => {
  try {
    const userClaims = getUserClaims()
    return userClaims.userRole
  } catch {
    return null
  }
}

export const getLanguage = () => {
  const language = localStorage.getItem(LANGUAGE)
  return language
}

export const getMapboxUUID = () => {
  const uuid = localStorage.getItem(UUID_MAPBOX)
  return uuid
}
