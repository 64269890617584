import dayjs from 'dayjs'

import type * as Api from 'types/api'
import type * as Store from 'types/store'

import { mapMachineRealtimeCurrentData } from './map-realtime-current'
import { mapMachineRealtimeEnergyData } from './map-realtime-energy'
import { mapMachineRealtimeIthdData } from './map-realtime-ithd'
import { mapMachineRealtimePowerData } from './map-realtime-power'
import { mapMachineRealtimePowerFactorData } from './map-realtime-power-factor'
import { mapMachineRealtimeVoltageData } from './map-realtime-voltage'
import { mapMachineRealtimeVthdData } from './map-realtime-vthd'

export const mapMachineRealtimeAllResponse = ({
  lastReset,
  ts,
  currents,
  energy,
  ithd,
  power,
  powerfactor: powerFactor,
  voltages,
  vthd,
}: Api.MachineRealTimeAllResponse): Store.MachineRealTimeAllResponse => ({
  lastReset: (lastReset && dayjs(lastReset).toDate()) || null,
  ts: dayjs(ts).toDate(),
  currents: mapMachineRealtimeCurrentData(currents),
  energy: mapMachineRealtimeEnergyData(energy),
  ithd: mapMachineRealtimeIthdData(ithd),
  power: mapMachineRealtimePowerData(power),
  powerFactor: mapMachineRealtimePowerFactorData(powerFactor),
  voltages: mapMachineRealtimeVoltageData(voltages),
  vthd: mapMachineRealtimeVthdData(vthd),
})

export default mapMachineRealtimeAllResponse
