import React, { useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import useSWR, { useSWRConfig } from 'swr'
import { useTheme } from 'providers/ThemeProvider'
import { themes } from './themes'
import { config } from 'core'
import { fetchUserMachineMenu } from 'store/machines'
import { useAppDispatch } from 'store'
import { getUserClaims } from 'core/localStore'
import { mapMachineEvalution } from 'services'
import { RealtimeTab, MachineDataType, ProductType, UserRoles, MachineDetailsBoxMode } from 'types/enums'

import type * as Api from 'types/api'
import type * as Store from 'types/store'

import getSwrFetcher from 'api/swr-fetcher'
import mapMachineDetail from 'services/map-machine-detail'
import classNames from 'classnames'
import routes from 'config/routes'
import MachineDetail from 'containers/MachineDetail'
import styles from './styles.module.scss'
import mapRegistryPicklist from 'services/map-registry-picklist'
import useSWRMutation from 'swr/mutation'

interface UpdateMachineDetailsProps {
  address: string
  sector: string
}

const machineFetcher = getSwrFetcher(
  { method: 'GET' },
  (data: Api.MachineDetail): Store.MachineDetail => mapMachineDetail(data),
)

const getRegistryPickList = getSwrFetcher(
  { method: 'GET' },
  (data: Api.RegistryPicklist): Store.RegistryPicklist => mapRegistryPicklist(data),
)

const updateMachineDetailsMutation = getSwrFetcher<void, UpdateMachineDetailsProps>({ method: 'POST' })
const updaterNicknameMutation = getSwrFetcher<void, { nickname: string; evalID?: string }>({ method: 'POST' })
const deleteMachineMutation = getSwrFetcher<void, { machineId: number }>({ method: 'DELETE' })

const machineEvaluationsFetcher = getSwrFetcher(
  { method: 'GET' },
  (data: Api.EvaluationsResponse<Api.MachineEvalutionItem>): Store.MachineEvalutionItem[] =>
    Array.isArray(data?.evaluations) ? data.evaluations.map(mapMachineEvalution) : [],
)

const machineEvaluationsByIdEvalFetcher = getSwrFetcher(
  { method: 'GET' },
  (data: Api.EvaluationsResponse<Api.MachineEvalutionItem>): Store.MachineEvalutionItem =>
    mapMachineEvalution(data?.evaluations),
)

export const MachineDetailPage: React.FC = () => {
  const { getStyles } = useTheme()
  const themeStyles = getStyles(themes)
  const machineDetailClass = classNames(styles['machine-detail-page'], themeStyles.theme)
  const { mutate } = useSWRConfig()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [detailsMode, setDetailsMode] = useState<MachineDetailsBoxMode>(MachineDetailsBoxMode.View)

  const { userRole } = getUserClaims()

  const params = useParams()
  const id = +params['id']
  const type = params['type'] as MachineDataType
  const tab = params['tab'] as RealtimeTab
  const idEval = +params['ideval'] || null

  if (isNaN(id) || !isFinite(id) || id < 1) {
    return <Navigate to={routes.errorPage.url} />
  }

  const { data: machine, isLoading, error } = useSWR(config.api.getUserMachineById(id), machineFetcher)

  const { trigger: doUpdateMachineDetails } = useSWRMutation(
    config.api.postMachineRegistry(id),
    updateMachineDetailsMutation,
  )

  const { trigger: doUpdateNickname } = useSWRMutation(
    config.api.postMachineNickname(id),
    updaterNicknameMutation,
  )

  const { trigger: doDeleteMachine } = useSWRMutation(
    config.api.deleteMachineForUser(id),
    deleteMachineMutation,
  )

  const { data: evaluations, isLoading: isLoadingEvaluations } = useSWR(
    type === MachineDataType.Evaluations && config.api.getMachineEvaluationsById(id),
    machineEvaluationsFetcher,
  )

  const { data: evaluationByIdEval, isLoading: isLoadingEvaluationByIdEval } = useSWR(
    idEval !== null && config.api.getMachineEvaluationsByIdAndByIdEval(id, idEval),
    machineEvaluationsByIdEvalFetcher,
  )

  const { data: registryPicklist, error: registryPicklistError } = useSWR(
    config.api.getRegistryPickList,
    getRegistryPickList,
  )

  if (error || registryPicklistError) {
    // TODO: Error handling?
    // return <ErrorComponentExample />
  }

  if (isLoading) {
    return (
      <section className={machineDetailClass}>
        <MachineDetail
          isLoading={true}
          machineId={id}
          machine={null}
          registryPicklist={null}
          dataType={null}
          activeTab={null}
        ></MachineDetail>
      </section>
    )
  }

  if (!type || (type === MachineDataType.Realtime && !tab)) {
    // NOTE: by default redirect to the first tab, (realtime > savings)
    return (
      <Navigate
        to={routes.machineDetailTab.url
          .replace(':id', id.toString())
          .replace(':type', MachineDataType.Realtime)
          .replace(
            ':tab',
            machine && machine.product === ProductType.Sterostab ? RealtimeTab.Voltage : RealtimeTab.Savings,
          )}
        replace={true}
      />
    )
  }

  if (
    !Object.values(MachineDataType).includes(type) ||
    (type === MachineDataType.Realtime && !Object.values(RealtimeTab).includes(tab))
  ) {
    return <Navigate to={routes.notFoundPage.url} replace={true} />
  }

  return (
    <section className={machineDetailClass}>
      <MachineDetail
        tab={tab}
        registryPicklist={registryPicklist}
        machine={machine}
        evaluations={evaluations}
        evaluationByIdEval={evaluationByIdEval}
        isLoadingEvaluationByIdEval={isLoadingEvaluationByIdEval}
        isLoadingEvaluations={isLoadingEvaluations}
        isLoading={isLoading}
        machineId={id}
        dataType={type}
        idEval={idEval}
        detailsMode={
          (!machine.address || !machine.sector) && (userRole === UserRoles.Pro || userRole === UserRoles.User)
            ? MachineDetailsBoxMode.Edit
            : detailsMode
        }
        setDetailsMode={setDetailsMode}
        setDataType={(type: MachineDataType) => {
          navigate(
            routes.machineDetailTab.url
              .replace(':id', id.toString())
              .replace(':type', type)
              .replace('/:tab', ''),
          )
        }}
        activeTab={tab}
        setActiveTab={(tab: RealtimeTab) => {
          navigate(
            routes.machineDetailTab.url
              .replace(':id', id.toString())
              .replace(':type', type)
              .replace(':tab', tab),
          )
        }}
        doUpdateMachineDetails={async (formData) => {
          try {
            await doUpdateMachineDetails({
              address: formData.address,
              sector: formData.sector,
            })

            mutate(config.api.getUserMachineById(machine.id))
          } catch (error) {
            console.error(error)
          }
        }}
        doUpdateNickname={async (nickname: string, evalID?: string) => {
          try {
            await doUpdateNickname({ nickname, evalID })
            mutate(config.api.getUserMachineById(machine.id))
            mutate(config.api.getMachineEvaluationsById(id))
            mutate(config.api.getMachineEvaluationsByIdAndByIdEval(id, idEval))
            dispatch(fetchUserMachineMenu())
          } catch (error) {
            console.error(error)
          }
        }}
        doDeleteMachine={async () => {
          try {
            await doDeleteMachine({ machineId: machine.id })
            dispatch(fetchUserMachineMenu())
            navigate(routes.allMachines.url)
          } catch (error) {
            console.error(error)
          }
        }}
      />
    </section>
  )
}

export default MachineDetailPage
