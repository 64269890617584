import type { CircleLayer, SymbolLayer } from 'react-map-gl'
import { mapStyleConfig } from 'themes/common'

export const clusterLayer: CircleLayer = {
  id: 'clusters',
  type: 'circle',
  source: 'machines',
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': mapStyleConfig.clusterLayer.paint.circleColor,
    'circle-radius': mapStyleConfig.clusterLayer.paint.circleRadius,
  },
}

export const clusterCountLayer: SymbolLayer = {
  id: 'cluster-count',
  type: 'symbol',
  source: 'machines',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-size': mapStyleConfig.clusterCountLayer.layout.textSize,
  },
  paint: {
    'text-color': mapStyleConfig.clusterCountLayer.paint.textColor,
  },
}

export const unclusteredPointLayer: CircleLayer = {
  id: 'unclustered-point',
  type: 'circle',
  source: 'machines',
  filter: ['!', ['has', 'point_count']],
  paint: {
    'circle-color': mapStyleConfig.unclusteredPointLayer.paint.circleColor,
    'circle-radius': mapStyleConfig.unclusteredPointLayer.paint.circleRadius,
  },
}
