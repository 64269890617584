import React, { useEffect, useMemo, useState } from 'react'

import { Dropdown, Button, MenuProps, DropdownProps } from 'antd'

import type * as Store from 'types/store'
import { useTheme } from '../../providers'
import { ThemeType } from '../../themes/types'
import { GlobalOutlined } from '@ant-design/icons'

const MOBILE_WIDTH_BREAKPOINT = 768

interface Props {
  languages: Store.LanguagePickListItem[]
  selectedLanguage: string
  onChange: (picked: string) => void
}

const LanguageSelector: React.FC<Props> = ({ languages, selectedLanguage, onChange }) => {
  const { theme } = useTheme()
  const [isMobile, setIsMobile] = useState(window.innerWidth <= MOBILE_WIDTH_BREAKPOINT)

  const onLanguageChange: DropdownProps['menu']['onClick'] = (menuInfo) => {
    onChange(menuInfo.key)
  }

  const language = useMemo(() => {
    const selectedLang = languages.find((item) => item.iso.toLowerCase() === selectedLanguage)
    if (isMobile) {
      return selectedLang.iso
    } else {
      return selectedLang.name
    }
  }, [isMobile, selectedLanguage])

  const items: MenuProps['items'] = languages.map((lang) => ({
    key: lang.iso.toLowerCase(),
    label: <>{lang.name}</>,
  }))

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= MOBILE_WIDTH_BREAKPOINT)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <Dropdown
      menu={{ items, onClick: onLanguageChange, selectedKeys: [selectedLanguage.toLowerCase()] }}
      trigger={['click']}
      placement="bottomRight"
    >
      <Button className="language-btn" type="link" icon={<GlobalOutlined />}>
        {language}
      </Button>
    </Dropdown>
  )
}

export default LanguageSelector
