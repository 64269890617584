import React, { useState, useCallback } from 'react'
import { Button, TabsProps, Typography, notification } from 'antd'
import { useMount } from 'react-use'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { getUserClaims } from 'core/localStore'
import { ListMapView, UserRoles } from 'types/enums'
import { useAppDispatch } from 'store'
import { fetchUserMachineList } from 'store/machines'
import AllMachinesTabs from 'containers/AllMachinesTabs'
import PageHeader from 'components/PageHeader/PageHeader'
// import { useNewsVisible } from 'providers/NewsProvider'
// import NewsBlock from 'containers/NewsBlock'
import { useTheme } from 'providers/ThemeProvider'

import { themes } from './themes'

import StickyBox from 'react-sticky-box'

import { ReactComponent as AddIcon } from 'assets/icon/add-icon.svg'
import styles from './styles.module.scss'
import { NewMachineModal } from 'components/NewMachine'
import { config } from 'core'
import { getAxios } from 'api/apiService'
import { getAuthenticationHeader } from 'api/common'
import { useNavigate } from 'react-router-dom'
import routes from 'config/routes'

export const AllMachinesPage: React.FC = () => {
  // const newsVisibile = useNewsVisible()
  const navigate = useNavigate()
  const { getStyles } = useTheme()
  const themeStyles = getStyles(themes)
  const allMachinesClass = classNames(styles['home-page'], themeStyles.theme)
  const [modalMode, setModalMode] = useState<'closed' | 'create'>('closed')
  const axios = getAxios()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const getUserRole = (): UserRoles => {
    try {
      const userClaims = getUserClaims()
      return userClaims.userRole
    } catch {
      return null
    }
  }
  const role = getUserRole()

  useMount(() => {
    if (role) {
      dispatch(fetchUserMachineList())
    }
  })

  if (!role) {
    return null
  }

  const putMachineForUserHandler = async (code: string): Promise<{ id: number } | ApiError> => {
    try {
      const response = await axios.put<{ id: number }>(
        config.api.putMachineForUser(code),
        {},
        await getAuthenticationHeader(),
      )
      return response.data
    } catch (error) {
      notification.error({ message: t('messages.invalidInput'), placement: 'topLeft' })
    }
  }

  const handleCancel = useCallback(() => {
    setModalMode('closed')
  }, [setModalMode])

  const openAddModal = useCallback(() => {
    setModalMode('create')
  }, [setModalMode])

  const handleConfirm = useCallback(
    async (webcode: string) => {
      const id = await putMachineForUserHandler(webcode)
      if (id) {
        dispatch(fetchUserMachineList())
        setModalMode('closed')
        notification.success({ message: t('messages.newMachineAdded'), placement: 'topLeft' })
      }
    },
    [putMachineForUserHandler],
  )

  const renderTabBar: TabsProps['renderTabBar'] = (props, DefaultTabBar) => (
    <StickyBox offsetTop={0} className="render-tab-bar">
      <PageHeader
        title={<Typography.Title level={1}>{t('allMachines.title')}</Typography.Title>}
        extra={
          !(role === UserRoles.Admin || role === UserRoles.Tester) && (
            <Button
              className="add-machine-btn"
              size="large"
              type="primary"
              icon={<AddIcon className="btn-icon" />}
              onClick={openAddModal}
            >
              {t('general.newMachine')}
            </Button>
          )
        }
      />

      <DefaultTabBar {...props} />
    </StickyBox>
  )

  return (
    <section className={allMachinesClass}>
      <section className="app-container">
        <NewMachineModal
          open={modalMode !== 'closed'}
          handleCancel={handleCancel}
          handleConfirm={handleConfirm}
        />

        <AllMachinesTabs
          setDataType={(type: ListMapView) => {
            if (ListMapView.MapView === type) {
              navigate(routes.allMachines.url.concat(type))
            } else {
              navigate(routes.allMachines.url)
            }
          }}
          renderTabBar={renderTabBar}
        />
        {/* {newsVisibile && <NewsBlock cardClass="news-card--horizontal" />} */}
      </section>
    </section>
  )
}

export default AllMachinesPage
