import React from 'react'
import { Col, Row } from 'antd'

import MachineDetailsBox from 'components/MachineDetailsBox/MachineDetailsBox'
import RealtimeTabs from 'components/RealtimeTabs/RealtimeTabs'

import { PowerFactorChartBox } from 'containers/boxes/PowerFactorChartBox'
import { VoltageChartBox } from 'containers/boxes/VoltageChartBox'
import { CurrentChartBox } from 'containers/boxes/CurrentChartBox'
import { PowerFactorBox } from 'containers/boxes/PowerFactorBox'
import { PowerChartBox } from 'containers/boxes/PowerChartBox'
import { VthdChartBox } from 'containers/boxes/VthdChartBox'
import { IthdChartBox } from 'containers/boxes/IthdChartBox'
import { SavingsBox } from 'containers/boxes/SavingsBox'
import {
  RealtimeTab,
  ProductType,
  UserRoles,
  MachineMetricsDataType,
  MachineDetailsBoxMode,
} from 'types/enums'
import { VoltageBox } from 'containers/boxes/VoltageBox'
import { CurrentBox } from 'containers/boxes/CurrentBox'
import { EnergyBox } from 'containers/boxes/EnergyBox'
import { VthdBox } from 'containers/boxes/VthdBox'
import { DashboardBox } from 'containers/boxes/DashboardBox'

import type * as Store from 'types/store'
import IthdBox from 'containers/boxes/IthdBox/IthdBox'
import PowerBox from 'containers/boxes/PowerBox/PowerBox'
import { getUserClaims } from 'core/localStore'
import { SuggestedAddress, UpdateMachineDetailsProps } from 'containers/MachineDetail'
import EmptyChartBox from 'components/RealtimeChartBox/EmptyChartBox'

export interface MachineDetailProps {
  machine: Store.MachineDetail
  machineId: number
  activeTab: RealtimeTab
  setActiveTab: (tab: RealtimeTab) => void
  registryPicklist: Store.RegistryPicklist
  doUpdateMachineDetails?: (machineDetails: UpdateMachineDetailsProps) => void
  doUpdateNickname?: (nickname: string, evalID?: string) => void
  doDeleteMachine?: () => void
  detailsMode?: MachineDetailsBoxMode
  setDetailsMode?: (
    detailsMode: MachineDetailsBoxMode | ((detailsMode: MachineDetailsBoxMode) => MachineDetailsBoxMode),
  ) => void
  setAddress: (value: string) => void
  suggestedAddress: SuggestedAddress[]
  setSuggestedAddress: (SuggestedAddress: SuggestedAddress[]) => void
}

const activeBoxMap: {
  [tab in RealtimeTab]?:
    | undefined
    | null
    | React.FC<{ boxes: Store.MachineBox[]; machineId: number | null | undefined }>
} = {
  [RealtimeTab.Voltage]: VoltageBox,
  [RealtimeTab.Current]: CurrentBox,
  [RealtimeTab.Power]: PowerBox,
  [RealtimeTab.PowerFactor]: PowerFactorBox,
  [RealtimeTab.VTHD]: VthdBox,
  [RealtimeTab.ITHD]: IthdBox,
  [RealtimeTab.Energy]: EnergyBox,
}

const chartMap: { [tab in RealtimeTab]?: undefined | null | React.FC<{ machineId: number }> } = {
  [RealtimeTab.Savings]: VoltageChartBox,
  [RealtimeTab.Voltage]: VoltageChartBox,
  [RealtimeTab.Current]: CurrentChartBox,
  [RealtimeTab.Power]: PowerChartBox,
  [RealtimeTab.PowerFactor]: PowerFactorChartBox,
  [RealtimeTab.VTHD]: VthdChartBox,
  [RealtimeTab.ITHD]: IthdChartBox,
}

export const RealtimeData: React.FC<MachineDetailProps> = ({
  machine,
  activeTab,
  setActiveTab,
  registryPicklist,
  doDeleteMachine,
  doUpdateMachineDetails,
  doUpdateNickname,
  detailsMode,
  setDetailsMode,
  setAddress,
  suggestedAddress,
  setSuggestedAddress,
}) => {
  const claims = getUserClaims()
  const { userRole } = claims

  const machineHasRegistrySet = (machine: Store.MachineDetail): boolean => {
    return (!machine.address || !machine.sector) &&
      machine.product !== ProductType.Ecometer &&
      (userRole === UserRoles.Pro || userRole === UserRoles.User)
      ? false
      : true
  }

  const activeMachineMap: {
    [tab in RealtimeTab]?: undefined | null | Store.MachineBox[]
  } = {
    ...(machine?.product !== ProductType.Sterostab ? { [RealtimeTab.Savings]: machine?.ecostabBoxes } : {}),
    [RealtimeTab.Voltage]: machine?.voltageBoxes,
    [RealtimeTab.Current]: machine?.currentBoxes,
    [RealtimeTab.Power]: machine?.powerBoxes,
    [RealtimeTab.PowerFactor]: machine?.powerFactorBoxes,
    [RealtimeTab.VTHD]: machine?.vthdBoxes,
    [RealtimeTab.ITHD]: machine?.ithdBoxes,
    [RealtimeTab.Energy]: machine?.energyBoxes,
  }

  if (machine.product !== ProductType.Sterostab) {
    activeBoxMap[RealtimeTab.Savings] = SavingsBox
  }

  const ActiveBox = activeBoxMap[activeTab]
  const activeMachineBox = activeMachineMap[activeTab]

  const Chart = chartMap[activeTab]

  const emptyChartMap: { [tab in RealtimeTab]?: undefined | null | MachineMetricsDataType } = {
    [RealtimeTab.Savings]: MachineMetricsDataType.Voltage,
    [RealtimeTab.Voltage]: MachineMetricsDataType.Voltage,
    [RealtimeTab.Current]: MachineMetricsDataType.Current,
    [RealtimeTab.Power]: MachineMetricsDataType.Power,
    [RealtimeTab.PowerFactor]: MachineMetricsDataType.PowerFactor,
    [RealtimeTab.VTHD]: MachineMetricsDataType.Vthd,
    [RealtimeTab.ITHD]: MachineMetricsDataType.Ithd,
    [RealtimeTab.Energy]: MachineMetricsDataType.Energy,
  }

  const activeTabType = emptyChartMap[activeTab]

  const displayChart = () => {
    return machineHasRegistrySet(machine) ? (
      <Chart machineId={machine?.id} />
    ) : (
      <EmptyChartBox type={activeTabType} />
    )
  }

  return (
    <section className="realtime-data-wrapper">
      <RealtimeTabs
        activeTab={activeTab}
        onTabChange={setActiveTab}
        machineType={machine?.product as ProductType}
      />

      <Row className="body-wrapper">
        <Col className="realtime-boxes-col">
          <div className="tabs-content">
            {activeTab === RealtimeTab.Dashboard && userRole === UserRoles.Admin && (
              <DashboardBox machine={machine} />
            )}

            {ActiveBox && (
              <ActiveBox
                machineId={machineHasRegistrySet(machine) ? machine?.id : null}
                boxes={activeMachineBox}
              />
            )}

            {Chart && displayChart()}
          </div>
        </Col>

        <Col className="details-box-col">
          <MachineDetailsBox
            machine={machine}
            registryPicklist={registryPicklist}
            doUpdateMachineDetails={doUpdateMachineDetails}
            doUpdateNickname={doUpdateNickname}
            doDeleteMachine={doDeleteMachine}
            detailsMode={detailsMode}
            setDetailsMode={setDetailsMode}
            setAddress={setAddress}
            suggestedAddress={suggestedAddress}
            setSuggestedAddress={setSuggestedAddress}
          />
        </Col>
      </Row>
    </section>
  )
}

export default RealtimeData
