import React from 'react'

import { useFetchRealtimeData } from 'hooks'
import { MachineRealtimeDataType } from 'types/enums'
import type * as Store from 'types/store'
import IthdBoxCmp from 'components/boxes/IthdBox'

interface IthdBoxProps {
  boxes: Store.MachineBox[]
  machineId: number | null | undefined
}

const IthdBox: React.FC<IthdBoxProps> = ({ boxes, machineId }) => {
  const {
    data: realtimeData,
    isLoading: realtimeIsLoading,
    error: realtimeError,
  } = useFetchRealtimeData(machineId, MachineRealtimeDataType.Ithd)

  return (
    <div>
      <IthdBoxCmp
        boxes={boxes}
        realtimeData={realtimeData}
        realtimeError={realtimeError}
        realtimeIsLoading={realtimeIsLoading}
      />
    </div>
  )
}

export default IthdBox
