import useSWR, { BareFetcher, SWRResponse } from 'swr'

import getSwrFetcher from 'api/swr-fetcher'
import {
  mapMachineLatestVoltageData,
  mapMachineLatestCurrentData,
  mapMachineLatestPowerData,
  mapMachineLatestPowerFactorData,
  mapMachineLatestThdData,
} from 'services'
import { MachineMetricsDataType } from 'types/enums'

import type * as Api from 'types/api'
import type * as Store from 'types/store'
import { config } from 'core'

const thdFetcher = getSwrFetcher(
  {
    method: 'GET',
  },
  (data: Api.MachineLatestThdData): Store.MachineLatestThdData => mapMachineLatestThdData(data),
)

const fetchers = {
  [MachineMetricsDataType.Voltage]: getSwrFetcher(
    {
      method: 'GET',
    },
    (data: Api.MachineLatestVoltageData): Store.MachineLatestVoltageData => mapMachineLatestVoltageData(data),
  ),
  [MachineMetricsDataType.Current]: getSwrFetcher(
    {
      method: 'GET',
    },
    (data: Api.MachineLatestCurrentData): Store.MachineLatestCurrentData => mapMachineLatestCurrentData(data),
  ),
  [MachineMetricsDataType.Power]: getSwrFetcher(
    {
      method: 'GET',
    },
    (data: Api.MachineLatestPowerData): Store.MachineLatestPowerData => mapMachineLatestPowerData(data),
  ),
  [MachineMetricsDataType.PowerFactor]: getSwrFetcher(
    {
      method: 'GET',
    },
    (data: Api.MachineLatestPowerFactorData): Store.MachineLatestPowerFactorData =>
      mapMachineLatestPowerFactorData(data),
  ),
  [MachineMetricsDataType.Vthd]: thdFetcher,
  [MachineMetricsDataType.Ithd]: thdFetcher,
}

export const useFetchMachineLatestData = <
  T extends keyof typeof fetchers,
  R = Awaited<ReturnType<(typeof fetchers)[T]>>,
>(
  machineId: number,
  type: T,
): SWRResponse<
  R,
  ApiError,
  {
    refreshInterval: typeof config.realtimeDataPollingInterval
  }
> => {
  switch (type) {
    case MachineMetricsDataType.Voltage:
    case MachineMetricsDataType.Current:
    case MachineMetricsDataType.Power:
    case MachineMetricsDataType.PowerFactor:
    case MachineMetricsDataType.Vthd:
    case MachineMetricsDataType.Ithd:
      return useSWR(config.api.getMachineLatestData(machineId, type), fetchers[type] as BareFetcher<R>)
    default:
      throw new Error('Invalid type!')
  }
}

export default useFetchMachineLatestData
