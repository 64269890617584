import { getAxios } from './apiService'

import type * as Api from 'types/api'
import { getApiError, getAuthenticationHeader } from './common'
import { AxiosError } from 'axios'
import { config } from 'core'

const axios = getAxios()

export async function getUserMachineList(): Promise<Api.MachineResponse<Api.MachineListItem> | ApiError> {
  try {
    const response = await axios.get<Api.MachineResponse<Api.MachineListItem>>(
      config.api.getUserMachineList(),
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function getUserMachineMenu(): Promise<Api.MachineResponse<Api.MachineMenuItem> | ApiError> {
  try {
    const response = await axios.get<Api.MachineResponse<Api.MachineMenuItem>>(
      config.api.getUserMachineMenu(),
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}
