import React from 'react'
import { useTranslation } from 'react-i18next'

import { useFetchTimelineData } from 'hooks'
import { TimelineChartBox } from 'components/TimelineChartBox'
import { MachineDataTypeByPeriod, MachineMetricsDataType } from 'types/enums'
import getSwrFetcher from 'api/swr-fetcher'
import useSWR from 'swr'
import { mapMachineMetricsByPeriod } from 'services'
import { config } from 'core'

interface Props {
  tab?: string
  machineId: number
  day: Date
}

const currentByPeriodFetcher = getSwrFetcher({ method: 'GET' }, mapMachineMetricsByPeriod)

export const CurrentTimelineChart: React.FC<Props> = ({ machineId, day, tab }) => {
  const { t } = useTranslation()
  const { data, isLoading, error } = useFetchTimelineData(machineId, day, MachineMetricsDataType.Current)

  const {
    data: periodData,
    isLoading: periodIsLoading,
    error: periodError,
  } = useSWR(
    config.api.getMachineDataByPeriod(machineId, MachineDataTypeByPeriod.Current, day, day),
    currentByPeriodFetcher,
  )

  if (isLoading || error || periodIsLoading || periodError) {
    // TODO: better error handling and loading
    return null
  }

  const { max, min } = periodData?.data

  return (
    <TimelineChartBox
      tab={tab}
      type={MachineMetricsDataType.Current}
      data={data}
      machineId={machineId}
      summary={[
        {
          description: t('timelineCharts.max'),

          value: max,
          unit: t('metricUnit.current'),
        },
        {
          description: t('timelineCharts.min'),
          value: min,
          unit: t('metricUnit.current'),
        },
      ]}
    />
  )
}

export default CurrentTimelineChart
