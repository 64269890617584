import React, { useState } from 'react'

import { TimelineTabs } from 'components/TimelineTabs'
import { TimelineTab } from 'types/enums'

import {
  VoltageTimelineChart,
  CurrentTimelineChart,
  PowerTimelineChart,
  PowerFactorTimelineChart,
  VthdTimelineChart,
  IthdTimelineChart,
  EnergyTimelineChart,
} from './timeline-charts'

interface Props {
  tab?: string
  day: Date
  machineId: number
}

const chartMap: {
  [tab in TimelineTab]?: undefined | null | React.FC<{ machineId: number; day: Date; tab?: string }>
} = {
  [TimelineTab.Voltage]: VoltageTimelineChart,
  [TimelineTab.Current]: CurrentTimelineChart,
  [TimelineTab.Power]: PowerTimelineChart,
  [TimelineTab.PowerFactor]: PowerFactorTimelineChart,
  [TimelineTab.VTHD]: VthdTimelineChart,
  [TimelineTab.ITHD]: IthdTimelineChart,
  [TimelineTab.Energy]: EnergyTimelineChart,
}

export const TimelineChartBox: React.FC<Props> = ({ day, machineId, tab }) => {
  const [activeTab, setActiveTab] = useState(TimelineTab.Voltage)

  const Chart = chartMap[activeTab]

  return (
    <div className="timeline-chartbox-wrapper">
      {tab === TimelineTab.Report ? (
        Object.entries(chartMap).map(([tab, Chart]) => (
          <Chart key={tab} machineId={machineId} day={day} tab={TimelineTab.Report} />
        ))
      ) : (
        <>
          <TimelineTabs activeTab={activeTab} onTabChange={(tab) => setActiveTab(tab)} />
          {Chart && <Chart machineId={machineId} day={day} />}
        </>
      )}
    </div>
  )
}

export default TimelineChartBox
