import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, Typography } from 'antd'
import { Line } from 'react-chartjs-2'
import { useTheme } from 'providers'
import { MachineMetricsDataType } from 'types/enums'
import { getChartOptions } from './chartHelper'
import Summary from './Summary'
import styles from './styles.module.scss'
import classNames from 'classnames'

const containerStyle: React.CSSProperties = { width: '100%', height: '350px' }

type Props = {
  type: MachineMetricsDataType
}

export const EmptyChartBox: React.FC<Props> = ({ type }) => {
  const { theme } = useTheme()
  const { t } = useTranslation()

  const getChartTitle = () => {
    const getTitleText = (title, unit?) => {
      return (
        <Typography.Title level={4}>
          {title}
          {unit && <span className="unit">{`[${unit}]`}</span>}
        </Typography.Title>
      )
    }
    {
      /* TODO: translate Voltage and V labels */
    }
    switch (type) {
      case MachineMetricsDataType.Voltage:
        return getTitleText(t('machineDetail.voltage'), 'V')
      case MachineMetricsDataType.Current:
        return getTitleText(t('machineDetail.current'), 'A')
      case MachineMetricsDataType.Power:
        return getTitleText(t('machineDetail.power'))
      case MachineMetricsDataType.PowerFactor:
        return getTitleText(t('machineDetail.powerFactor'))
      case MachineMetricsDataType.Vthd:
        return getTitleText(t('machineDetail.vthd'), '%')
      case MachineMetricsDataType.Ithd:
        return getTitleText(t('machineDetail.ithd'), '%')
    }
  }

  const options = useMemo(() => getChartOptions(theme), [theme])
  const summary = useMemo<React.ReactNode>(
    () => (
      <>
        {getChartTitle()}

        {type === MachineMetricsDataType.Voltage && (
          <Summary
            data={[
              {
                description: t('machineDetail.voltageBox.inMax'),
                unit: 'V',
                value: null,
              },
              {
                description: t('machineDetail.voltageBox.inMin'),
                unit: 'V',
                value: null,
              },
              {
                description: t('machineDetail.voltageBox.outAvg'),
                unit: 'V',
                value: null,
              },
            ]}
          />
        )}
      </>
    ),
    [type],
  )

  return (
    <Card className={classNames(styles['chart-box'], 'chart-box', 'chart-box--empty')} bordered={false}>
      {summary}
      <div style={containerStyle}>
        <Line data={{ labels: [], datasets: [] }} options={options} />
      </div>
    </Card>
  )
}

export default EmptyChartBox
