// NOTE: this needs to be imported before other modules
import 'core/dayjs-plugins'

import { matchPath } from 'react-router-dom'

import routes from 'config/routes'
import { ApiSortDirection, TableSortDirection, UserRoles } from 'types/enums'
import PasswordValidator from 'password-validator'

function getQueryString(pars: Array<string>): string {
  let q = ''
  let value: string

  Object.keys(pars).map((key) => {
    value = pars[key]
    if (!(value === null)) {
      q += `&${key}=${encodeURIComponent(value)}`
    }
    return null
  })

  if (q.length > 0) {
    //remove first
    q = q.substr(1)
  }

  return q
}

function splitQueryString(q: string) {
  const pars = q.split('&')
  const qq = {}

  for (let i = 0; i < pars.length; i += 1) {
    const ret = pars[i].toString().split('=')
    qq[ret[0]] = decodeURIComponent(ret[1])
  }
  return qq
}

export function getQueryParameters(): QueryParameters {
  const { search } = document.location
  if (!search) {
    //no search pars
    return {}
  }

  //normalize to lowercase
  const q = search.toLowerCase().substr(1) as string
  return splitQueryString(q) as QueryParameters
}

export function getHashParameters() {
  const { hash } = document.location
  if (!hash) {
    //no hash pars
    return {}
  }

  //normalize to lowercase
  const q = hash.toLowerCase().substr(1)
  return splitQueryString(q)
}

export function setHashParameters(pars) {
  const h = `#${getQueryString(pars)}`
  document.location.hash = h
}

export function removeNull(obj) {
  Object.entries(obj).reduce((a, [k, v]) => (v == null ? a : ((a[k] = v), a)), {})
  return obj
}

export const getCurrentRoute = (locationPath: string): Route => {
  const currentRouteKey = Object.keys(routes).find((route) => {
    const match = matchPath(routes[route].url, locationPath)
    return match != null
  })
  return routes[currentRouteKey]
}

/** Acronymify function creates a sort of "acronym" given a string and the desired length of the acronym
 * Example:
 * expect(acronymify('Java Script Object Notation', 4)).toBe('JSON');
 * expect(acronymify('Java Script Object Notation', 2)).toBe('JS');
 * expect(acronymify('Alan Turing', 2)).toBe('AT');
 * expect(acronymify('Jhonny', 2)).toBe('Jh');
 * expect(acronymify('DO', 4)).toBe('DO00');
 */
export function acronymify(text: string, len: number): string {
  text = text.padEnd(len, '0')
  const acronym = text.split(/\W/).reduce((accumulator, word) => accumulator + word.charAt(0), '')
  return acronym.length >= len
    ? acronym.slice(0, len)
    : `${acronym}${acronymify(text.slice(acronym.length), len - acronym.length)}`
}

export const isRouteAuthorizedForUser = (userRole: UserRoles, currentRoute: Route): boolean => {
  if (currentRoute?.authorize && userRole) {
    return (currentRoute.authorize as UserRoles[]).includes(userRole)
  }

  return true
}

/**
 * Generic function to be used with ANTD forms to validate that the submit button can be enabled,
 * cause all the required fields are filled and have a valid value
 *
 * Usage:
 * const requiredFields = [
    'firstname',
    'lastname',
    ]
 * <Form.Item shouldUpdate={true}>
            {() => (
              <Button
                htmlType="submit"
                disabled={checkSubmitButtonBeDisabled(form, requiredFields) }
                block
              >
 */
export const checkSubmitButtonBeDisabled = (form, requiredFields) => {
  const result = Object.keys(form.getFieldsValue()).filter((visibleField) => {
    const isRequired = requiredFields.indexOf(visibleField) > -1
    const fieldValue = form.getFieldValue(visibleField)
    const isMissingValue = !fieldValue && fieldValue !== 0 && !form.isFieldTouched(visibleField)
    const hasError = form.getFieldError(visibleField).length > 0
    if (isRequired) {
      return isMissingValue || hasError
    } else {
      return hasError
    }
  })
  return result.length > 0
}

export const getInitialsFromEmail = (email: string): string => {
  if (typeof email !== 'string' || email.length < 5 || !email.indexOf('@')) {
    return '??'
  }

  const dotSeparatedNames = /([\w\d]+)\.([\w\d]+)@/

  if (dotSeparatedNames.test(email)) {
    const match = dotSeparatedNames.exec(email)

    return `${match[1][0]}${match[2][0]}`.toUpperCase()
  }

  return email
    .split('')
    .filter((letter) => /\w/.test(letter))
    .slice(0, 2)
    .join('')
    .toUpperCase()
}

export const getUserBadgeString = (nameOrEmail: string, type: 'email' | 'name') => {
  if (type === 'email') {
    return getInitialsFromEmail(nameOrEmail)
  }

  return acronymify(nameOrEmail, 2)
}

const schema = new PasswordValidator()
schema.is().min(8).has().uppercase().has().lowercase().has().digits().has().symbols()

export const formatPasswordValidateError = (errors: Array<string>) => {
  for (let i = 0; i < errors.length; i++) {
    if (errors[i] === 'min') {
      return 'validation.password.passwordMinCharacters'
    } else if (errors[i] === 'lowercase') {
      return 'validation.password.passwordLowercaseLetters'
    } else if (errors[i] === 'uppercase') {
      return 'validation.password.passwordUppercaseLetters'
    } else if (errors[i] === 'digits') {
      return 'validation.password.passwordDigits'
    } else if (errors[i] === 'symbols') {
      return 'validation.password.passwordSymbols'
    }
  }
}

export const validatePassword = (value: string): string => {
  if (!value || value.length < 1) {
    return ''
  }

  const validationRulesErrors = schema.validate(value, { list: true })
  if ((validationRulesErrors as string[]).length > 0) {
    return formatPasswordValidateError(validationRulesErrors as string[])
  }
  return ''
}

export const generateDownloadLink = (response: string, fileName: string) => {
  const blob = new Blob([response])
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = url
  a.download = fileName
  a.click()
  window.URL.revokeObjectURL(url)
  a.remove()
}

export const mapSortDirectionFrom = (direction: TableSortDirection): ApiSortDirection => {
  switch (direction) {
    case TableSortDirection.Ascend:
      return ApiSortDirection.Asc
    case TableSortDirection.Descend:
      return ApiSortDirection.Desc
    default:
      return
  }
}
