import type * as Api from 'types/api'
import type * as Store from 'types/store'

export const mapMachinePowerFactorByDay = ({
  pfr,
  pfs,
  pft,
}: Api.MachinePowerFactorByDay): Store.MachinePowerFactorByDay => ({
  pfr,
  pfs,
  pft,
})

export default mapMachinePowerFactorByDay
