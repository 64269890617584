import React, { useMemo, useState } from 'react'
import { NewsContext, NewsState } from './NewsContext'

export const NewsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [newsVisible, setVisible] = useState<boolean>(false)

  // const value: NewsState = useMemo({
  //   newsVisible,
  //   setNewsVisibility: (hidden: boolean) => setVisible(hidden),
  // })

  const value = useMemo<NewsState>(
    () => ({
      newsVisible,
      setNewsVisibility: (hidden: boolean) => setVisible(hidden),
    }),
    [newsVisible, setVisible],
  )

  return <NewsContext.Provider value={value}>{children}</NewsContext.Provider>
}

export default NewsProvider
