import { config } from 'core'
import { AxiosError, AxiosResponse } from 'axios'

import { getAxios } from './apiService'
import { getApiError } from './common'

import type * as Api from 'types/api'

const axios = getAxios(false)

export async function login(data: Api.LoginRequest): Promise<Api.LoginResponse | ApiError> {
  try {
    const response = await axios.post<Api.LoginResponse, AxiosResponse<Api.LoginResponse>, Api.LoginRequest>(
      config.api.login(),
      data,
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function register(data: Api.RegisterRequest): Promise<Api.RegisterResponse | ApiError> {
  try {
    const response = await axios.post<
      Api.RegisterResponse,
      AxiosResponse<Api.RegisterResponse>,
      Api.RegisterRequest
    >(config.api.register(), data)
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function forgotPassword(email: string): Promise<AxiosResponse | ApiError> {
  try {
    const response = await axios.post(config.api.forgotPwd(), { email })
    return response
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function resetPassword(
  data: Api.ConfirmEmailResetPasswordRequest,
): Promise<AxiosResponse | ApiError> {
  try {
    const response = await axios.put(config.api.resetPwd(), data)
    return response
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}
export async function confirmEmail(data: Api.ConfirmEmailRequest): Promise<AxiosResponse | ApiError> {
  try {
    const response = await axios.put(config.api.confirm(), data)
    return response
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}
