import type * as Api from 'types/api'
import type * as Store from 'types/store'

export const mapMachineList = ({
  id,
  at,
  serial,
  name,
  product,
  note,
  sector,
  country,
  city,
  province,
  address,
  isFavourite,
}: Api.MachineListItem): Store.MachineListItem => ({
  id,
  at,
  serial,
  name,
  product,
  note,
  sector,
  country,
  city,
  province,
  address,
  isFavourite,
})

export default mapMachineList
