import React from 'react'
import classNames from 'classnames'
import routes from 'config/routes'
import { Link, useNavigate } from 'react-router-dom'
import { getUserBadgeString } from 'core/helpers'
import { useTranslation } from 'react-i18next'
import { Button, Divider, Layout, Popover, Space, Typography } from 'antd'

import { useTheme } from 'providers/ThemeProvider'
import { useAppDispatch } from 'store'
import { logout } from 'store/user'
import { getUserClaims } from 'core/localStore'
import ThemeSwitcher from 'components/ThemeSwitcher'
import LanguageSelector from 'containers/LanguageSelector/LanguageSelector'

import { themes } from './themes'

import styles from './styles.module.scss'
import MachineMenu from 'containers/MachineMenu'
import { LogoutOutlined, UserOutlined } from '@ant-design/icons'

const Header: React.FC = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { getStyles } = useTheme()
  const claims = getUserClaims()
  const { email } = claims

  const themeStyles = getStyles(themes)

  const navigate = useNavigate()

  function logoutUser() {
    dispatch(logout())
    navigate(routes.login.url)
  }

  const content = (
    <div>
      <Space align="center">
        <Typography.Title level={5} className="m-0">
          {email}
        </Typography.Title>
      </Space>
      <Divider className="my-10" />
      <Link to={routes.userProfile.url}>
        <Button block className="btn-icon" icon={<UserOutlined size={18} />}>
          {t('form.profile')}
        </Button>
      </Link>
      <Link to={routes.login.url} onClick={logoutUser}>
        <Button block className="my-15 btn-icon" icon={<LogoutOutlined size={18} />}>
          {t('form.logout')}
        </Button>
      </Link>
    </div>
  )

  return (
    <Layout.Header className={classNames(styles.header, themeStyles.theme)}>
      <MachineMenu mobile />

      <div className="top-bar-wrapper">
        <LanguageSelector />
        <ThemeSwitcher />

        <Popover trigger={['click']} showArrow={false} placement="bottomRight" content={content}>
          <Button className="profile-item p-0" type="primary">
            {getUserBadgeString(email, 'email').toUpperCase()}
          </Button>
        </Popover>
      </div>
    </Layout.Header>
  )
}

Header.displayName = 'Header'

export default Header
