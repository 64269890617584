// NOTE: this needs to be imported before other modules
import 'core/dayjs-plugins'

import React, { Suspense } from 'react'
import Debug from 'debug'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import dayjs from 'dayjs'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import {
  Chart as ChartJS,
  CategoryScale,
  Filler,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'

import { isAlive } from 'api/ping'
import { config, initLanguage } from 'core'
import ServerNotAvailablePage from 'pages/ServerNotAvailablePage'
import { ThemeType } from 'themes/types'
import { store } from 'store'
import { LanguageProvider, THEME_STORAGE, ThemeProvider } from 'providers'
import NewsProvider from 'providers/NewsProvider/NewsProvider'

import App from './containers/App'
import reportWebVitals from './reportWebVitals'

import 'scss/index.scss'
import 'antd/dist/reset.css'
import { FlagsProvider } from 'flagged'

ChartJS.register(
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
)

const debug = Debug('Performance')

const labelSource = process.env.REACT_APP_LABELS_SOURCE

const enableReportDownload = /true/.test(process.env.REACT_APP_FEATURE_REPORT)

const container = document.getElementById('root')
const root = createRoot(container)

const isSystemDarkTheme = window.matchMedia('(prefers-color-scheme: dark)').matches
const themeSetting = localStorage.getItem(THEME_STORAGE) as ThemeType

const initialTheme = themeSetting ?? (isSystemDarkTheme ? ThemeType.Dark : ThemeType.Light)

isAlive()
  .then(() => initLanguage(config.getLanguage(), labelSource))
  .then(() => {
    dayjs.locale(config.getLanguage())

    root.render(
      <React.StrictMode>
        <HelmetProvider>
          <Provider store={store}>
            <ThemeProvider initialTheme={initialTheme}>
              <Suspense fallback="">
                <BrowserRouter>
                  <FlagsProvider features={{ enableReport: enableReportDownload }}>
                    <LanguageProvider>
                      <NewsProvider>
                        <App />
                      </NewsProvider>
                    </LanguageProvider>
                  </FlagsProvider>
                </BrowserRouter>
              </Suspense>
            </ThemeProvider>
          </Provider>
        </HelmetProvider>
      </React.StrictMode>,
    )

    // If you want to start measuring performance in your app, pass a function
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals(debug)
  })
  .catch(() => {
    //API down, add a static error page
    root.render(<ServerNotAvailablePage />)
  })
