import { getAxios } from './apiService'
import type * as Api from 'types/api'
import { config } from 'core'
import { getApiError, getAuthenticationHeader } from './common'
import { AxiosError, AxiosResponse } from 'axios'

const axios = getAxios()

export async function getAdminUsers({ q, o, sz, n }: Api.PaginationParams = {}): Promise<
  Api.PaginatedResult<Api.AdminUser> | ApiError
> {
  try {
    const { headers } = await getAuthenticationHeader()
    const response = await axios.get<Api.PaginatedResult<Api.AdminUser>>(config.api.getAdminUsers(), {
      params: { q, o, sz: sz?.toString(), n: n?.toString() },
      headers,
    })
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function getAdminUserById(id: number): Promise<Api.AdminUser | ApiError> {
  try {
    const response = await axios.get<Api.AdminUser>(
      config.api.getAdminUserById(id),
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function postAdminUser(newUserPayload: Api.AdminUserPayload): Promise<Api.AdminUser | ApiError> {
  try {
    const response = await axios.post<Api.AdminUser, AxiosResponse<Api.AdminUser>, Api.AdminUserPayload>(
      config.api.postAdminUser(),
      newUserPayload,
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function putAdminUserById(
  id: number,
  userPayload: Api.AdminUserPayload,
): Promise<Api.AdminUser | ApiError> {
  try {
    const response = await axios.put<Api.AdminUser, AxiosResponse<Api.AdminUser>, Api.AdminUserPayload>(
      config.api.putAdminUserById(id),
      userPayload,
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function deleteAdminUserById(id: number): Promise<Api.AdminUser | ApiError> {
  try {
    const response = await axios.delete<
      Api.AdminUser,
      AxiosResponse<Api.AdminUser, Api.AdminUserPayload>,
      Api.AdminUser
    >(config.api.deleteAdminUserById(id), await getAuthenticationHeader())
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}
