import type * as Api from 'types/api'
import type * as Store from 'types/store'

export const mapCode = ({ at, claimedAt, code, role, serial }: Api.AdminCode): Store.AdminCode => ({
  at,
  claimedAt,
  code,
  role,
  serial,
})

export default mapCode
