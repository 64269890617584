import React, { useCallback, useMemo, useState } from 'react'
import { Button, Card, Col, DatePicker, Radio, RadioChangeEvent, Row, Space, notification } from 'antd'
import dayjs from 'dayjs'
import { ChartData, ChartOptions, Scale } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'

import { config } from 'core'
import { getThemeColors } from 'core/chartThemeColors'
import { useTheme } from 'providers'

import type * as Store from 'types/store'
import styles from './styles.module.scss'

import { ReactComponent as ArrowIcon } from 'assets/icon/arrow-icon.svg'
import { ProductType, TimelineTab } from 'types/enums'

import localeEN from 'antd/es/date-picker/locale/en_GB'
import localeIT from 'antd/es/date-picker/locale/it_IT'
import DownloadTimelineDocument from 'containers/DownloadTimelineDocument'
import { useFeature } from 'flagged'

const { RangePicker } = DatePicker

interface Props {
  tab?: string
  begin: Date
  end: Date
  data: Store.EnergyByPeriodDataPoint[]
  isEval?: boolean
  machineType?: string
  doUpdateMachineDateRange?: (start: dayjs.Dayjs, end: dayjs.Dayjs) => void
  machineId: number
  evalChartHasNextDate?: boolean
  evalChartHasPrevDate?: boolean
  doUpdateMachineEvalDateRange?: (operation: string) => void
  lang: string
}

const checkThisLastMonth = (start: Date, end: Date) => {
  const now = dayjs()
  return dayjs(start).isSame(now, 'month') &&
    dayjs(end).isSame(now, 'month') &&
    dayjs(end).isBefore(now.endOf('month'))
    ? true
    : dayjs(start).isSame(now.subtract(1, 'month'), 'month') &&
      dayjs(end).isSame(now.subtract(1, 'month'), 'month') &&
      dayjs(end).isBefore(now.subtract(1, 'month').endOf('month'))
    ? false
    : null
}

export const TimelineBarChart: React.FC<Props> = ({
  tab,
  begin,
  end,
  data,
  isEval,
  doUpdateMachineDateRange,
  machineType,
  machineId,
  evalChartHasNextDate,
  evalChartHasPrevDate,
  doUpdateMachineEvalDateRange,
  lang,
}) => {
  const enableReportDownload = useFeature('enableReport')

  const { t } = useTranslation()
  const [api, contextHolder] = notification.useNotification()
  const { theme } = useTheme()
  const colors = getThemeColors(theme)
  const [dateStart, setDateStart] = useState(dayjs(begin))
  const [dateEnd, setDateEnd] = useState(dayjs(end))

  const [currentMonth, setCurrentMonth] = useState(checkThisLastMonth(begin, end))

  const containerStyle: React.CSSProperties = { width: '100%', height: '250px' }

  const handleMonthSwitch = useCallback(
    (e: RadioChangeEvent) => {
      setCurrentMonth(e.target.value === 'currentMonth')
    },
    [setCurrentMonth],
  )

  const handleDateRangeChange = (dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null) => {
    if (dates) {
      const [dateStart, dateEnd] = dates
      const interval = dateEnd.diff(dateStart, 'day') as number
      if (interval > 30) {
        api.warning({
          message: t('error.period31days.title'),
          duration: 3,
        })
      }
      setDateStart(dateStart)
      setDateEnd(interval < 31 ? dateEnd : dateStart.add(30, 'day'))
      doUpdateMachineDateRange(dateStart, interval < 31 ? dateEnd : dateStart.add(30, 'day'))
    } else {
      setDateStart(null)
      setDateEnd(null)
    }
  }

  const datasets = useMemo<ChartData<'bar', number[], string>>(
    () => ({
      labels: data?.map((d) => dayjs(d.day).format(config.getDateFormat())),
      datasets: [
        {
          label: machineType !== ProductType.Sterostab ? t('energy.savingPerc') : t('energy.savingkwh'),
          data: data?.map((d) => (machineType === ProductType.Sterostab ? d.eIn : d.eRisP)),
          backgroundColor: colors.greenBarChartFiller,
          borderColor: colors.greenBorderColor,
          borderWidth: 1,
          borderRadius: 11,
        },
      ],
    }),
    [data, t, colors],
  )

  const options = useMemo<ChartOptions<'bar'>>(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      cubicInterpolationMode: 'monotone',
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: machineType !== ProductType.Sterostab ? t('energy.savingPerc') : t('energy.savingkwh'),
        },
      },
      scales: {
        x: {
          color: colors.tickColor,
          border: {
            display: false,
          },
          grid: {
            display: false,
          },
          ticks: {
            callback: function (this: Scale, val: number, index: number) {
              return index % 2 === 0 ? this.getLabelForValue(val) : ''
            },
            maxRotation: 0,
            minRotation: 0,
          },
        },
        y: {
          color: colors.tickColor,
          border: {
            display: false,
          },
          grid: {
            color: colors.gridColor,
          },
          title: {
            display: machineType === ProductType.Sterostab ? true : false,
            text: t('table.kwh'),
          },
        },
      },
    }),
    [t, colors],
  )

  const prevNextDateRangeHandler = (operation: string) => {
    const diff = dateEnd?.diff(dateStart, 'day') + 1

    const updateDates = (start: dayjs.Dayjs | null, end: dayjs.Dayjs | null) => {
      setDateStart(start)
      setDateEnd(end)
      doUpdateMachineDateRange(start, end)
    }

    switch (operation) {
      case 'add':
        if (isEval && evalChartHasNextDate) {
          doUpdateMachineEvalDateRange(operation)
        } else {
          updateDates(dateStart?.add(diff, 'day'), dateEnd?.add(diff, 'day'))
        }
        break
      case 'subtract':
        if (isEval && evalChartHasPrevDate) {
          doUpdateMachineEvalDateRange(operation)
        } else {
          updateDates(dateStart?.subtract(diff, 'day'), dateEnd?.subtract(diff, 'day'))
        }
        break
      default:
        console.warn('Invalid operation: ' + operation)
        break
    }
  }

  const currentMonthLastMonthHandler = (currentMonthLastMonth: string) => {
    const [startMonth, endMonth] = [
      currentMonthLastMonth === 'current'
        ? dayjs().startOf('month')
        : dayjs().subtract(1, 'month').startOf('month'),
      currentMonthLastMonth === 'current'
        ? dayjs().endOf('month')
        : dayjs().subtract(1, 'month').endOf('month'),
    ]
    setDateStart(startMonth)
    setDateEnd(endMonth)
    doUpdateMachineDateRange(startMonth, endMonth)
  }

  return (
    <>
      {contextHolder}
      {!isEval && tab !== TimelineTab.Report && (
        <Row justify="space-between" className="chart-timerange-wrapper">
          <Col>
            <Space>
              <Radio.Group
                size="large"
                className="month-switch"
                buttonStyle="solid"
                value={currentMonth !== null ? (currentMonth ? 'currentMonth' : 'lastMonth') : null}
                onChange={handleMonthSwitch}
              >
                <Radio.Button value="lastMonth" onClick={() => currentMonthLastMonthHandler('last')}>
                  {t('general.lastMonth')}
                </Radio.Button>
                <Radio.Button value="currentMonth" onClick={() => currentMonthLastMonthHandler('current')}>
                  {t('general.thisMonth')}
                </Radio.Button>
              </Radio.Group>
              {enableReportDownload && (
                <DownloadTimelineDocument
                  end={end}
                  start={begin}
                  machineId={machineId}
                  machineType={machineType}
                  lang={lang}
                />
              )}
            </Space>
          </Col>

          <Col>
            <RangePicker
              locale={config.getLanguage() === 'en' ? localeEN : localeIT}
              size="large"
              placeholder={[t('general.startDate'), t('general.endDate')]}
              value={[dateStart, dateEnd]}
              onChange={handleDateRangeChange}
              format={config.getDateFormat()}
            />
          </Col>
        </Row>
      )}

      <Card className={styles['barchart-card']} bordered={false}>
        <Button
          className="nav-prev"
          type="text"
          disabled={!evalChartHasPrevDate && isEval}
          icon={<ArrowIcon />}
          onClick={() => prevNextDateRangeHandler('subtract')}
        />

        <div className="chart-wrapper">
          <div style={containerStyle}>
            <Bar data={datasets} options={options} />
          </div>
        </div>

        <Button
          className="nav-next"
          type="text"
          icon={<ArrowIcon />}
          disabled={!evalChartHasNextDate && isEval}
          onClick={() => prevNextDateRangeHandler('add')}
        />
      </Card>
    </>
  )
}

export default TimelineBarChart
