import React from 'react'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { useFetchTimelineData } from 'hooks'
import { TimelineChartBox } from 'components/TimelineChartBox'
import { MachineDataTypeByPeriod, MachineMetricsDataType } from 'types/enums'
import { config } from 'core'
import getSwrFetcher from 'api/swr-fetcher'
import { mapMachineMetricsByPeriod } from 'services'

interface Props {
  tab?: string
  machineId: number
  day: Date
}

const voltageByPeriodFetcher = getSwrFetcher({ method: 'GET' }, mapMachineMetricsByPeriod)

export const VoltageTimelineChart: React.FC<Props> = ({ machineId, day, tab }) => {
  const { t } = useTranslation()
  const { data, isLoading, error } = useFetchTimelineData(machineId, day, MachineMetricsDataType.Voltage)

  const {
    data: periodData,
    isLoading: periodIsLoading,
    error: periodError,
  } = useSWR(
    config.api.getMachineDataByPeriod(machineId, MachineDataTypeByPeriod.Voltage, day, day),
    voltageByPeriodFetcher,
  )

  if (isLoading || error || periodIsLoading || periodError) {
    // TODO: better error handling and loading
    return null
  }

  const { max, min, out } = periodData?.data

  return (
    <TimelineChartBox
      tab={tab}
      type={MachineMetricsDataType.Voltage}
      data={data}
      machineId={machineId}
      summary={
        // TODO: verify labels and add translations and correct values
        [
          {
            description: t('machineDetail.voltageBox.inMax'),
            value: max,
            unit: t('metricUnit.voltage'),
          },
          {
            description: t('machineDetail.voltageBox.inMin'),
            value: min,
            unit: t('metricUnit.voltage'),
          },
          {
            description: t('machineDetail.voltageBox.outAvg'),
            value: out,
            unit: t('metricUnit.voltage'),
          },
        ]
      }
    />
  )
}

export default VoltageTimelineChart
