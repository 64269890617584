import { ThemeType } from 'types/enums'

const light = {
  redColor: '#B01F24',
  redBorderColor: 'rgba(176, 31, 36, 0.7)',
  redFillerColor: 'rgba(176, 31, 36, 0.1)',

  greenColor: '#9AC802',
  greenBorderColor: 'rgba(154, 200, 2, 0.7)',
  greenFillerColor: 'rgba(154, 200, 2, 0.1)',
  greenBarChartFiller: 'rgba(154, 200, 2, 0.2)',

  tickColor: 'rgba(0, 0, 0, 0.64)',
  gridColor: 'rgba(0, 0, 0, 0.08)',

  brownColor: '#8B4513',

  grayColor: '#888888',

  blackOrWhiteColor: '#090909',
  blackOrWhiteBorderColor: 'rgb(9, 9, 9, 0.7)',
  blackOrWhiteFillerColor: 'rgb(9, 9, 9, 0.1)',

  yellowColor: '#FFC800',
  yellowBorderColor: 'rgba(255, 200, 0, 0.7)',
  yellowFillerColor: 'rgba(255, 200, 0, 0.1)',

  blueColor: '#021f9a',
  blueBorderColor: 'rgba(	2, 31, 154, 0.7)',
  blueFillerColor: 'rgba(	2, 31, 154, 0.1)',
}

const dark = {
  redColor: '#D1252B',
  redBorderColor: 'rgba(209, 37, 43, 0.7)',
  redFillerColor: 'rgba(209, 37, 43, 0.1)',

  greenColor: '#B8EE02',
  greenBorderColor: 'rgba(184, 238, 2, 0.7)',
  greenFillerColor: 'rgba(184, 238, 2, 0.1)',
  greenBarChartFiller: 'rgba(184, 238, 2, 0.2)',

  tickColor: 'rgba(255, 255, 255, 0.4)',
  gridColor: 'rgba(255, 255, 255, 0.08)',

  brownColor: '#8A5A2D',

  grayColor: '#AAAAAA',

  blackOrWhiteColor: '#F9F9F9',
  blackOrWhiteBorderColor: 'rgb(249, 249, 249, 0.7)',
  blackOrWhiteFillerColor: 'rgb(249, 249, 249, 0.1)',

  yellowColor: '#FFD700',
  yellowBorderColor: 'rgba(	255, 215, 0, 0.7)',
  yellowFillerColor: 'rgba(	255, 215, 0, 0.1)',

  blueColor: '#3A52A3',
  blueBorderColor: 'rgba(	58, 82, 163, 0.7)',
  blueFillerColor: 'rgba(	58, 82, 163, 0.1)',
}

export const getThemeColors = (theme: ThemeType) => (theme === ThemeType.Dark ? dark : light)
