import { isNumeric, toNumber } from 'core'

import type * as Api from 'types/api'
import type * as Store from 'types/store'

import { mapDate } from './map-date'

export const mapMachineEnergyByPeriod = ({
  begin,
  end,
  data,
}: Api.MachineEnergyByPeriod): Store.MachineEnergyByPeriod => ({
  begin: mapDate(begin),
  end: mapDate(end),
  data: data.map(({ day, ein, ein_sum, eris, erisp, eris_sum, erisp_sum, ku }) => ({
    day: mapDate(day),
    eIn: isNumeric(ein) ? toNumber(ein) : null,
    eInSum: isNumeric(ein_sum) ? toNumber(ein_sum) : null,
    eRis: isNumeric(eris) ? toNumber(eris) : null,
    eRisP: isNumeric(erisp) ? toNumber(erisp) : null,
    eRisSum: isNumeric(eris_sum) ? toNumber(eris_sum) : null,
    eRispSum: isNumeric(erisp_sum) ? toNumber(erisp_sum) : null,
    ku: isNumeric(ku) ? toNumber(ku) : null,
  })),
})

export default mapMachineEnergyByPeriod
