/**
 * Quickly converts a valid numeric value to a number type. Will not check strings.
 * NOTE: the input must first be checked with the isNumeric function
 * @param {number | bigint | `${number}`} input Valid number
 * @returns {number} The value converted as a number type (NaN in case the type is not a number, string or bigint)
 */
export const toNumber = (input: number | bigint | `${number}`): number => {
  switch (typeof input) {
    case 'number':
      return input
    case 'string':
    case 'bigint':
      return Number(input)
    default:
      return NaN
  }
}

export default toNumber
