import TimelineDesktopTable from 'components/TimelineDesktopTable'
import { TimelineMobileTable } from 'components/TimelineMobileTable'
import React, { useEffect, useState } from 'react'

import type * as Store from 'types/store'

const MOBILE_WIDTH_BREAKPOINT = 768

// const MOBILE_WIDTH_BREAKPOINT = 1296
interface Props {
  tab?: string
  data: Store.EnergyByPeriodDataPoint[]
  expandedRowRender: (record: Store.EnergyByPeriodDataPoint) => React.ReactNode
  machineType?: string
}

export const TimelineTable: React.FC<Props> = ({ tab, data, expandedRowRender, machineType }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= MOBILE_WIDTH_BREAKPOINT)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= MOBILE_WIDTH_BREAKPOINT)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return isMobile ? (
    <TimelineMobileTable
      tab={tab}
      data={data}
      expandedRowRender={expandedRowRender}
      machineType={machineType}
    />
  ) : (
    <TimelineDesktopTable
      tab={tab}
      data={data}
      expandedRowRender={expandedRowRender}
      machineType={machineType}
    />
  )
}

export default TimelineTable
