import type * as Api from 'types/api'
import type * as Store from 'types/store'

export const mapMachineMenu = ({
  id,
  at,
  serial,
  name,
  product,
  note,
  sector,
  country,
  city,
  province,
  address,
  isFavourite,
}: Api.MachineMenuItem): Store.MachineMenuItem => ({
  id,
  at,
  serial,
  name,
  product,
  note,
  sector,
  country,
  city,
  province,
  address,
  isFavourite,
})

export default mapMachineMenu
