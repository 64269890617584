import React from 'react'
import { Card, Space, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import { isNumeric, toNumber } from 'core'

import { useLanguage } from 'providers'

export interface Props {
  value: number | null | undefined
  valuePerc: number
}

export const EcostabBox: React.FC<Props> = ({ value, valuePerc }) => {
  const { t } = useTranslation()
  const lang = useLanguage()

  return (
    <Card className="saving-box" bordered={false}>
      <Typography.Title level={5} className="card-subtitle card-subtitle--top">
        {t('machineDetail.saving')}
      </Typography.Title>

      <Space className="content-wrapper" align="start">
        <div>
          <Typography.Text className="box-value">
            <span className="numeric">
              {isNumeric(value) ? new Intl.NumberFormat(lang).format(toNumber(value)) : ''}
            </span>
            <span className="unit">{t(`metricUnit.activePower`)}</span>
          </Typography.Text>

          <Typography.Text className="box-value">
            <span className="numeric">{valuePerc ? valuePerc : ''}</span>
            <span className="unit">{t(`metricUnit.percentage`)}</span>
          </Typography.Text>
        </div>
      </Space>
    </Card>
  )
}

export default EcostabBox
