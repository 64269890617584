import useSWR, { BareFetcher, SWRResponse } from 'swr'

import getSwrFetcher from 'api/swr-fetcher'
import { mapMachineRealtimeResponse } from 'services'
import { config } from 'core'

import type * as Api from 'types/api'
import type * as Store from 'types/store'
import { MachineRealtimeDataType } from 'types/enums'
import mapMachineRealtimeAllResponse from 'services/map-realtime-all-response'

const fetchers = {
  [MachineRealtimeDataType.All]: getSwrFetcher({ method: 'GET' }, (data: Api.MachineRealTimeAllResponse) =>
    mapMachineRealtimeAllResponse(data),
  ),
  [MachineRealtimeDataType.Current]: getSwrFetcher(
    { method: 'GET' },
    (data: Api.MachineRealTimeResponse<Api.RealtimeCurrentData>) =>
      mapMachineRealtimeResponse<Api.RealtimeCurrentData, Store.RealtimeCurrentData>(
        data,
        MachineRealtimeDataType.Current,
      ),
  ),
  [MachineRealtimeDataType.Energy]: getSwrFetcher(
    { method: 'GET' },
    (data: Api.MachineRealTimeResponse<Api.RealtimeEnergyData>) =>
      mapMachineRealtimeResponse<Api.RealtimeEnergyData, Store.RealtimeEnergyData>(
        data,
        MachineRealtimeDataType.Energy,
      ),
  ),
  [MachineRealtimeDataType.Ithd]: getSwrFetcher(
    { method: 'GET' },
    (data: Api.MachineRealTimeResponse<Api.RealtimeIthdData>) =>
      mapMachineRealtimeResponse<Api.RealtimeIthdData, Store.RealtimeIthdData>(
        data,
        MachineRealtimeDataType.Ithd,
      ),
  ),
  [MachineRealtimeDataType.Power]: getSwrFetcher(
    { method: 'GET' },
    (data: Api.MachineRealTimeResponse<Api.RealtimePowerData>) =>
      mapMachineRealtimeResponse<Api.RealtimePowerData, Store.RealtimePowerData>(
        data,
        MachineRealtimeDataType.Power,
      ),
  ),
  [MachineRealtimeDataType.PowerFactor]: getSwrFetcher(
    { method: 'GET' },
    (data: Api.MachineRealTimeResponse<Api.RealtimePowerFactorData>) =>
      mapMachineRealtimeResponse<Api.RealtimePowerFactorData, Store.RealtimePowerFactorData>(
        data,
        MachineRealtimeDataType.PowerFactor,
      ),
  ),
  [MachineRealtimeDataType.Savings]: getSwrFetcher(
    { method: 'GET' },
    (data: Api.MachineRealTimeResponse<Api.RealtimeSavingsData>) =>
      mapMachineRealtimeResponse<Api.RealtimeSavingsData, Store.RealtimeSavingsData>(
        data,
        MachineRealtimeDataType.Savings,
      ),
  ),
  [MachineRealtimeDataType.Voltage]: getSwrFetcher(
    { method: 'GET' },
    (data: Api.MachineRealTimeResponse<Api.RealtimeVoltageData>) =>
      mapMachineRealtimeResponse<Api.RealtimeVoltageData, Store.RealtimeVoltageData>(
        data,
        MachineRealtimeDataType.Voltage,
      ),
  ),
  [MachineRealtimeDataType.Vthd]: getSwrFetcher(
    { method: 'GET' },
    (data: Api.MachineRealTimeResponse<Api.RealtimeVthdData>) =>
      mapMachineRealtimeResponse<Api.RealtimeVthdData, Store.RealtimeVthdData>(
        data,
        MachineRealtimeDataType.Vthd,
      ),
  ),
}

export const useFetchRealtimeData = <
  T extends MachineRealtimeDataType,
  R = Awaited<ReturnType<(typeof fetchers)[T]>>,
>(
  machineId: number | null | undefined,
  type: T,
): SWRResponse<
  R,
  ApiError,
  {
    refreshInterval: typeof config.realtimeDataPollingInterval
  }
> =>
  useSWR(machineId && config.api.getMachineRealtimeData(machineId, type), fetchers[type] as BareFetcher<R>, {
    refreshInterval: config.realtimeDataPollingInterval,
  })

export default useFetchRealtimeData
