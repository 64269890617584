import Axios, { AxiosInstance, AxiosError, AxiosResponse } from 'axios'

import Debug from 'debug'
import * as localStore from 'core/localStore'

const debug = Debug('Axios')
const axiosForAuthApi: AxiosInstance = Axios.create()
const axiosForPublicApi: AxiosInstance = Axios.create()

const goToLogin = () => {
  //GG-keep this console.warn
  console.warn('Auth expired: clear storage and redirect to login')
  localStore.clearUser()
  window.location.reload()
}

const isValidStatus = (status: number) => {
  return status >= 200 && status < 300
}

const onFullfilled = (response: AxiosResponse): AxiosResponse | Promise<AxiosResponse> => {
  // const originalRequest = response.config
  debug('Axios Response:', response)

  //In this case, we have an http error
  if (!isValidStatus(response.status)) {
    return Promise.reject(response)
  }

  //If we are here, everything should be fine
  return Promise.resolve(response)
}

const onRejected = (error: AxiosError) => {
  // const originalRequest = error.config
  debug('Axios Response:', error, error.response)

  //check if there are Rest API authentication error
  const { response } = error
  if (response.status === 401) {
    return goToLogin()
  }

  return Promise.reject(error)
}

// Request interceptor for API calls
axiosForAuthApi.interceptors.request.use((request) => {
  debug('Axios Request', request)
  return request
})
axiosForPublicApi.interceptors.request.use((request) => {
  debug('Axios Request', request)
  return request
})

// Response interceptor for Auth API calls
axiosForAuthApi.interceptors.response.use(onFullfilled, onRejected)

// Response interceptor for public API calls
axiosForPublicApi.interceptors.response.use(onFullfilled)

export const getAxios = (isAuthApi = true) => {
  return isAuthApi ? axiosForAuthApi : axiosForPublicApi
}
