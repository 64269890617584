import type * as Api from 'types/api'
import type * as Store from 'types/store'

export const mapUser = ({ id, at, email, role, machines, state }: Api.AdminUser): Store.AdminUser => ({
  id,
  at,
  email,
  role,
  machines,
  state,
})

export default mapUser
