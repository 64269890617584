import { Button, Typography } from 'antd'
import classNames from 'classnames'
import { config } from 'core'
import dayjs from 'dayjs'
import { useTheme } from 'providers/ThemeProvider'
import React from 'react'
import { useTranslation } from 'react-i18next'
import type * as Store from 'types/store'
import styles from './styles.module.scss'
import { themes } from './themes'

export interface NewsCardProps {
  article: Store.NewsArticle
  isLoading: boolean
  hidden?: boolean
  className?: string
}

export const NewsCard = React.forwardRef<HTMLElement, NewsCardProps>(
  ({ article, hidden, className }, ref) => {
    const { t } = useTranslation()
    const { getStyles } = useTheme()
    const themeStyles = getStyles(themes)
    const newsCardClass = classNames(styles['news-card'], themeStyles.theme, 'news-card', className)

    if (!article) {
      return null
    }

    return (
      <section
        ref={ref}
        className={newsCardClass}
        style={{
          visibility: hidden ? 'hidden' : 'visible',
        }}
      >
        <div className="news-img-wrapper">
          <img src={article.imageUrl} className="news-img" alt="News Image" />
        </div>
        <div className="news-content">
          <Typography.Title level={5}>
            <span dangerouslySetInnerHTML={{ __html: article.title }} />
          </Typography.Title>
          <Typography.Text>{dayjs(article.publishedAt).format(config.getDateFormat())}</Typography.Text>

          <a href={article.link} target="_blank" rel="noopener noreferrer">
            <Button className="go-to-news-btn" size="small" type="primary">
              {t('news.read')}
            </Button>
          </a>
        </div>
      </section>
    )
  },
)

NewsCard.displayName = 'NewsCard'

export default NewsCard
