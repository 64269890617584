import { AxiosError } from 'axios'
import { ApiErrorType } from 'core/errorHelper'
import { getAuthToken } from 'core/localStore'

export const Bearer = 'Bearer'

export function getApiError(error: AxiosError): ApiError {
  return {
    type: ApiErrorType,
    status: error.response?.status || 500,
    data: error.response?.data || {},
  }
}

export const getAuthenticationHeader = async () => {
  const token = await getAuthToken()
  return { headers: { Authorization: `${Bearer} ${token}` } }
}
