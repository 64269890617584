import React from 'react'
import classNames from 'classnames'
import { ChartDataset } from 'chart.js'

interface LegendProps {
  datasets: ChartDataset<'line', number[]>[]
  onLegendClick: (index: number) => void
}

export const Legend: React.FC<LegendProps> = ({ datasets, onLegendClick }) => {
  return (
    <div className="legend">
      {datasets.map((dataset, index) => (
        <div
          key={index}
          className={classNames('legend-item', {
            'legend-item--hidden': dataset.hidden,
          })}
          onClick={() => onLegendClick(index)}
        >
          <span className="legend-color" style={{ backgroundColor: dataset.borderColor as string }}></span>
          <span className="legend-label">{dataset.label}</span>
        </div>
      ))}
    </div>
  )
}

export default Legend
