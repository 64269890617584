import React from 'react'

import MachineMenu, { MachineMenuProps } from 'components/MachineMenu'
import { getUserRole } from 'core/localStore'
import { useLocation } from 'react-router-dom'
import { useMount } from 'react-use'
import { useAppDispatch, useAppSelector } from 'store'
import { fetchUserMachineMenu, selectMachinesMenu } from 'store/machines'

const getSelectedIdFromRoute = (path: string): MachineMenuProps['selectedKey'] => {
  switch (path) {
    case '/':
      return 'ALL_MACHINES'
    case '/admin/machines':
      return 'adminMachines'
    case '/admin/users':
      return 'adminUsers'
    case '/admin/codes':
      return 'adminCodes'
    case '/admin/languages':
      return 'adminLanguages'
    case '/admin/translations':
      return 'adminTranslations'
    default: {
      const regexp = /\/machine\/(\d+)/
      if (!regexp.test(path)) {
        return void 0
      }
      const match = path.match(regexp)
      return match[1] as `${number}`
    }
  }
}

interface Props {
  mobile: boolean
}

export const MachineMenuContainer: React.FC<Props> = ({ mobile = false }) => {
  const route = useLocation()
  const selectedKey = getSelectedIdFromRoute(route.pathname)

  const role = getUserRole()
  const dispatch = useAppDispatch()
  const machinesMenu = useAppSelector(selectMachinesMenu)

  useMount(() => {
    if (role) {
      dispatch(fetchUserMachineMenu())
    }
  })

  return <MachineMenu mobile={mobile} machines={machinesMenu} selectedKey={selectedKey} />
}

export default MachineMenuContainer
