import dayjs from 'dayjs'

import { EMPTY_DATE } from 'core'

/**
 * Converts string to Date if the date is valid, or returns null
 * @param {string | null} dateString The string to be converted to date
 * @param {dayjs.OptionType | undefined} parseFormat The date format string to use for parsing the date
 * @returns {Date | null} The Date object if the date is valid, null otherwise
 */
export const mapDate = (
  dateString: string | null,
  parseFormat?: dayjs.OptionType | undefined,
): Date | null => {
  if (!dateString || dateString === EMPTY_DATE) {
    return null
  }

  const d = parseFormat ? dayjs(dateString, parseFormat, true) : dayjs(dateString)

  return d.isValid() ? d.toDate() : null
}
