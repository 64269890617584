import type * as Api from 'types/api'
import type * as Store from 'types/store'

export const mapMachinePowerByDay = ({ pr, ps, pt }: Api.MachinePowerByDay): Store.MachinePowerByDay => ({
  pr,
  ps,
  pt,
})

export default mapMachinePowerByDay
