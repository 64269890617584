import { getAxios } from './apiService'
import type * as Api from 'types/api'
import { config } from 'core'
import { getApiError, getAuthenticationHeader } from './common'
import { AxiosError, AxiosResponse } from 'axios'

const axios = getAxios()

export async function getAdminLabels({ q, o, sz, n }: Api.PaginationParams = {}): Promise<
  Api.PaginatedResult<Api.LabelTag> | ApiError
> {
  try {
    const { headers } = await getAuthenticationHeader()
    const response = await axios.get<Api.PaginatedResult<Api.LabelTag>>(config.api.getAdminLabels(), {
      params: { q, o, sz: sz?.toString(), n: n?.toString() },
      headers,
    })
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function getAdminLabelById(labelId: number): Promise<Api.LabelTag | ApiError> {
  try {
    const response = await axios.get<Api.LabelTag>(
      config.api.getAdminLabelById(labelId),
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function postAdminLabel(labelPayload: Api.LabelPayload): Promise<Api.LabelTag | ApiError> {
  try {
    const response = await axios.post<Api.LabelTag, AxiosResponse<Api.LabelTag>, Api.LabelPayload>(
      config.api.postAdminLabel(),
      labelPayload,
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function putAdminLabelById(
  labelId: number,
  labelPayload: Api.LabelPayload,
): Promise<Api.LabelTag | ApiError> {
  try {
    const response = await axios.put<Api.LabelTag, AxiosResponse<Api.LabelTag>, Api.LabelPayload>(
      config.api.putAdminLabelById(labelId),
      labelPayload,
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function deleteAdminLabelById(labelId: number): Promise<Api.LabelTag | ApiError> {
  try {
    const response = await axios.delete<
      Api.LabelTag,
      AxiosResponse<Api.LabelTag, Api.LabelPayload>,
      Api.LabelPayload
    >(config.api.deleteAdminLabelById(labelId), await getAuthenticationHeader())
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}
