import useSWR, { BareFetcher, SWRResponse } from 'swr'

import { getSwrFetcher } from 'api/swr-fetcher'
import { mapMachineByDayResponse } from 'services'
import { config } from 'core'
import { MachineMetricsDataType } from 'types/enums'

import type * as Api from 'types/api'
import type * as Store from 'types/store'

const fetchers = {
  [MachineMetricsDataType.Voltage]: getSwrFetcher(
    { method: 'GET' },
    (data: Api.MachineDataByDayResponse<Api.MachineVoltageByDay>) =>
      mapMachineByDayResponse<Api.MachineVoltageByDay, Store.MachineVoltageByDay>(
        data,
        MachineMetricsDataType.Voltage,
      ),
  ),
  [MachineMetricsDataType.Current]: getSwrFetcher(
    { method: 'GET' },
    (data: Api.MachineDataByDayResponse<Api.MachineCurrentByDay>) =>
      mapMachineByDayResponse<Api.MachineCurrentByDay, Store.MachineCurrentByDay>(
        data,
        MachineMetricsDataType.Current,
      ),
  ),
  [MachineMetricsDataType.Power]: getSwrFetcher(
    { method: 'GET' },
    (data: Api.MachineDataByDayResponse<Api.MachinePowerByDay>) =>
      mapMachineByDayResponse<Api.MachinePowerByDay, Store.MachinePowerByDay>(
        data,
        MachineMetricsDataType.Power,
      ),
  ),
  [MachineMetricsDataType.PowerFactor]: getSwrFetcher(
    { method: 'GET' },
    (data: Api.MachineDataByDayResponse<Api.MachinePowerFactorByDay>) =>
      mapMachineByDayResponse<Api.MachinePowerFactorByDay, Store.MachinePowerFactorByDay>(
        data,
        MachineMetricsDataType.PowerFactor,
      ),
  ),
  [MachineMetricsDataType.Vthd]: getSwrFetcher(
    { method: 'GET' },
    (data: Api.MachineDataByDayResponse<Api.MachineVthdByDay>) =>
      mapMachineByDayResponse<Api.MachineVthdByDay, Store.MachineVthdByDay>(
        data,
        MachineMetricsDataType.Vthd,
      ),
  ),
  [MachineMetricsDataType.Ithd]: getSwrFetcher(
    { method: 'GET' },
    (data: Api.MachineDataByDayResponse<Api.MachineIthdByDay>) =>
      mapMachineByDayResponse<Api.MachineIthdByDay, Store.MachineIthdByDay>(
        data,
        MachineMetricsDataType.Ithd,
      ),
  ),
  [MachineMetricsDataType.Energy]: getSwrFetcher(
    { method: 'GET' },
    (data: Api.MachineDataByDayResponse<Api.MachineEnergyByDay>) =>
      mapMachineByDayResponse<Api.MachineEnergyByDay, Store.MachineEnergyByDay>(
        data,
        MachineMetricsDataType.Energy,
      ),
  ),
}

export const useFetchTimelineData = <
  T extends
    | MachineMetricsDataType.Voltage
    | MachineMetricsDataType.Current
    | MachineMetricsDataType.Power
    | MachineMetricsDataType.PowerFactor
    | MachineMetricsDataType.Vthd
    | MachineMetricsDataType.Ithd
    | MachineMetricsDataType.Energy,
  R = Awaited<ReturnType<(typeof fetchers)[T]>>,
>(
  machineId: number,
  day: Date,
  type: T,
): SWRResponse<R, ApiError> =>
  useSWR(config.api.getMachineDataByDay(machineId, day, type), fetchers[type] as BareFetcher<R>)
