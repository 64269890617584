import dayjs from 'dayjs'
import type * as Api from 'types/api'
import type * as Store from 'types/store'

export const mapMachineLatestCurrentData = ({
  bounds: { begin, end },
  cin,
  cin_max,
  cin_min,
  cin_t1,
  cout,
  cout_max,
  cout_min,
  cout_t1,
}: Api.MachineLatestCurrentData): Store.MachineLatestCurrentData => ({
  bounds: { begin: dayjs(begin).toDate(), end: dayjs(end).toDate() },
  cIn: cin.map(({ t, cInAvg }) => ({ t: dayjs(t).toDate(), cInAvg })),
  cInMax: cin_max.map(({ t, cInMaxAvg }) => ({ t: dayjs(t).toDate(), cInMaxAvg })),
  cInMin: cin_min.map(({ t, cInMinAvg }) => ({ t: dayjs(t).toDate(), cInMinAvg })),
  cInT1: cin_t1.map(({ t, cInT1Avg }) => ({ t: dayjs(t).toDate(), cInT1Avg })),
  cOut: cout.map(({ t, cOutAvg }) => ({ t: dayjs(t).toDate(), cOutAvg })),
  cOutMax: cout_max.map(({ t, cOutMaxAvg }) => ({ t: dayjs(t).toDate(), cOutMaxAvg })),
  cOutMin: cout_min.map(({ t, cOutMinAvg }) => ({ t: dayjs(t).toDate(), cOutMinAvg })),
  cOutT1: cout_t1.map(({ t, cOutT1Avg }) => ({ t: dayjs(t).toDate(), cOutT1Avg })),
})

export default mapMachineLatestCurrentData
