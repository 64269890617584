// import proper moment translation for each new language
import 'dayjs/locale/it'

import dayjs from 'dayjs'

import { apiProvider } from 'config/api'

import * as localStore from './localStore'

const homepage = process.env.REACT_APP_HOMEPAGE
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL
const mapboxToken = process.env.REACT_APP_MAPBOX_TOKEN
const fallbackLanguage = 'en'
const getLanguage = () => localStore.getLanguage() ?? process.env.REACT_APP_LOCALE ?? fallbackLanguage
const platform = 'WEB'

/* eslint-disable no-useless-escape */
export const VALIDATE_PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/g
export const EMPTY_DATE = '0001-01-01T00:00:00Z'

export const config = (function config() {
  const provider = apiProvider(apiBaseUrl)

  return {
    api: provider.api,
    homepage,
    getLanguage,
    mapboxToken,
    fallbackLanguage,
    getDateFormat: () => (getLanguage() === 'en' ? 'YYYY/MM/DD' : 'DD/MM/YYYY'),
    getDateTimeFormat: () => 'DD/MM/YYYY HH:mm',
    getHoursFormat: () => 'HH:mm',
    getMonthDayYearFormat: () => 'MMM D, YYYY',
    setLanguage: (lng: string) => {
      provider.setLanguage(lng)
      dayjs.locale(lng)
    },
    apiBaseUrl,
    platform,
    adminPageSize: 10,
    clientPaginationPageSize: 1000,
    realtimeDataPollingInterval: 5000 as const,
    allMachinesRealtimePollingInterval: 15000 as const,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    defaultLatMapView: 50.0,
    defaultLonMapView: 9.0,
    defaultZoomMapView: 3,
    defaultZoomMapViewSelectedMachine: 18,
    defaultZoomMapViewDuration: 2000,
    defaultMaxZoomMapView: 18,
  }
})()

export const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY
