import dayjs from 'dayjs'
import type * as Api from 'types/api'
import type * as Store from 'types/store'

export const mapMachineLatestVoltageData = ({
  bounds: { begin, end },
  summary: { Vout, vMax, vMin },
  thdinavg,
  thdoutavg,
  vin,
  vin_max,
  vin_min,
  vinphase,
  vout,
  vout_max,
  vout_min,
  voutphase,
}: Api.MachineLatestVoltageData): Store.MachineLatestVoltageData => ({
  bounds: { begin: dayjs(begin).toDate(), end: dayjs(end).toDate() },
  summary: { vOut: +Vout, vMax: +vMax, vMin: +vMin },
  thdIn: thdinavg.map(({ t, thdInAvg }) => ({ t: dayjs(t).toDate(), thdInAvg })),
  thdOut: thdoutavg.map(({ t, thdOutAvg }) => ({ t: dayjs(t).toDate(), thdOutAvg })),
  vInConc: vin.map(({ t, vInConcAvg }) => ({ t: dayjs(t).toDate(), vInConcAvg })),
  vInMaxConc: vin_max.map(({ t, vInMaxConcAvg }) => ({ t: dayjs(t).toDate(), vInMaxConcAvg })),
  vInMinConc: vin_min.map(({ t, vInMinConcAvg }) => ({ t: dayjs(t).toDate(), vInMinConcAvg })),
  vInPhase: vinphase.map(({ t, vInPhaseAvg }) => ({ t: dayjs(t).toDate(), vInPhaseAvg })),
  vOutConc: vout.map(({ t, vOutConcAvg }) => ({ t: dayjs(t).toDate(), vOutConcAvg })),
  vOutMaxConc: vout_max.map(({ t, vOutMaxConcAvg }) => ({ t: dayjs(t).toDate(), vOutMaxConcAvg })),
  vOutMinConc: vout_min.map(({ t, vOutMinConcAvg }) => ({ t: dayjs(t).toDate(), vOutMinConcAvg })),
  vOutPhase: voutphase.map(({ t, vOutPhaseAvg }) => ({ t: dayjs(t).toDate(), vOutPhaseAvg })),
})

export default mapMachineLatestVoltageData
